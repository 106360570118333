import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The AWSDate scalar type represents a valid extended ISO 8601 Date string. In other words, this scalar type accepts date strings of the form YYYY-MM-DD. This scalar type can also accept time zone offsets. For example, 1970-01-01Z, 1970-01-01-07:00 and 1970-01-01+05:30 are all valid dates. The time zone offset must either be Z (representing the UTC time zone) or be in the format ±hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: { input: string; output: string };
  /** The AWSDateTime scalar type represents a valid extended ISO 8601 DateTime string. In other words, this scalar type accepts datetime strings of the form YYYY-MM-DDThh:mm:ss.sssZ. The field after the seconds field is a nanoseconds field. It can accept between 1 and 9 digits. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used). The time zone offset is compulsory for this scalar. The time zone offset must either be Z (representing the UTC time zone) or be in the format ±hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: { input: string; output: string };
  /** The AWSEmail scalar type represents an Email address string that complies with RFC 822. For example, username@example.com is a valid Email address. */
  AWSEmail: { input: string; output: string };
  /** The AWSIPAddress scalar type represents a valid IPv4 or IPv6 address string. */
  AWSIPAddress: { input: string; output: string };
  /**
   * The AWSJSON scalar type represents a JSON string that complies with RFC 8259.
   *
   * Maps like {\"upvotes\": 10}, lists like [1,2,3], and scalar values like \"AWSJSON example string\", 1, and true are accepted as valid JSON. They will automatically be parsed and loaded in the resolver mapping templates as Maps, Lists, or Scalar values rather than as the literal input strings. Invalid JSON strings like {a: 1}, {'a': 1} and Unquoted string will throw GraphQL validation errors.
   */
  AWSJSON: { input: string; output: string };
  /** The AWSPhone scalar type represents a valid Phone Number. Phone numbers are serialized and deserialized as Strings. Phone numbers provided may be whitespace delimited or hyphenated. The number can specify a country code at the beginning but this is not required. */
  AWSPhone: { input: string; output: string };
  /**
   * The AWSTime scalar type represents a valid extended ISO 8601 Time string. In other words, this scalar type accepts time strings of the form hh:mm:ss.sss. The field after the seconds field is a nanoseconds field. It can accept between 1 and 9 digits. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used). This scalar type can also accept time zone offsets.
   *
   * For example, 12:30Z, 12:30:24-07:00 and 12:30:24.500+05:30 are all valid time strings.
   *
   * The time zone offset must either be Z (representing the UTC time zone) or be in the format hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard.
   */
  AWSTime: { input: string; output: string };
  /** The AWSTimestamp scalar type represents the number of seconds that have elapsed since 1970-01-01T00:00Z. Timestamps are serialized and deserialized as numbers. Negative values are also accepted and these represent the number of seconds till 1970-01-01T00:00Z. */
  AWSTimestamp: { input: string; output: string };
  /** The AWSURL scalar type represents a valid URL string. The URL may use any scheme and may also be a local URL (Ex: <http://localhost/>). URLs without schemes are considered invalid. URLs which contain double slashes are also considered invalid. */
  AWSURL: { input: string; output: string };
};

export type Account = {
  __typename?: 'Account';
  address?: Maybe<Address>;
  books?: Maybe<AccountBooks>;
  id?: Maybe<Scalars['ID']['output']>;
  logoKey?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<AccountSettings>;
};

export type AccountAutopay = {
  __typename?: 'AccountAutopay';
  activated?: Maybe<Scalars['Boolean']['output']>;
  activationStatus?: Maybe<Scalars['String']['output']>;
  enableCollection?: Maybe<Scalars['Boolean']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type AccountBooks = {
  __typename?: 'AccountBooks';
  autopay?: Maybe<AccountAutopay>;
  gstNumber?: Maybe<Scalars['String']['output']>;
};

export type AccountDetail = {
  __typename?: 'AccountDetail';
  accountNumber?: Maybe<Scalars['String']['output']>;
  accountNumber4Digit?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  institutionNumber?: Maybe<Scalars['String']['output']>;
  transitNumber?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AccountSettings = {
  __typename?: 'AccountSettings';
  campaignApplicationRules?: Maybe<CampaignApplicationRule>;
  resident_request_scheduling?: Maybe<ResidentRequestScheduling>;
};

export type AddAdditionalTenantInput = {
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  leaseholder?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type AddAddressHistoryInput = {
  address: AddressInput;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  endDate?: InputMaybe<Scalars['AWSDate']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  rentalType?: InputMaybe<RentalType>;
  startDate?: InputMaybe<Scalars['AWSDate']['input']>;
};

export type AddBankAccountInput = {
  bankAccountIds: Array<Scalars['String']['input']>;
  institutionName: Scalars['String']['input'];
  loginId: Scalars['String']['input'];
  requestId?: InputMaybe<Scalars['String']['input']>;
};

export type AddLoginInput = {
  email: Scalars['String']['input'];
};

export type AddOtherIncomeInput = {
  amount: Scalars['Float']['input'];
  source: IncomeSource;
};

export type AddVehicleInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
  plate: Scalars['String']['input'];
};

export type AddWorkHistoryInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['AWSDate']['input']>;
  frequency?: InputMaybe<SalaryFrequency>;
  location: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['AWSDate']['input']>;
};

export type AdditionalProvisions = {
  __typename?: 'AdditionalProvisions';
  parking?: Maybe<Scalars['Boolean']['output']>;
  pets?: Maybe<Scalars['Int']['output']>;
  smoking?: Maybe<Scalars['Boolean']['output']>;
  storage?: Maybe<Scalars['Boolean']['output']>;
};

export type AdditionalTenant = {
  __typename?: 'AdditionalTenant';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  leaseholder?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  payeeFrac?: Maybe<Fraction>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  postal?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  suite?: Maybe<Scalars['String']['output']>;
};

export type AddressHistory = {
  __typename?: 'AddressHistory';
  address: Address;
  email?: Maybe<Scalars['AWSEmail']['output']>;
  endDate?: Maybe<Scalars['AWSDate']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  rentalType?: Maybe<RentalType>;
  startDate?: Maybe<Scalars['AWSDate']['output']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  postal?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type AmenitiesSetting = {
  __typename?: 'AmenitiesSetting';
  amenities?: Maybe<Array<Maybe<Amenity>>>;
};

export type Amenity = {
  __typename?: 'Amenity';
  category?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Application = {
  __typename?: 'Application';
  accountId: Scalars['ID']['output'];
  additionalInformation?: Maybe<Scalars['String']['output']>;
  addressHistory?: Maybe<Array<AddressHistory>>;
  applicationId?: Maybe<Scalars['ID']['output']>;
  approvedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  campaignId: Scalars['ID']['output'];
  declinedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  dob?: Maybe<Scalars['AWSDate']['output']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  emails?: Maybe<Array<Maybe<Scalars['AWSEmail']['output']>>>;
  emergencyContacts?: Maybe<Array<EmergencyContact>>;
  household?: Maybe<ApplicationHousehold>;
  id: Scalars['ID']['output'];
  leaseAgreement?: Maybe<LeaseAgreement>;
  listedUnitId: Scalars['ID']['output'];
  moveInDate?: Maybe<Scalars['AWSDate']['output']>;
  name: Scalars['String']['output'];
  otherIncome?: Maybe<Array<Income>>;
  phone?: Maybe<Scalars['String']['output']>;
  preSubmittedZ: Scalars['AWSDateTime']['output'];
  profileId: Scalars['ID']['output'];
  screeningAgreement?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<ApplicationStatus>;
  submittedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  workHistory?: Maybe<Array<WorkHistory>>;
};

export type ApplicationAdditionalTenant = {
  __typename?: 'ApplicationAdditionalTenant';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  leaseholder?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  payeeFrac?: Maybe<Fraction>;
  phone?: Maybe<Scalars['String']['output']>;
  sentZ?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type ApplicationHousehold = {
  __typename?: 'ApplicationHousehold';
  additionalTenants?: Maybe<Array<ApplicationAdditionalTenant>>;
  payeeFrac?: Maybe<Fraction>;
  pets?: Maybe<Array<Pet>>;
  vehicles?: Maybe<Array<Vehicle>>;
};

export type ApplicationPayload = {
  __typename?: 'ApplicationPayload';
  application?: Maybe<Application>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum ApplicationStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Submitted = 'SUBMITTED',
}

export type AppointmentChangeInput = {
  appointmentPreferences?: InputMaybe<Array<InputMaybe<IntervalInput>>>;
  reason?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  visitId?: InputMaybe<Scalars['ID']['input']>;
};

export type ApproveRequestInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type Asset = {
  __typename?: 'Asset';
  accountId: Scalars['ID']['output'];
  assetType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  role?: Maybe<Scalars['String']['output']>;
  shared: Scalars['Boolean']['output'];
};

export type AssetFee = {
  __typename?: 'AssetFee';
  assetId: Scalars['ID']['output'];
  feeAmount?: Maybe<Scalars['Float']['output']>;
  feeRate?: Maybe<Scalars['Float']['output']>;
  flatFee?: Maybe<Scalars['Float']['output']>;
  type: AssetType;
};

export type AssetInput = {
  accountId: Scalars['ID']['input'];
  assetType: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  role?: InputMaybe<Scalars['String']['input']>;
  shared: Scalars['Boolean']['input'];
};

export enum AssetType {
  Property = 'property',
  Unit = 'unit',
}

export type AssetsShare = {
  __typename?: 'AssetsShare';
  assets: Array<Asset>;
  expiryZ?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  profile?: Maybe<Profile>;
  profileId: Scalars['ID']['output'];
  shared?: Maybe<Scalars['Boolean']['output']>;
  shareeEmail?: Maybe<Scalars['String']['output']>;
  shareeName?: Maybe<Scalars['String']['output']>;
};

export type AssetsShareAssetsArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
};

export type AssetsShareInput = {
  assets?: InputMaybe<Array<AssetInput>>;
  expiryZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  shared: Scalars['Boolean']['input'];
  shareeEmail: Scalars['String']['input'];
  shareeName?: InputMaybe<Scalars['String']['input']>;
};

export type AssetsSharePayload = {
  __typename?: 'AssetsSharePayload';
  assetsShare?: Maybe<AssetsShare>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AutoRenewSetup = {
  __typename?: 'AutoRenewSetup';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  termMonths?: Maybe<Scalars['Int']['output']>;
};

export type Autopay = {
  __typename?: 'Autopay';
  bankAccounts?: Maybe<Array<BankAccount>>;
};

export type AutopayClearableInput = {
  clearableId: Scalars['ID']['input'];
  outstanding: Scalars['Float']['input'];
};

export type AutopayDetail = {
  __typename?: 'AutopayDetail';
  autopayAccount?: Maybe<Scalars['String']['output']>;
  autopayClientId?: Maybe<Scalars['String']['output']>;
  autopayCollectionsEnabled?: Maybe<Scalars['Boolean']['output']>;
  autopayInstitution?: Maybe<Scalars['String']['output']>;
  autopayStatus?: Maybe<Scalars['String']['output']>;
  autopayTransit?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type AutopayEnrollment = {
  __typename?: 'AutopayEnrollment';
  activatedZ?: Maybe<Scalars['String']['output']>;
  bankAccount?: Maybe<BankAccount>;
  cancelledZ?: Maybe<Scalars['String']['output']>;
  clearables?: Maybe<Array<FixedEnrollmentClearable>>;
  declinedZ?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invitedZ?: Maybe<Scalars['String']['output']>;
  leaseId?: Maybe<Scalars['ID']['output']>;
  posted?: Maybe<Scalars['String']['output']>;
  propertyId: Scalars['ID']['output'];
  residencyId?: Maybe<Scalars['ID']['output']>;
  residentId?: Maybe<Scalars['ID']['output']>;
  status: AutopayEnrollmentStatus;
  statusHistory?: Maybe<Array<AutopayEnrollmentStatusHistory>>;
  unitId: Scalars['ID']['output'];
};

export type AutopayEnrollmentPayload = {
  __typename?: 'AutopayEnrollmentPayload';
  enrollment?: Maybe<AutopayEnrollment>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum AutopayEnrollmentStatus {
  Activated = 'ACTIVATED',
  Archived = 'ARCHIVED',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Invited = 'INVITED',
  Nsf = 'NSF',
  Suspended = 'SUSPENDED',
}

export type AutopayEnrollmentStatusHistory = {
  __typename?: 'AutopayEnrollmentStatusHistory';
  createdZ: Scalars['AWSDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  status: AutopayEnrollmentStatus;
};

export type AutopaySingle = {
  __typename?: 'AutopaySingle';
  accountId: Scalars['ID']['output'];
  amount: Scalars['Float']['output'];
  clearableIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  singlePosted: Scalars['AWSDate']['output'];
};

export type AutopaySinglePayload = {
  __typename?: 'AutopaySinglePayload';
  autopaySingle?: Maybe<AutopaySingle>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum BalanceType {
  Credit = 'credit',
  Debit = 'debit',
}

export type BankAccount = {
  __typename?: 'BankAccount';
  accountDetails?: Maybe<AccountDetail>;
  bankAccountId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  institutionName: Scalars['String']['output'];
  loginId: Scalars['String']['output'];
  requestId?: Maybe<Scalars['String']['output']>;
};

export type BooksActivatedPayload = {
  __typename?: 'BooksActivatedPayload';
  anyActivated?: Maybe<Scalars['Boolean']['output']>;
};

export type CalculatedTax = {
  __typename?: 'CalculatedTax';
  amount?: Maybe<Scalars['Float']['output']>;
  label: Scalars['String']['output'];
};

export type Campaign = {
  __typename?: 'Campaign';
  accountId: Scalars['ID']['output'];
  application?: Maybe<Application>;
  id: Scalars['ID']['output'];
  profileId: Scalars['ID']['output'];
};

export type CampaignApplicationDocRule = {
  __typename?: 'CampaignApplicationDocRule';
  docTypes: Array<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
};

export type CampaignApplicationRule = {
  __typename?: 'CampaignApplicationRule';
  requiredDocs?: Maybe<Array<CampaignApplicationDocRule>>;
};

export type CampaignPayload = {
  __typename?: 'CampaignPayload';
  campaign?: Maybe<Campaign>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum CampaignStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Inactive = 'INACTIVE',
}

export type Clearable = {
  __typename?: 'Clearable';
  accountAddress?: Maybe<Address>;
  accountId: Scalars['ID']['output'];
  accountName?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Float']['output'];
  autopaySinglePosted?: Maybe<Scalars['AWSDate']['output']>;
  autopaySingleProfileId?: Maybe<Scalars['ID']['output']>;
  balanceType: BalanceType;
  cleared?: Maybe<Scalars['AWSDate']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due: Scalars['AWSDate']['output'];
  enrollmentId?: Maybe<Scalars['ID']['output']>;
  fixedEnrollmentId?: Maybe<Scalars['ID']['output']>;
  glAccountName?: Maybe<Scalars['String']['output']>;
  glId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  outstanding: Scalars['Float']['output'];
  ownerId: Scalars['ID']['output'];
  payee: PayeeType;
  payeeAutopayStatus?: Maybe<Scalars['String']['output']>;
  payeeId: Scalars['ID']['output'];
  payeeMailingAddress?: Maybe<Address>;
  payeeName?: Maybe<Scalars['String']['output']>;
  payments?: Maybe<Array<ClearablePaymentEffect>>;
  posted: Scalars['AWSDate']['output'];
  propertyAddress?: Maybe<Address>;
  propertyId: Scalars['ID']['output'];
  propertyName?: Maybe<Scalars['String']['output']>;
  ref?: Maybe<Scalars['String']['output']>;
  refund?: Maybe<Scalars['Boolean']['output']>;
  sourceJournalId: Scalars['String']['output'];
  unitId?: Maybe<Scalars['ID']['output']>;
  unitName?: Maybe<Scalars['String']['output']>;
};

export type ClearableEdge = RelayEdge & {
  __typename?: 'ClearableEdge';
  cursor: Scalars['ID']['output'];
  node: Clearable;
};

export type ClearablePaymentEffect = {
  __typename?: 'ClearablePaymentEffect';
  effect: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  paymentMethod?: Maybe<PaymentMethod>;
  posted: Scalars['AWSDate']['output'];
};

export type ClearablesPage = RelayPage & {
  __typename?: 'ClearablesPage';
  edges: Array<ClearableEdge>;
  pageInfo: RelayPageInfo;
  totalByAccount?: Maybe<Array<TotalByAccount>>;
  totalByProperty?: Maybe<Array<TotalByProperty>>;
  totalByUnit?: Maybe<Array<TotalByUnit>>;
  totalCount: Scalars['Int']['output'];
};

export type CondoUnitFee = {
  __typename?: 'CondoUnitFee';
  amount?: Maybe<Scalars['Float']['output']>;
  feeId: Scalars['ID']['output'];
};

export enum Consent {
  ElectronicNotice = 'electronic_notice',
  MarketingMessages = 'marketing_messages',
}

export enum ContactRelationship {
  Child = 'Child',
  Friend = 'Friend',
  Other = 'Other',
  Parent = 'Parent',
  Relative = 'Relative',
  Spouse = 'Spouse',
}

export enum CorrespondenceMethod {
  Electronic = 'electronic',
  Mail = 'mail',
}

export type CostLine = {
  __typename?: 'CostLine';
  amount: Scalars['Float']['output'];
  date?: Maybe<Scalars['AWSDate']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  markup?: Maybe<Scalars['Float']['output']>;
  receiptImages?: Maybe<Array<Maybe<Image>>>;
  receiptKey?: Maybe<Scalars['String']['output']>;
  total: Scalars['Float']['output'];
  visitNumber?: Maybe<Scalars['Int']['output']>;
};

export type CreateApplicationInput = {
  accountId: Scalars['ID']['input'];
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  addressHistory?: InputMaybe<Array<UpdateAddressHistoryInput>>;
  applicationId?: InputMaybe<Scalars['ID']['input']>;
  campaignId: Scalars['ID']['input'];
  dob?: InputMaybe<Scalars['AWSDate']['input']>;
  documents?: InputMaybe<Array<DocumentInput>>;
  emails?: InputMaybe<Array<InputMaybe<Scalars['AWSEmail']['input']>>>;
  emergencyContacts?: InputMaybe<Array<EmergencyContactInput>>;
  household?: InputMaybe<HouseholdInput>;
  listedUnitId: Scalars['ID']['input'];
  moveInDate?: InputMaybe<Scalars['AWSDate']['input']>;
  name: Scalars['String']['input'];
  otherIncome?: InputMaybe<Array<UpdateOtherIncomeInput>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  screeningAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  workHistory?: InputMaybe<Array<UpdateWorkHistoryInput>>;
};

export type CreateAutopayEmbedUrlPayload = {
  __typename?: 'CreateAutopayEmbedUrlPayload';
  error?: Maybe<Scalars['String']['output']>;
  iframeKey?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type CreateAutopaySingleInput = {
  accountId: Scalars['ID']['input'];
  amount: Scalars['Float']['input'];
  autopayClientId: Scalars['ID']['input'];
  clearableIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ownerId: Scalars['ID']['input'];
  payeeId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  propertyTimezone?: InputMaybe<Scalars['String']['input']>;
  residencyId?: InputMaybe<Scalars['ID']['input']>;
  singlePosted: Scalars['AWSDate']['input'];
  unitId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateCampaignInput = {
  accountId: Scalars['ID']['input'];
};

export type CreateRequestInput = {
  from?: InputMaybe<Scalars['String']['input']>;
  originalRequest?: InputMaybe<Scalars['String']['input']>;
  originalSubject?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<TenantInput>;
  unitId: Scalars['ID']['input'];
};

export type CreateRequestPayload = {
  __typename?: 'CreateRequestPayload';
  error?: Maybe<Scalars['String']['output']>;
  request?: Maybe<ProfileRequest>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeclineRequestInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteBankAccountInput = {
  id: Scalars['String']['input'];
};

export type Document = {
  __typename?: 'Document';
  createdZ?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  typename?: Maybe<Scalars['String']['output']>;
};

export type DocumentInput = {
  key: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  typename?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentLinkPayload = {
  __typename?: 'DocumentLinkPayload';
  error?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  consentToShare: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  relationship?: Maybe<ContactRelationship>;
};

export type EmergencyContactInput = {
  consentToShare?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  relationship?: InputMaybe<ContactRelationship>;
};

export type EnrollmentClearablesInput = {
  amount: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
};

export enum EventSource {
  System = 'system',
}

export type ExpenseEntry = {
  __typename?: 'ExpenseEntry';
  amount: Scalars['Float']['output'];
  clearableId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  glAccountType?: Maybe<Scalars['String']['output']>;
  glBalanceType?: Maybe<Scalars['String']['output']>;
  glId: Scalars['ID']['output'];
  glName?: Maybe<Scalars['String']['output']>;
  gstAmount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  outstanding?: Maybe<Scalars['Float']['output']>;
  ownerId: Scalars['ID']['output'];
  paidDate?: Maybe<Scalars['AWSDate']['output']>;
  posted: Scalars['AWSDate']['output'];
  propertyId: Scalars['ID']['output'];
  ref?: Maybe<Scalars['String']['output']>;
  requestId?: Maybe<Scalars['ID']['output']>;
  unitId?: Maybe<Scalars['ID']['output']>;
};

export type Field = {
  __typename?: 'Field';
  imageKey?: Maybe<Scalars['String']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FieldInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum FilingFrequency {
  Monthly = 'monthly',
  None = 'none',
  Quarterly = 'quarterly',
  Self = 'self',
  Yearly = 'yearly',
}

export type FinalizeRequestInput = {
  appointmentPreferences?: InputMaybe<Array<InputMaybe<IntervalInput>>>;
  availabilityWindows?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  category?: InputMaybe<Scalars['String']['input']>;
  consents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  details?: InputMaybe<Scalars['String']['input']>;
  entryConsentDetails?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<InputMaybe<RequestFieldInput>>>;
  firstOccurred?: InputMaybe<Scalars['AWSDate']['input']>;
  id: Scalars['ID']['input'];
  images?: InputMaybe<Array<InputMaybe<RequestImageInput>>>;
  minutes?: InputMaybe<Scalars['Int']['input']>;
  pendingNotifications?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  template?: InputMaybe<Scalars['String']['input']>;
};

export type FinalizeRequestPayload = {
  __typename?: 'FinalizeRequestPayload';
  error?: Maybe<Scalars['String']['output']>;
  request?: Maybe<ProfileRequest>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Fingerprints = {
  __typename?: 'Fingerprints';
  createdSub?: Maybe<Scalars['ID']['output']>;
  createdZ?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedSub?: Maybe<Scalars['ID']['output']>;
  updatedZ?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type FixedEnrollmentClearable = {
  __typename?: 'FixedEnrollmentClearable';
  amount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
};

export type FixedEnrollmentInput = {
  clearables: Array<EnrollmentClearablesInput>;
  copySourceEnrollmentId: Scalars['ID']['input'];
  posted?: InputMaybe<Scalars['AWSDate']['input']>;
};

export type FixedEnrollmentPayload = {
  __typename?: 'FixedEnrollmentPayload';
  enrollment?: Maybe<AutopayEnrollment>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Floorplan = {
  __typename?: 'Floorplan';
  additionalFeatures?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  areas?: Maybe<Array<FloorplanArea>>;
  bathrooms?: Maybe<Scalars['Float']['output']>;
  bedrooms?: Maybe<Scalars['Float']['output']>;
  default?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  exteriorSqFt?: Maybe<Scalars['Float']['output']>;
  generatedSummary?: Maybe<GeneratedFloorplanSummary>;
  id: Scalars['ID']['output'];
  interiorSqFt?: Maybe<Scalars['Float']['output']>;
  links?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  marketRate?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  photos?: Maybe<Array<Image>>;
  publicPhotos?: Maybe<Array<Image>>;
  totalUnits?: Maybe<Scalars['Int']['output']>;
  units?: Maybe<Array<Maybe<Unit>>>;
};

export type FloorplanArea = {
  __typename?: 'FloorplanArea';
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Array<Maybe<FloorplanAreaFeature>>>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export type FloorplanAreaFeature = {
  __typename?: 'FloorplanAreaFeature';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Fraction = {
  __typename?: 'Fraction';
  denominator: Scalars['Int']['output'];
  numerator: Scalars['Int']['output'];
};

export type FractionInput = {
  denominator: Scalars['Int']['input'];
  numerator: Scalars['Int']['input'];
};

export type GeneratedFloorplanFeature = {
  __typename?: 'GeneratedFloorplanFeature';
  feature: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type GeneratedFloorplanSummary = {
  __typename?: 'GeneratedFloorplanSummary';
  features?: Maybe<Array<GeneratedFloorplanFeature>>;
  summary?: Maybe<Scalars['String']['output']>;
};

export type Household = {
  __typename?: 'Household';
  additionalTenants?: Maybe<Array<AdditionalTenant>>;
  payeeFrac?: Maybe<Fraction>;
  pets?: Maybe<Array<Pet>>;
  vehicles?: Maybe<Array<Vehicle>>;
};

export type HouseholdInput = {
  additionalTenants?: InputMaybe<Array<UpdateAdditionalTenantInput>>;
  payeeFrac?: InputMaybe<FractionInput>;
  pets?: InputMaybe<Array<PetInput>>;
  vehicles?: InputMaybe<Array<UpdateVehicleInput>>;
};

export type IamManager = {
  __typename?: 'IamManager';
  insurancePolicies?: Maybe<Array<InsurancePolicy>>;
  profile?: Maybe<Profile>;
};

export type IamManagerInsurancePoliciesArgs = {
  residencyId: Scalars['ID']['input'];
};

export type IamManagerProfileArgs = {
  id: Scalars['ID']['input'];
};

export type Image = {
  __typename?: 'Image';
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type InboxMessage = {
  __typename?: 'InboxMessage';
  accountId: Scalars['ID']['output'];
  announcementId?: Maybe<Scalars['ID']['output']>;
  archivedZ?: Maybe<Scalars['String']['output']>;
  attachments?: Maybe<Array<InboxMessageAttachment>>;
  emailBody?: Maybe<Scalars['String']['output']>;
  emailSubject?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  profileId: Scalars['ID']['output'];
  publishZ?: Maybe<Scalars['String']['output']>;
  readZ?: Maybe<Scalars['String']['output']>;
  residentId: Scalars['ID']['output'];
};

export type InboxMessageAttachment = {
  __typename?: 'InboxMessageAttachment';
  key: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type InboxMessageConnection = {
  __typename?: 'InboxMessageConnection';
  items?: Maybe<Array<InboxMessage>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export enum InboxMessageEntities {
  Announcement = 'announcement',
}

export type InboxMessagePayload = {
  __typename?: 'InboxMessagePayload';
  error?: Maybe<Scalars['String']['output']>;
  inboxMessage?: Maybe<InboxMessage>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum InboxMessageStates {
  All = 'all',
  Archived = 'archived',
  Read = 'read',
  Unread = 'unread',
}

export type Income = {
  __typename?: 'Income';
  amount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  source: IncomeSource;
};

export enum IncomeSource {
  Alimony = 'alimony',
  GovernmentAssistancePrograms = 'government_assistance_programs',
  Investments = 'investments',
  Pension = 'pension',
}

export enum InspectionCategory {
  MoveIn = 'MOVE_IN',
  MoveOut = 'MOVE_OUT',
  Routine = 'ROUTINE',
}

export enum InspectionStatus {
  Attempted = 'ATTEMPTED',
  Completed = 'COMPLETED',
  Started = 'STARTED',
}

export type InspectionUrlInput = {
  inspectionId: Scalars['ID']['input'];
};

export type InspectionUrlObject = {
  __typename?: 'InspectionUrlObject';
  fileUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type InspectionUrlPayload = {
  __typename?: 'InspectionUrlPayload';
  error?: Maybe<Scalars['String']['output']>;
  inspectionUrlObject?: Maybe<InspectionUrlObject>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type InsurancePolicy = {
  __typename?: 'InsurancePolicy';
  accountId: Scalars['ID']['output'];
  effective: Scalars['AWSDate']['output'];
  expiry: Scalars['AWSDate']['output'];
  id: Scalars['ID']['output'];
  liability?: Maybe<Scalars['Float']['output']>;
  policyDocumentKey?: Maybe<Scalars['String']['output']>;
  policyNumber: Scalars['String']['output'];
  profileId: Scalars['ID']['output'];
  propertyId: Scalars['ID']['output'];
  providerName: Scalars['String']['output'];
  residencyId: Scalars['ID']['output'];
  residentId: Scalars['ID']['output'];
  sentZ?: Maybe<Scalars['AWSDateTime']['output']>;
  unitId: Scalars['ID']['output'];
};

export type InsurancePolicyFilterInput = {
  residencyId?: InputMaybe<Scalars['ID']['input']>;
};

export type InsurancePolicyInput = {
  accountId: Scalars['ID']['input'];
  effective: Scalars['AWSDate']['input'];
  expiry: Scalars['AWSDate']['input'];
  liability?: InputMaybe<Scalars['Float']['input']>;
  policyDocumentKey?: InputMaybe<Scalars['String']['input']>;
  policyNumber: Scalars['String']['input'];
  propertyId: Scalars['ID']['input'];
  providerName: Scalars['String']['input'];
  residencyId: Scalars['ID']['input'];
  residentId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type InsurancePolicyPayload = {
  __typename?: 'InsurancePolicyPayload';
  error?: Maybe<Scalars['String']['output']>;
  insurancePolicy?: Maybe<InsurancePolicy>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Interval = {
  __typename?: 'Interval';
  end: Scalars['AWSDateTime']['output'];
  start: Scalars['AWSDateTime']['output'];
};

export type IntervalInput = {
  end: Scalars['AWSDateTime']['input'];
  start: Scalars['AWSDateTime']['input'];
};

export type Lease = {
  __typename?: 'Lease';
  additionalProvisions?: Maybe<AdditionalProvisions>;
  autoRenewSetup?: Maybe<AutoRenewSetup>;
  end?: Maybe<Scalars['String']['output']>;
  gst?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  incentives?: Maybe<Array<Maybe<LeaseIncentive>>>;
  leaseAgreement?: Maybe<LeaseAgreement>;
  occupancy?: Maybe<Scalars['String']['output']>;
  originalLeaseId?: Maybe<Scalars['String']['output']>;
  property?: Maybe<ManagerProperty>;
  propertyId?: Maybe<Scalars['ID']['output']>;
  rateSchedule?: Maybe<Array<Maybe<RateSchedule>>>;
  rent: Scalars['Float']['output'];
  rentRollFees?: Maybe<Array<Maybe<RentRollFee>>>;
  securityDeposit?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['String']['output']>;
  status?: Maybe<LeaseStatus>;
  tenants?: Maybe<Array<Maybe<LeaseTenant>>>;
  unit?: Maybe<Unit>;
  unitId?: Maybe<Scalars['ID']['output']>;
};

export type LeaseAgreement = {
  __typename?: 'LeaseAgreement';
  acceptedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  agreementFileKey?: Maybe<Scalars['String']['output']>;
  history?: Maybe<Array<StatusHistoryEvent>>;
  id: Scalars['ID']['output'];
  offeredZ?: Maybe<Scalars['String']['output']>;
  signatures?: Maybe<Array<Signature>>;
};

export type LeaseFilterItemInput = {
  accountId: Scalars['String']['input'];
  leaseId: Scalars['String']['input'];
};

export type LeaseIncentive = {
  __typename?: 'LeaseIncentive';
  description?: Maybe<Scalars['String']['output']>;
  discountAmount: Scalars['Float']['output'];
  end: Scalars['AWSDate']['output'];
  id: Scalars['ID']['output'];
  start: Scalars['AWSDate']['output'];
  type: Scalars['String']['output'];
};

export type LeaseInspection = {
  __typename?: 'LeaseInspection';
  category?: Maybe<InspectionCategory>;
  completedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  propertyId?: Maybe<Scalars['ID']['output']>;
  residencyId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<InspectionStatus>;
  unitId?: Maybe<Scalars['ID']['output']>;
};

export enum LeaseStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Inactive = 'INACTIVE',
  Terminated = 'TERMINATED',
}

export type LeaseTenant = {
  __typename?: 'LeaseTenant';
  accountId?: Maybe<Scalars['ID']['output']>;
  emails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  isOwner?: Maybe<Scalars['Boolean']['output']>;
  leaseFuture?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  leaseHolder?: Maybe<Scalars['Boolean']['output']>;
  leaseId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  propertyId?: Maybe<Scalars['ID']['output']>;
  unitId?: Maybe<Scalars['ID']['output']>;
};

export enum LeaseType {
  DoubleNet = 'double_net',
  GrossRent = 'gross_rent',
  ModifiedGrossRent = 'modified_gross_rent',
  Net = 'net',
  PercentageRent = 'percentage_rent',
  TripleNet = 'triple_net',
}

export type ListInboxMessagesFilter = {
  entities?: InputMaybe<Array<InputMaybe<InboxMessageEntities>>>;
  state?: InputMaybe<InboxMessageStates>;
};

export type ListedUnit = {
  __typename?: 'ListedUnit';
  bathrooms?: Maybe<Scalars['Float']['output']>;
  bedrooms?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  rent?: Maybe<Scalars['Float']['output']>;
  sqft?: Maybe<Scalars['Float']['output']>;
};

export type MaintenanceApproval = {
  __typename?: 'MaintenanceApproval';
  amount: Scalars['Float']['output'];
  approvedComment?: Maybe<Scalars['String']['output']>;
  approvedZ?: Maybe<Scalars['String']['output']>;
  declinedComment?: Maybe<Scalars['String']['output']>;
  declinedZ?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
};

export type Manager = {
  __typename?: 'Manager';
  accountId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Signature>;
};

export type ManagerCampaign = {
  __typename?: 'ManagerCampaign';
  accountId: Scalars['ID']['output'];
  history?: Maybe<Array<StatusHistoryEvent>>;
  id: Scalars['ID']['output'];
  listedUnits?: Maybe<Array<ListedUnit>>;
  name?: Maybe<Scalars['String']['output']>;
  property?: Maybe<ManagerProperty>;
  propertyId: Scalars['ID']['output'];
  status: CampaignStatus;
};

export type ManagerProperty = {
  __typename?: 'ManagerProperty';
  account?: Maybe<PropertyAccount>;
  accountId: Scalars['ID']['output'];
  address?: Maybe<Address>;
  amenitiesSetting?: Maybe<AmenitiesSetting>;
  autopayCollectionsEnabled?: Maybe<Scalars['Boolean']['output']>;
  commercialLeasedUnits?: Maybe<Scalars['Int']['output']>;
  commonAreas?: Maybe<Scalars['Int']['output']>;
  condoUnits?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  imageKey?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  leasedUnits?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  ownerId?: Maybe<Scalars['String']['output']>;
  ownerUnits?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  reserveAmount?: Maybe<Scalars['Float']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  totalUnits?: Maybe<Scalars['Int']['output']>;
  units?: Maybe<Array<Maybe<ManagerUnit>>>;
  window?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ManagerUnit = {
  __typename?: 'ManagerUnit';
  additionalInformation?: Maybe<Scalars['String']['output']>;
  bathrooms: Scalars['Float']['output'];
  bedrooms: Scalars['Float']['output'];
  condoFee?: Maybe<Scalars['Float']['output']>;
  condoFeeGst?: Maybe<Scalars['Float']['output']>;
  condoUnitFees?: Maybe<Array<Maybe<CondoUnitFee>>>;
  currentResidencies?: Maybe<Array<Residency>>;
  fingerprints?: Maybe<Fingerprints>;
  futureResidencies?: Maybe<Array<Residency>>;
  id: Scalars['ID']['output'];
  /** @deprecated Use `residency instead` */
  lease?: Maybe<Lease>;
  /** @deprecated Use `residency instead` */
  leaseFutureIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** @deprecated Use `residency instead` */
  leaseHistoryIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** @deprecated Use `residency instead` */
  leaseId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  occupancy?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Owner>;
  ownerId?: Maybe<Scalars['ID']['output']>;
  parking?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  property?: Maybe<ManagerProperty>;
  propertyId?: Maybe<Scalars['String']['output']>;
  reserveAmount?: Maybe<Scalars['Float']['output']>;
  sqft: Scalars['Float']['output'];
  storage?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  unitFactor?: Maybe<Scalars['Float']['output']>;
};

export type ManagerUnitFloorplan = {
  __typename?: 'ManagerUnitFloorplan';
  floorplan?: Maybe<Floorplan>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum MoveOutReason {
  EarlyMoveOut = 'EARLY_MOVE_OUT',
  Evicted = 'EVICTED',
  Expired = 'EXPIRED',
  Sublet = 'SUBLET',
  Terminated = 'TERMINATED',
}

export type Mutation = {
  __typename?: 'Mutation';
  addInsurancePolicy?: Maybe<InsurancePolicyPayload>;
  approveMaintenanceRequest?: Maybe<RequestPayload>;
  assetsShare?: Maybe<AssetsSharePayload>;
  assetsShareInvitation?: Maybe<AssetsSharePayload>;
  assetsShareSoftDelete?: Maybe<AssetsSharePayload>;
  cancelPad?: Maybe<AutopayEnrollmentPayload>;
  cancelRequest?: Maybe<Request>;
  createApplication?: Maybe<ApplicationPayload>;
  createAutopayEmbedUrl?: Maybe<CreateAutopayEmbedUrlPayload>;
  createAutopaySingle?: Maybe<AutopaySinglePayload>;
  createCampaign?: Maybe<CampaignPayload>;
  createFixedEnrollment?: Maybe<FixedEnrollmentPayload>;
  createRequest?: Maybe<CreateRequestPayload>;
  declineMaintenanceRequest?: Maybe<RequestPayload>;
  declinePadInvite?: Maybe<AutopayEnrollmentPayload>;
  deleteInsurancePolicy?: Maybe<InsurancePolicyPayload>;
  editInsurancePolicy?: Maybe<InsurancePolicyPayload>;
  finalizeRequest?: Maybe<CreateRequestPayload>;
  profileEvent?: Maybe<ProfileEvent>;
  reactivateAutopay?: Maybe<SuccessPayload>;
  recategorizeRequest?: Maybe<Request>;
  savePad?: Maybe<AutopayEnrollmentPayload>;
  sendInsurancePolicyDocument?: Maybe<InsurancePolicyPayload>;
  sendRequestLink?: Maybe<SendRequestLinkPayload>;
  signLeaseOffer?: Maybe<ApplicationPayload>;
  submitAppointment?: Maybe<Request>;
  submitRequest?: Maybe<Request>;
  suspendAutopay?: Maybe<SuccessPayload>;
  updateAdditionalTenants?: Maybe<ApplicationPayload>;
  updateDraft?: Maybe<Request>;
  updateInboxMessage?: Maybe<InboxMessagePayload>;
  updatePad?: Maybe<AutopayEnrollmentPayload>;
  updateSharedAssets?: Maybe<AssetsSharePayload>;
  user?: Maybe<UserMutation>;
};

export type MutationAddInsurancePolicyArgs = {
  input: InsurancePolicyInput;
};

export type MutationApproveMaintenanceRequestArgs = {
  input: ApproveRequestInput;
};

export type MutationAssetsShareArgs = {
  id: Scalars['ID']['input'];
};

export type MutationAssetsShareInvitationArgs = {
  input: AssetsShareInput;
};

export type MutationAssetsShareSoftDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationCancelPadArgs = {
  enrollmentId: Scalars['ID']['input'];
};

export type MutationCancelRequestArgs = {
  id: Scalars['ID']['input'];
};

export type MutationCreateApplicationArgs = {
  input: CreateApplicationInput;
};

export type MutationCreateAutopayEmbedUrlArgs = {
  payeeId: Scalars['ID']['input'];
};

export type MutationCreateAutopaySingleArgs = {
  input: CreateAutopaySingleInput;
};

export type MutationCreateCampaignArgs = {
  id: Scalars['ID']['input'];
  input: CreateCampaignInput;
};

export type MutationCreateFixedEnrollmentArgs = {
  input: FixedEnrollmentInput;
};

export type MutationCreateRequestArgs = {
  input?: InputMaybe<CreateRequestInput>;
};

export type MutationDeclineMaintenanceRequestArgs = {
  input: DeclineRequestInput;
};

export type MutationDeclinePadInviteArgs = {
  enrollmentId: Scalars['ID']['input'];
};

export type MutationDeleteInsurancePolicyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEditInsurancePolicyArgs = {
  id: Scalars['ID']['input'];
  input: InsurancePolicyInput;
};

export type MutationFinalizeRequestArgs = {
  input?: InputMaybe<FinalizeRequestInput>;
};

export type MutationProfileEventArgs = {
  input: ProfileEventInput;
};

export type MutationReactivateAutopayArgs = {
  payeeId: Scalars['ID']['input'];
};

export type MutationRecategorizeRequestArgs = {
  id: Scalars['ID']['input'];
  newCategory?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSavePadArgs = {
  enrollmentId: Scalars['ID']['input'];
  input: SavePadInput;
};

export type MutationSendInsurancePolicyDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSendRequestLinkArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSignLeaseOfferArgs = {
  input: SignLeaseOfferInput;
};

export type MutationSubmitAppointmentArgs = {
  id: Scalars['ID']['input'];
  input: AppointmentChangeInput;
};

export type MutationSubmitRequestArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSuspendAutopayArgs = {
  payeeId: Scalars['ID']['input'];
};

export type MutationUpdateAdditionalTenantsArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAdditionalTenantsInput;
};

export type MutationUpdateDraftArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<UpdateRequestInput>;
};

export type MutationUpdateInboxMessageArgs = {
  id: Scalars['ID']['input'];
  input: UpdateInboxMessageInput;
};

export type MutationUpdatePadArgs = {
  enrollmentId: Scalars['ID']['input'];
  input: UpdatePadInput;
};

export type MutationUpdateSharedAssetsArgs = {
  input: UpdateSharedAssetsInput;
};

export type Owner = {
  __typename?: 'Owner';
  accountId: Scalars['ID']['output'];
  address?: Maybe<Address>;
  assetFees?: Maybe<Array<Maybe<AssetFee>>>;
  correspondenceMethod?: Maybe<CorrespondenceMethod>;
  email?: Maybe<Scalars['String']['output']>;
  feeAmount?: Maybe<Scalars['Float']['output']>;
  feeRate?: Maybe<Scalars['Float']['output']>;
  flatFee?: Maybe<Scalars['Float']['output']>;
  gstFiling?: Maybe<FilingFrequency>;
  gstNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phones?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yearEndMonth?: Maybe<Scalars['Int']['output']>;
};

export type PayeeClearablesFilterItemInput = {
  accountId: Scalars['String']['input'];
  payeeId: Scalars['String']['input'];
};

export type PayeeClearablesSearchFilterInput = {
  after?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  payeeAccountIds: Array<PayeeClearablesFilterItemInput>;
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  unitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum PayeeType {
  Account = 'account',
  Operator = 'operator',
  Owner = 'owner',
  Supplier = 'supplier',
  Tenant = 'tenant',
}

export enum PaymentMethod {
  Eft = 'EFT',
  Cash = 'cash',
  Cheque = 'cheque',
  CreditCard = 'creditCard',
  Debit = 'debit',
  None = 'none',
  Pad = 'pad',
}

export type Pet = {
  __typename?: 'Pet';
  count: Scalars['Int']['output'];
  details?: Maybe<Array<PetDetails>>;
  type: Scalars['String']['output'];
};

export type PetDetails = {
  __typename?: 'PetDetails';
  breed?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  isService?: Maybe<Scalars['Boolean']['output']>;
  license?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['String']['output']>;
};

export type PetDetailsInput = {
  breed?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  isService?: InputMaybe<Scalars['Boolean']['input']>;
  license?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['String']['input']>;
};

export type PetInput = {
  count: Scalars['Int']['input'];
  details?: InputMaybe<Array<PetDetailsInput>>;
  type: Scalars['String']['input'];
};

export type PresignedImage = {
  __typename?: 'PresignedImage';
  tags?: Maybe<Array<Scalars['String']['output']>>;
  url: Scalars['String']['output'];
};

export type PresignedImagePayload = {
  __typename?: 'PresignedImagePayload';
  images?: Maybe<Array<PresignedImage>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Profile = {
  __typename?: 'Profile';
  addressHistory?: Maybe<Array<AddressHistory>>;
  assetsShares?: Maybe<Array<AssetsShare>>;
  autopay?: Maybe<Autopay>;
  consents?: Maybe<Array<Scalars['String']['output']>>;
  dob?: Maybe<Scalars['AWSDate']['output']>;
  email: Scalars['AWSEmail']['output'];
  emails?: Maybe<Array<Maybe<Scalars['AWSEmail']['output']>>>;
  emergencyContacts?: Maybe<Array<EmergencyContact>>;
  household?: Maybe<Household>;
  id: Scalars['ID']['output'];
  insurancePolicies?: Maybe<Array<InsurancePolicy>>;
  listInboxMessages: InboxMessageConnection;
  listRequests: ProfileRequestConnection;
  logins?: Maybe<Array<ProfileLogin>>;
  name: Scalars['String']['output'];
  otherIncome?: Maybe<Array<Income>>;
  owners?: Maybe<Array<ProfileOwner>>;
  phone?: Maybe<Scalars['String']['output']>;
  request?: Maybe<ProfileRequest>;
  requestsByProperty: ProfileRequestConnection;
  residents?: Maybe<Array<Maybe<Resident>>>;
  tenants?: Maybe<Array<ProfileTenant>>;
  workHistory?: Maybe<Array<WorkHistory>>;
};

export type ProfileInsurancePoliciesArgs = {
  filter?: InputMaybe<InsurancePolicyFilterInput>;
};

export type ProfileListInboxMessagesArgs = {
  filter?: InputMaybe<ListInboxMessagesFilter>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileListRequestsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileLoginsArgs = {
  status?: InputMaybe<ProfileLoginStatus>;
};

export type ProfileRequestArgs = {
  id: Scalars['ID']['input'];
};

export type ProfileRequestsByPropertyArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProfileEvent = {
  __typename?: 'ProfileEvent';
  detail?: Maybe<Scalars['AWSJSON']['output']>;
  detailType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  profileId: Scalars['ID']['output'];
  source: EventSource;
  time: Scalars['String']['output'];
};

export type ProfileEventInput = {
  event: Scalars['AWSJSON']['input'];
};

export type ProfileHeader = {
  __typename?: 'ProfileHeader';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sharedAssets?: Maybe<Array<Maybe<SharedMeta>>>;
};

export type ProfileLogin = {
  __typename?: 'ProfileLogin';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: ProfileLoginStatus;
  userAttributes?: Maybe<ProfileLoginUserAttributes>;
};

export enum ProfileLoginStatus {
  Archived = 'ARCHIVED',
  Compromised = 'COMPROMISED',
  Confirmed = 'CONFIRMED',
  ExternalProvider = 'EXTERNAL_PROVIDER',
  ForceChangePassword = 'FORCE_CHANGE_PASSWORD',
  ResetRequired = 'RESET_REQUIRED',
  Unconfirmed = 'UNCONFIRMED',
  Unknown = 'UNKNOWN',
}

export type ProfileLoginUserAttributes = {
  __typename?: 'ProfileLoginUserAttributes';
  identities?: Maybe<Scalars['String']['output']>;
};

export type ProfileOwner = {
  __typename?: 'ProfileOwner';
  accountId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
};

export type ProfileRequest = {
  __typename?: 'ProfileRequest';
  accountId?: Maybe<Scalars['ID']['output']>;
  appointmentPreferences?: Maybe<Array<Maybe<Interval>>>;
  appointmentZ?: Maybe<Scalars['AWSDateTime']['output']>;
  approval?: Maybe<MaintenanceApproval>;
  availabilityWindows?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  calculatedTaxes?: Maybe<Array<CalculatedTax>>;
  category?: Maybe<Scalars['String']['output']>;
  consents?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  costLines?: Maybe<Array<CostLine>>;
  createdZ?: Maybe<Scalars['AWSDateTime']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  entryConsentDetails?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<Maybe<Field>>>;
  firstOccurred?: Maybe<Scalars['String']['output']>;
  hasReminderPendingNotification?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  minutes?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  originalRequest?: Maybe<Scalars['String']['output']>;
  originalSubject?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['ID']['output']>;
  pendingNotifications?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  resolution?: Maybe<Scalars['String']['output']>;
  sentNotifications?: Maybe<Array<Maybe<SentNotification>>>;
  status?: Maybe<Scalars['String']['output']>;
  taxExempted?: Maybe<Scalars['Boolean']['output']>;
  unit?: Maybe<RequestUnit>;
  visits?: Maybe<Array<Maybe<Visit>>>;
};

export type ProfileRequestConnection = {
  __typename?: 'ProfileRequestConnection';
  items?: Maybe<Array<ProfileRequest>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ProfileTenant = {
  __typename?: 'ProfileTenant';
  accountId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
};

export type Property = {
  __typename?: 'Property';
  account?: Maybe<Account>;
  address?: Maybe<Address>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['ID']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type PropertyAccount = {
  __typename?: 'PropertyAccount';
  id?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<AccountSettings>;
};

export type PropertyExpensesDownloadInput = {
  documentKey: Scalars['String']['input'];
  ownerId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type PropertyExpensesFilterItemInput = {
  endDate: Scalars['AWSDate']['input'];
  ownerId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  startDate: Scalars['AWSDate']['input'];
};

export type PropertyHeader = {
  __typename?: 'PropertyHeader';
  address?: Maybe<Address>;
  id: Scalars['ID']['output'];
  imageKey?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sharedMeta?: Maybe<SharedMeta>;
};

export type Query = {
  __typename?: 'Query';
  accountAutopay?: Maybe<Account>;
  accountSettings?: Maybe<Account>;
  announcementAttachmentPresignedUrl?: Maybe<DocumentLinkPayload>;
  applications?: Maybe<Array<Maybe<Application>>>;
  applicationsByApplicationId?: Maybe<Array<Maybe<Application>>>;
  booksActivation?: Maybe<BooksActivatedPayload>;
  campaign?: Maybe<Campaign>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  categoryTemplate?: Maybe<Template>;
  documentLink?: Maybe<DocumentLinkPayload>;
  holidays?: Maybe<Array<Scalars['AWSDate']['output']>>;
  iamManager?: Maybe<IamManager>;
  inboxMessage?: Maybe<InboxMessage>;
  inspectionUrl?: Maybe<InspectionUrlPayload>;
  /** @deprecated Use resident to get lease. */
  lease?: Maybe<Lease>;
  /** @deprecated Use resident to get lease. */
  leases?: Maybe<Array<Maybe<Lease>>>;
  manager?: Maybe<Manager>;
  managerCampaign?: Maybe<ManagerCampaign>;
  owner?: Maybe<Owner>;
  payeeClearable?: Maybe<Clearable>;
  payeeClearables?: Maybe<ClearablesPage>;
  property?: Maybe<ManagerProperty>;
  propertyExpenses?: Maybe<Array<Maybe<ExpenseEntry>>>;
  propertyExpensesDocumentLink?: Maybe<DocumentLinkPayload>;
  propertyPhotos?: Maybe<PresignedImagePayload>;
  request?: Maybe<Request>;
  requestById?: Maybe<Request>;
  requests?: Maybe<Array<Maybe<Request>>>;
  residents?: Maybe<Array<Maybe<Resident>>>;
  tenant?: Maybe<Tenant>;
  tenantById?: Maybe<Tenant>;
  unit?: Maybe<ManagerUnit>;
  unitFloorplan?: Maybe<ManagerUnitFloorplan>;
  user?: Maybe<UserQuery>;
};

export type QueryAccountAutopayArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAccountSettingsArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAnnouncementAttachmentPresignedUrlArgs = {
  inboxMessageId: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type QueryApplicationsByApplicationIdArgs = {
  applicationId: Scalars['ID']['input'];
};

export type QueryBooksActivationArgs = {
  accountIds: Array<Scalars['ID']['input']>;
};

export type QueryCampaignArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCategoryTemplateArgs = {
  accountId: Scalars['ID']['input'];
  category: Scalars['String']['input'];
};

export type QueryDocumentLinkArgs = {
  key: Scalars['String']['input'];
  residentId: Scalars['ID']['input'];
};

export type QueryInboxMessageArgs = {
  id: Scalars['ID']['input'];
};

export type QueryInspectionUrlArgs = {
  accountId: Scalars['ID']['input'];
  input: InspectionUrlInput;
};

export type QueryLeaseArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLeasesArgs = {
  leases: Array<LeaseFilterItemInput>;
};

export type QueryManagerArgs = {
  id: Scalars['ID']['input'];
};

export type QueryManagerCampaignArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOwnerArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPayeeClearableArgs = {
  id: Scalars['ID']['input'];
  payeeAccountId: PayeeClearablesFilterItemInput;
};

export type QueryPayeeClearablesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  payeeAccountIds: Array<PayeeClearablesFilterItemInput>;
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  showPaid: Scalars['Boolean']['input'];
};

export type QueryPropertyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPropertyExpensesArgs = {
  filter: PropertyExpensesFilterItemInput;
};

export type QueryPropertyExpensesDocumentLinkArgs = {
  filter: PropertyExpensesDownloadInput;
};

export type QueryPropertyPhotosArgs = {
  propertyId: Scalars['ID']['input'];
};

export type QueryRequestArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRequestByIdArgs = {
  id: Scalars['ID']['input'];
  tenantId: Scalars['ID']['input'];
};

export type QueryRequestsArgs = {
  tenantId: Scalars['ID']['input'];
};

export type QueryTenantArgs = {
  email: Scalars['String']['input'];
};

export type QueryTenantByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUnitArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUnitFloorplanArgs = {
  id: Scalars['ID']['input'];
};

export type RateSchedule = {
  __typename?: 'RateSchedule';
  rent: Scalars['Float']['output'];
  start: Scalars['AWSDate']['output'];
};

export type RelayEdge = {
  cursor: Scalars['ID']['output'];
};

export type RelayPage = {
  edges: Array<RelayEdge>;
  pageInfo: RelayPageInfo;
};

export type RelayPageInfo = {
  __typename?: 'RelayPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type RentRollFee = {
  __typename?: 'RentRollFee';
  amount?: Maybe<Scalars['Float']['output']>;
  feeId: Scalars['ID']['output'];
};

export enum RentalType {
  Family = 'family',
  Own = 'own',
  Rental = 'rental',
}

export type Request = {
  __typename?: 'Request';
  accountId?: Maybe<Scalars['ID']['output']>;
  appointmentPreferences?: Maybe<Array<Maybe<Interval>>>;
  appointmentZ?: Maybe<Scalars['AWSDateTime']['output']>;
  approval?: Maybe<MaintenanceApproval>;
  availabilityWindows?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  calculatedTaxes?: Maybe<Array<CalculatedTax>>;
  category?: Maybe<Scalars['String']['output']>;
  consents?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  costLines?: Maybe<Array<CostLine>>;
  details?: Maybe<Scalars['String']['output']>;
  entryConsentDetails?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<Maybe<Field>>>;
  firstOccurred?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  hasReminderPendingNotification?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  minutes?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  originalRequest?: Maybe<Scalars['String']['output']>;
  originalSubject?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['ID']['output']>;
  pendingNotifications?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  resolution?: Maybe<Scalars['String']['output']>;
  sentNotifications?: Maybe<Array<Maybe<SentNotification>>>;
  status?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  taxExempted?: Maybe<Scalars['Boolean']['output']>;
  tenant?: Maybe<Tenant>;
  unit?: Maybe<Unit>;
  visits?: Maybe<Array<Maybe<Visit>>>;
};

export type RequestAccount = {
  __typename?: 'RequestAccount';
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type RequestFieldInput = {
  imageKey?: InputMaybe<Scalars['String']['input']>;
  info?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type RequestImageInput = {
  key: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type RequestPayload = {
  __typename?: 'RequestPayload';
  error?: Maybe<Scalars['String']['output']>;
  request?: Maybe<Request>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RequestProperty = {
  __typename?: 'RequestProperty';
  account?: Maybe<RequestAccount>;
  address?: Maybe<Address>;
  id?: Maybe<Scalars['String']['output']>;
  imageKey?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['ID']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type RequestUnit = {
  __typename?: 'RequestUnit';
  name?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['ID']['output']>;
  property?: Maybe<RequestProperty>;
};

export type Residency = {
  __typename?: 'Residency';
  additionalProvisions?: Maybe<AdditionalProvisions>;
  effects?: Maybe<Array<ResidencyEffect>>;
  endZ?: Maybe<Scalars['String']['output']>;
  gst?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  leaseAgreement?: Maybe<LeaseAgreement>;
  moveInInspection?: Maybe<LeaseInspection>;
  moveOutInspection?: Maybe<LeaseInspection>;
  moveOutReason?: Maybe<MoveOutReason>;
  occupancy?: Maybe<Scalars['String']['output']>;
  property?: Maybe<ManagerProperty>;
  propertyId: Scalars['ID']['output'];
  renewZ?: Maybe<Scalars['String']['output']>;
  residents: Array<ResidentAssociation>;
  rrule?: Maybe<Scalars['String']['output']>;
  securityDeposit?: Maybe<Scalars['Float']['output']>;
  startZ: Scalars['String']['output'];
  terms?: Maybe<Array<Scalars['String']['output']>>;
  type?: Maybe<LeaseType>;
  unit?: Maybe<ManagerUnit>;
  unitId: Scalars['ID']['output'];
  unitOwnerId?: Maybe<Scalars['ID']['output']>;
  utilities?: Maybe<Array<Scalars['String']['output']>>;
};

export type ResidencyEffect = {
  __typename?: 'ResidencyEffect';
  effect: Scalars['Float']['output'];
  feeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  startZ: Scalars['AWSDateTime']['output'];
};

export type Resident = {
  __typename?: 'Resident';
  accountHeader?: Maybe<Account>;
  accountId: Scalars['ID']['output'];
  autopayDetail?: Maybe<AutopayDetail>;
  autopayEnrollments?: Maybe<Array<AutopayEnrollment>>;
  currentResidencies?: Maybe<Array<Residency>>;
  documents?: Maybe<Array<Maybe<Document>>>;
  futureResidencies?: Maybe<Array<Residency>>;
  id: Scalars['ID']['output'];
  isOwner?: Maybe<Scalars['Boolean']['output']>;
  profileId: Scalars['ID']['output'];
  propertyHeaders?: Maybe<Array<PropertyHeader>>;
  propertyIds?: Maybe<Array<Scalars['ID']['output']>>;
  sharerHeader?: Maybe<ProfileHeader>;
  unitHeaders?: Maybe<Array<UnitHeader>>;
  unitIds?: Maybe<Array<Scalars['ID']['output']>>;
};

export type ResidentAssociation = {
  __typename?: 'ResidentAssociation';
  id: Scalars['ID']['output'];
  leaseHolder?: Maybe<Scalars['Boolean']['output']>;
  payeeFrac?: Maybe<Fraction>;
  residencyId: Scalars['ID']['output'];
  resident: ResidentRecord;
  residentId: Scalars['ID']['output'];
};

export type ResidentPayload = {
  __typename?: 'ResidentPayload';
  error?: Maybe<Scalars['String']['output']>;
  resident?: Maybe<Resident>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ResidentRecord = {
  __typename?: 'ResidentRecord';
  accountId: Scalars['ID']['output'];
  emails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phones?: Maybe<Array<Maybe<Scalars['AWSPhone']['output']>>>;
};

export enum ResidentRequestScheduling {
  None = 'NONE',
  Preferences = 'PREFERENCES',
}

export enum SalaryFrequency {
  AnnualSalary = 'annual_salary',
  HourlyRate = 'hourly_rate',
  MonthlySalary = 'monthly_salary',
}

export type SavePadInput = {
  agreeToWithdrawalTerms: Scalars['Boolean']['input'];
  bankAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type SendRequestLinkPayload = {
  __typename?: 'SendRequestLinkPayload';
  status?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SentNotification = {
  __typename?: 'SentNotification';
  event?: Maybe<Scalars['String']['output']>;
  sentZ?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type SharedMeta = {
  __typename?: 'SharedMeta';
  id?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  role?: Maybe<Scalars['String']['output']>;
  shared: Scalars['Boolean']['output'];
};

export type SignLeaseOfferInput = {
  applicationId: Scalars['ID']['input'];
  signature: SignatureInput;
};

export type Signature = {
  __typename?: 'Signature';
  id?: Maybe<Scalars['ID']['output']>;
  signature: Scalars['String']['output'];
  signerId?: Maybe<Scalars['ID']['output']>;
  signerName?: Maybe<Scalars['String']['output']>;
};

export type SignatureInput = {
  signature: Scalars['String']['input'];
  signerId?: InputMaybe<Scalars['ID']['input']>;
  signerName: Scalars['String']['input'];
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type StatusHistoryEvent = {
  __typename?: 'StatusHistoryEvent';
  createdZ?: Maybe<Scalars['AWSDateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onProfileEvent?: Maybe<ProfileEvent>;
};

export type SubscriptionOnProfileEventArgs = {
  profileId: Scalars['ID']['input'];
};

export type SuccessPayload = {
  __typename?: 'SuccessPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Template = {
  __typename?: 'Template';
  category?: Maybe<Scalars['String']['output']>;
  default?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  fields?: Maybe<Array<Maybe<TemplateField>>>;
  id: Scalars['ID']['output'];
  minutes?: Maybe<Scalars['Int']['output']>;
  response?: Maybe<Scalars['String']['output']>;
};

export type TemplateField = {
  __typename?: 'TemplateField';
  id?: Maybe<Scalars['ID']['output']>;
  imageKey?: Maybe<Scalars['String']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  accountId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use `resident` instead */
  allLeases?: Maybe<Array<Maybe<TenantLease>>>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Doesn't contain the accountId, use `allLeases` instead */
  leaseFuture?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  leaseId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use `allLeases` instead */
  leases?: Maybe<Array<Maybe<TenantLease>>>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  propertyId?: Maybe<Scalars['ID']['output']>;
  unitId?: Maybe<Scalars['ID']['output']>;
};

export type TenantInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  leaseId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  unitId?: InputMaybe<Scalars['ID']['input']>;
};

export type TenantLease = {
  __typename?: 'TenantLease';
  accountId: Scalars['ID']['output'];
  leaseHolder?: Maybe<Scalars['Boolean']['output']>;
  leaseId: Scalars['ID']['output'];
  propertyId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  unitId?: Maybe<Scalars['ID']['output']>;
};

export type TenantsLeaseholderInput = {
  id: Scalars['ID']['input'];
  payeeFrac?: InputMaybe<FractionInput>;
};

export type TotalByAccount = {
  __typename?: 'TotalByAccount';
  accountId: Scalars['ID']['output'];
  totalOutstanding?: Maybe<Scalars['Float']['output']>;
};

export type TotalByProperty = {
  __typename?: 'TotalByProperty';
  propertyId: Scalars['ID']['output'];
  totalOutstanding?: Maybe<Scalars['Float']['output']>;
};

export type TotalByUnit = {
  __typename?: 'TotalByUnit';
  totalOutstanding?: Maybe<Scalars['Float']['output']>;
  unitId: Scalars['ID']['output'];
};

export type Unit = {
  __typename?: 'Unit';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  occupancy?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['ID']['output']>;
  property?: Maybe<Property>;
};

export type UnitHeader = {
  __typename?: 'UnitHeader';
  address?: Maybe<Address>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  occupancy?: Maybe<Scalars['String']['output']>;
  propertyId: Scalars['ID']['output'];
  propertyImageKey?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  sharedMeta?: Maybe<SharedMeta>;
};

export type UpdateAdditionalTenantInput = {
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  id: Scalars['ID']['input'];
  leaseholder?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  payeeFrac?: InputMaybe<FractionInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  sentZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type UpdateAdditionalTenantsInput = {
  additionalTenants?: InputMaybe<Array<UpdateAdditionalTenantInput>>;
};

export type UpdateAddressHistoryInput = {
  address: AddressInput;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  endDate?: InputMaybe<Scalars['AWSDate']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  rentalType?: InputMaybe<RentalType>;
  startDate?: InputMaybe<Scalars['AWSDate']['input']>;
};

export type UpdateInboxMessageInput = {
  archivedZ?: InputMaybe<Scalars['String']['input']>;
  readZ?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOtherIncomeInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  source?: InputMaybe<IncomeSource>;
};

export type UpdatePadInput = {
  agreeToWithdrawalTerms: Scalars['Boolean']['input'];
  bankAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type UpdateProfileConsentsInput = {
  consents?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
};

export type UpdateProfileEmailInput = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateProfileInput = {
  dob?: InputMaybe<Scalars['AWSDate']['input']>;
  emergencyContacts?: InputMaybe<Array<EmergencyContactInput>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProfilePayload = {
  __typename?: 'UpdateProfilePayload';
  error?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Profile>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateRequestInput = {
  appointmentPreferences?: InputMaybe<Array<InputMaybe<IntervalInput>>>;
  category?: InputMaybe<Scalars['String']['input']>;
  consents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  details?: InputMaybe<Scalars['String']['input']>;
  entryConsentDetails?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<InputMaybe<FieldInput>>>;
  firstOccurred?: InputMaybe<Scalars['AWSDate']['input']>;
  pendingNotifications?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateSharedAssetsInput = {
  assets?: InputMaybe<Array<AssetInput>>;
  id: Scalars['ID']['input'];
};

export type UpdateTenantsPayeeFracInput = {
  tenantsLeaseholder?: InputMaybe<Array<TenantsLeaseholderInput>>;
};

export type UpdateVehicleInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
  plate: Scalars['String']['input'];
};

export type UpdateWorkHistoryInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['AWSDate']['input']>;
  frequency?: InputMaybe<SalaryFrequency>;
  id: Scalars['ID']['input'];
  location: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['AWSDate']['input']>;
};

export type UserMutation = {
  __typename?: 'UserMutation';
  addAdditionalTenant: UpdateProfilePayload;
  addAddressHistory: UpdateProfilePayload;
  addBankAccount: UpdateProfilePayload;
  addLogin: UpdateProfilePayload;
  addOtherIncome: UpdateProfilePayload;
  addVehicle: UpdateProfilePayload;
  addWorkHistory: UpdateProfilePayload;
  deleteAdditionalTenant: UpdateProfilePayload;
  deleteAddressHistory: UpdateProfilePayload;
  deleteBankAccount: UpdateProfilePayload;
  deleteLogin: UpdateProfilePayload;
  deleteOtherIncome: UpdateProfilePayload;
  deleteVehicle: UpdateProfilePayload;
  deleteWorkHistory: UpdateProfilePayload;
  editAdditionalTenant: UpdateProfilePayload;
  editAddressHistory: UpdateProfilePayload;
  editOtherIncome: UpdateProfilePayload;
  editVehicle: UpdateProfilePayload;
  editWorkHistory: UpdateProfilePayload;
  saveNoAdditionalTenants: UpdateProfilePayload;
  saveNoAddressHistory: UpdateProfilePayload;
  saveNoOtherIncome: UpdateProfilePayload;
  saveNoPets: UpdateProfilePayload;
  saveNoVehicles: UpdateProfilePayload;
  saveNoWorkHistory: UpdateProfilePayload;
  updatePets: UpdateProfilePayload;
  updateProfile: UpdateProfilePayload;
  updateProfileConsents: UpdateProfilePayload;
  updateProfileEmail: UpdateProfilePayload;
  updateTenantsPayeeFrac: UpdateProfilePayload;
};

export type UserMutationAddAdditionalTenantArgs = {
  input?: InputMaybe<AddAdditionalTenantInput>;
};

export type UserMutationAddAddressHistoryArgs = {
  input: AddAddressHistoryInput;
};

export type UserMutationAddBankAccountArgs = {
  input: AddBankAccountInput;
};

export type UserMutationAddLoginArgs = {
  input: AddLoginInput;
};

export type UserMutationAddOtherIncomeArgs = {
  input: AddOtherIncomeInput;
};

export type UserMutationAddVehicleArgs = {
  input?: InputMaybe<AddVehicleInput>;
};

export type UserMutationAddWorkHistoryArgs = {
  input: AddWorkHistoryInput;
};

export type UserMutationDeleteAdditionalTenantArgs = {
  id: Scalars['ID']['input'];
};

export type UserMutationDeleteAddressHistoryArgs = {
  id: Scalars['ID']['input'];
};

export type UserMutationDeleteBankAccountArgs = {
  id: Scalars['ID']['input'];
};

export type UserMutationDeleteLoginArgs = {
  id: Scalars['ID']['input'];
};

export type UserMutationDeleteOtherIncomeArgs = {
  id: Scalars['ID']['input'];
};

export type UserMutationDeleteVehicleArgs = {
  id: Scalars['ID']['input'];
};

export type UserMutationDeleteWorkHistoryArgs = {
  id: Scalars['ID']['input'];
};

export type UserMutationEditAdditionalTenantArgs = {
  input?: InputMaybe<UpdateAdditionalTenantInput>;
};

export type UserMutationEditAddressHistoryArgs = {
  input: UpdateAddressHistoryInput;
};

export type UserMutationEditOtherIncomeArgs = {
  input: UpdateOtherIncomeInput;
};

export type UserMutationEditVehicleArgs = {
  input?: InputMaybe<UpdateVehicleInput>;
};

export type UserMutationEditWorkHistoryArgs = {
  input: UpdateWorkHistoryInput;
};

export type UserMutationUpdatePetsArgs = {
  input?: InputMaybe<Array<PetInput>>;
};

export type UserMutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export type UserMutationUpdateProfileConsentsArgs = {
  input: UpdateProfileConsentsInput;
};

export type UserMutationUpdateProfileEmailArgs = {
  input: UpdateProfileEmailInput;
};

export type UserMutationUpdateTenantsPayeeFracArgs = {
  input: UpdateTenantsPayeeFracInput;
};

export type UserQuery = {
  __typename?: 'UserQuery';
  profile?: Maybe<Profile>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  color?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  plate: Scalars['String']['output'];
};

export type Visit = {
  __typename?: 'Visit';
  appointmentZ?: Maybe<Scalars['AWSDateTime']['output']>;
  delay?: Maybe<Scalars['String']['output']>;
  dueZ?: Maybe<Scalars['AWSDateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  minutes?: Maybe<Scalars['Int']['output']>;
  operatorName?: Maybe<Scalars['String']['output']>;
  pendingNotifications?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sentNotifications?: Maybe<SentNotification>;
  status?: Maybe<Scalars['String']['output']>;
  tenantProposedTimes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type WorkHistory = {
  __typename?: 'WorkHistory';
  amount?: Maybe<Scalars['Float']['output']>;
  endDate?: Maybe<Scalars['AWSDate']['output']>;
  frequency?: Maybe<SalaryFrequency>;
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['AWSDate']['output']>;
};

export type TenantLeaseFieldsFragment = {
  __typename?: 'TenantLease';
  leaseId: string;
  accountId: string;
  unitId?: string;
  propertyId?: string;
  status?: string;
  leaseHolder?: boolean;
};

export type GetTenantQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetTenantQuery = {
  __typename?: 'Query';
  tenantById?: {
    __typename?: 'Tenant';
    id: string;
    name: string;
    email?: string;
    phone?: string;
    leaseId?: string;
    accountId?: string;
    propertyId?: string;
    unitId?: string;
  };
};

export type ProfileWithResidentsQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileWithResidentsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserQuery';
    profile?: {
      __typename?: 'Profile';
      id: string;
      name: string;
      email: string;
      emails?: Array<string>;
      phone?: string;
      dob?: string;
      consents?: Array<string>;
      residents?: Array<{
        __typename?: 'Resident';
        id: string;
        accountId: string;
        isOwner?: boolean;
        autopayDetail?: {
          __typename?: 'AutopayDetail';
          id: string;
          autopayStatus?: string;
          autopayCollectionsEnabled?: boolean;
          autopayInstitution?: string;
          autopayAccount?: string;
          autopayTransit?: string;
          autopayClientId?: string;
        };
      }>;
      workHistory?: Array<{
        __typename?: 'WorkHistory';
        id: string;
        name: string;
        location: string;
        phone?: string;
        startDate?: string;
        endDate?: string;
        amount?: number;
        frequency?: SalaryFrequency;
      }>;
      otherIncome?: Array<{
        __typename?: 'Income';
        id: string;
        source: IncomeSource;
        amount: number;
      }>;
      addressHistory?: Array<{
        __typename?: 'AddressHistory';
        id: string;
        name: string;
        phone?: string;
        email?: string;
        startDate?: string;
        endDate?: string;
        rentalType?: RentalType;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      household?: {
        __typename?: 'Household';
        payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
        additionalTenants?: Array<{
          __typename?: 'AdditionalTenant';
          id: string;
          name: string;
          email?: string;
          phone?: string;
          leaseholder?: boolean;
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
        }>;
        pets?: Array<{
          __typename?: 'Pet';
          type: string;
          count: number;
          details?: Array<{
            __typename?: 'PetDetails';
            type?: string;
            breed?: string;
            weight?: string;
            name?: string;
            color?: string;
            license?: string;
            isService?: boolean;
          }>;
        }>;
        vehicles?: Array<{
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          make: string;
          model: string;
          color?: string;
        }>;
      };
      tenants?: Array<{ __typename?: 'ProfileTenant'; id: string; accountId: string }>;
      owners?: Array<{ __typename?: 'ProfileOwner'; id: string; accountId: string }>;
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        relationship?: ContactRelationship;
        phone: string;
        consentToShare: boolean;
      }>;
      autopay?: {
        __typename?: 'Autopay';
        bankAccounts?: Array<{
          __typename?: 'BankAccount';
          id: string;
          institutionName: string;
          accountDetails?: {
            __typename?: 'AccountDetail';
            accountNumber4Digit?: string;
            currency?: string;
            type?: string;
          };
        }>;
      };
    };
  };
};

export type AccountFieldsFragment = {
  __typename?: 'Account';
  id?: string;
  name?: string;
  address?: {
    __typename?: 'Address';
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    lat?: number;
    lng?: number;
  };
  settings?: {
    __typename?: 'AccountSettings';
    resident_request_scheduling?: ResidentRequestScheduling;
  };
};

export type AutopayEnrollmentFieldsFragment = {
  __typename?: 'AutopayEnrollment';
  id: string;
  residencyId?: string;
  residentId?: string;
  propertyId: string;
  unitId: string;
  status: AutopayEnrollmentStatus;
  invitedZ?: string;
  activatedZ?: string;
  declinedZ?: string;
  cancelledZ?: string;
  posted?: string;
  statusHistory?: Array<{
    __typename?: 'AutopayEnrollmentStatusHistory';
    status: AutopayEnrollmentStatus;
    createdZ: string;
    description?: string;
  }>;
  bankAccount?: {
    __typename?: 'BankAccount';
    id: string;
    institutionName: string;
    accountDetails?: {
      __typename?: 'AccountDetail';
      accountNumber4Digit?: string;
      currency?: string;
      type?: string;
    };
  };
  clearables?: Array<{ __typename?: 'FixedEnrollmentClearable'; id: string; amount: number }>;
};

export type LeaseInspectionFieldsFragment = {
  __typename?: 'LeaseInspection';
  id: string;
  category?: InspectionCategory;
  status?: InspectionStatus;
  completedZ?: string;
};

export type LeaseFieldsFragment = {
  __typename?: 'Lease';
  id: string;
  start?: string;
  end?: string;
  rent: number;
  securityDeposit?: number;
  status?: LeaseStatus;
  occupancy?: string;
  unitId?: string;
  originalLeaseId?: string;
  gst?: number;
  autoRenewSetup?: { __typename?: 'AutoRenewSetup'; enabled?: boolean; termMonths?: number };
  additionalProvisions?: {
    __typename?: 'AdditionalProvisions';
    pets?: number;
    parking?: boolean;
    storage?: boolean;
    smoking?: boolean;
  };
  property?: {
    __typename?: 'ManagerProperty';
    id: string;
    name: string;
    accountId: string;
    timezone?: string;
    imageKey?: string;
    window?: Array<string>;
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
    account?: { __typename?: 'PropertyAccount'; name: string; phone?: string };
  };
  unit?: { __typename?: 'Unit'; id?: string; name?: string; occupancy?: string };
  leaseAgreement?: { __typename?: 'LeaseAgreement'; agreementFileKey?: string };
  rateSchedule?: Array<{ __typename?: 'RateSchedule'; start: string; rent: number }>;
  rentRollFees?: Array<{ __typename?: 'RentRollFee'; feeId: string; amount?: number }>;
  incentives?: Array<{
    __typename?: 'LeaseIncentive';
    id: string;
    start: string;
    end: string;
    discountAmount: number;
    type: string;
    description?: string;
  }>;
};

export type ManagerFieldsFragment = {
  __typename?: 'Manager';
  id: string;
  accountId: string;
  name?: string;
};

export type ResidencyFieldsFragment = {
  __typename?: 'Residency';
  id: string;
  startZ: string;
  endZ?: string;
  securityDeposit?: number;
  propertyId: string;
  unitId: string;
  rrule?: string;
  type?: LeaseType;
  occupancy?: string;
  gst?: number;
  unitOwnerId?: string;
  utilities?: Array<string>;
  effects?: Array<{
    __typename?: 'ResidencyEffect';
    id: string;
    feeId: string;
    effect: number;
    startZ: string;
  }>;
  additionalProvisions?: {
    __typename?: 'AdditionalProvisions';
    pets?: number;
    parking?: boolean;
    storage?: boolean;
    smoking?: boolean;
  };
  unit?: {
    __typename?: 'ManagerUnit';
    id: string;
    name: string;
    ownerId?: string;
    parking?: Array<string>;
    storage?: Array<string>;
  };
  property?: {
    __typename?: 'ManagerProperty';
    id: string;
    accountId: string;
    name: string;
    key?: string;
    autopayCollectionsEnabled?: boolean;
    imageKey?: string;
    timezone?: string;
    window?: Array<string>;
    ownerId?: string;
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
    account?: { __typename?: 'PropertyAccount'; name: string; phone?: string };
  };
  leaseAgreement?: { __typename?: 'LeaseAgreement'; agreementFileKey?: string };
  moveInInspection?: {
    __typename?: 'LeaseInspection';
    id: string;
    category?: InspectionCategory;
    status?: InspectionStatus;
    completedZ?: string;
  };
  moveOutInspection?: {
    __typename?: 'LeaseInspection';
    id: string;
    category?: InspectionCategory;
    status?: InspectionStatus;
    completedZ?: string;
  };
};

export type PropertyHeaderFieldsFragment = {
  __typename?: 'PropertyHeader';
  id: string;
  name?: string;
  key?: string;
  imageKey?: string;
  address?: {
    __typename?: 'Address';
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    lat?: number;
    lng?: number;
  };
  sharedMeta?: {
    __typename?: 'SharedMeta';
    shared: boolean;
    role?: string;
    permissions?: Array<string>;
  };
};

export type UnitHeaderFieldsFragment = {
  __typename?: 'UnitHeader';
  id: string;
  propertyId: string;
  propertyName?: string;
  name?: string;
  occupancy?: string;
  propertyImageKey?: string;
  address?: {
    __typename?: 'Address';
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    lat?: number;
    lng?: number;
  };
  sharedMeta?: {
    __typename?: 'SharedMeta';
    shared: boolean;
    role?: string;
    permissions?: Array<string>;
  };
};

export type ResidentFieldsFragment = {
  __typename?: 'Resident';
  id: string;
  accountId: string;
  propertyIds?: Array<string>;
  unitIds?: Array<string>;
  isOwner?: boolean;
  propertyHeaders?: Array<{
    __typename?: 'PropertyHeader';
    id: string;
    name?: string;
    key?: string;
    imageKey?: string;
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      lat?: number;
      lng?: number;
    };
    sharedMeta?: {
      __typename?: 'SharedMeta';
      shared: boolean;
      role?: string;
      permissions?: Array<string>;
    };
  }>;
  unitHeaders?: Array<{
    __typename?: 'UnitHeader';
    id: string;
    propertyId: string;
    propertyName?: string;
    name?: string;
    occupancy?: string;
    propertyImageKey?: string;
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      lat?: number;
      lng?: number;
    };
    sharedMeta?: {
      __typename?: 'SharedMeta';
      shared: boolean;
      role?: string;
      permissions?: Array<string>;
    };
  }>;
  accountHeader?: {
    __typename?: 'Account';
    id?: string;
    name?: string;
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      lat?: number;
      lng?: number;
    };
    settings?: {
      __typename?: 'AccountSettings';
      resident_request_scheduling?: ResidentRequestScheduling;
    };
  };
  sharerHeader?: { __typename?: 'ProfileHeader'; id: string; name?: string; email?: string };
  documents?: Array<{
    __typename?: 'Document';
    key: string;
    name?: string;
    size?: number;
    createdZ?: string;
  }>;
  autopayEnrollments?: Array<{
    __typename?: 'AutopayEnrollment';
    id: string;
    residencyId?: string;
    residentId?: string;
    propertyId: string;
    unitId: string;
    status: AutopayEnrollmentStatus;
    invitedZ?: string;
    activatedZ?: string;
    declinedZ?: string;
    cancelledZ?: string;
    posted?: string;
    statusHistory?: Array<{
      __typename?: 'AutopayEnrollmentStatusHistory';
      status: AutopayEnrollmentStatus;
      createdZ: string;
      description?: string;
    }>;
    bankAccount?: {
      __typename?: 'BankAccount';
      id: string;
      institutionName: string;
      accountDetails?: {
        __typename?: 'AccountDetail';
        accountNumber4Digit?: string;
        currency?: string;
        type?: string;
      };
    };
    clearables?: Array<{ __typename?: 'FixedEnrollmentClearable'; id: string; amount: number }>;
  }>;
  currentResidencies?: Array<{
    __typename?: 'Residency';
    id: string;
    startZ: string;
    endZ?: string;
    securityDeposit?: number;
    propertyId: string;
    unitId: string;
    rrule?: string;
    type?: LeaseType;
    occupancy?: string;
    gst?: number;
    unitOwnerId?: string;
    utilities?: Array<string>;
    effects?: Array<{
      __typename?: 'ResidencyEffect';
      id: string;
      feeId: string;
      effect: number;
      startZ: string;
    }>;
    additionalProvisions?: {
      __typename?: 'AdditionalProvisions';
      pets?: number;
      parking?: boolean;
      storage?: boolean;
      smoking?: boolean;
    };
    unit?: {
      __typename?: 'ManagerUnit';
      id: string;
      name: string;
      ownerId?: string;
      parking?: Array<string>;
      storage?: Array<string>;
    };
    property?: {
      __typename?: 'ManagerProperty';
      id: string;
      accountId: string;
      name: string;
      key?: string;
      autopayCollectionsEnabled?: boolean;
      imageKey?: string;
      timezone?: string;
      window?: Array<string>;
      ownerId?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
      account?: { __typename?: 'PropertyAccount'; name: string; phone?: string };
    };
    leaseAgreement?: { __typename?: 'LeaseAgreement'; agreementFileKey?: string };
    moveInInspection?: {
      __typename?: 'LeaseInspection';
      id: string;
      category?: InspectionCategory;
      status?: InspectionStatus;
      completedZ?: string;
    };
    moveOutInspection?: {
      __typename?: 'LeaseInspection';
      id: string;
      category?: InspectionCategory;
      status?: InspectionStatus;
      completedZ?: string;
    };
  }>;
  futureResidencies?: Array<{
    __typename?: 'Residency';
    id: string;
    startZ: string;
    endZ?: string;
    securityDeposit?: number;
    propertyId: string;
    unitId: string;
    rrule?: string;
    type?: LeaseType;
    occupancy?: string;
    gst?: number;
    unitOwnerId?: string;
    utilities?: Array<string>;
    effects?: Array<{
      __typename?: 'ResidencyEffect';
      id: string;
      feeId: string;
      effect: number;
      startZ: string;
    }>;
    additionalProvisions?: {
      __typename?: 'AdditionalProvisions';
      pets?: number;
      parking?: boolean;
      storage?: boolean;
      smoking?: boolean;
    };
    unit?: {
      __typename?: 'ManagerUnit';
      id: string;
      name: string;
      ownerId?: string;
      parking?: Array<string>;
      storage?: Array<string>;
    };
    property?: {
      __typename?: 'ManagerProperty';
      id: string;
      accountId: string;
      name: string;
      key?: string;
      autopayCollectionsEnabled?: boolean;
      imageKey?: string;
      timezone?: string;
      window?: Array<string>;
      ownerId?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
      account?: { __typename?: 'PropertyAccount'; name: string; phone?: string };
    };
    leaseAgreement?: { __typename?: 'LeaseAgreement'; agreementFileKey?: string };
    moveInInspection?: {
      __typename?: 'LeaseInspection';
      id: string;
      category?: InspectionCategory;
      status?: InspectionStatus;
      completedZ?: string;
    };
    moveOutInspection?: {
      __typename?: 'LeaseInspection';
      id: string;
      category?: InspectionCategory;
      status?: InspectionStatus;
      completedZ?: string;
    };
  }>;
  autopayDetail?: {
    __typename?: 'AutopayDetail';
    id: string;
    autopayStatus?: string;
    autopayCollectionsEnabled?: boolean;
    autopayInstitution?: string;
    autopayAccount?: string;
    autopayTransit?: string;
    autopayClientId?: string;
  };
};

export type UnitFieldsFragment = {
  __typename?: 'ManagerUnit';
  id: string;
  name: string;
  sqft: number;
  bedrooms: number;
  bathrooms: number;
  additionalInformation?: string;
  occupancy?: string;
  unitFactor?: number;
  condoFee?: number;
  condoFeeGst?: number;
  parking?: Array<string>;
  storage?: Array<string>;
  propertyId?: string;
  reserveAmount?: number;
  ownerId?: string;
  condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
  property?: {
    __typename?: 'ManagerProperty';
    id: string;
    name: string;
    phone?: string;
    imageKey?: string;
    timezone?: string;
    key?: string;
    accountId: string;
    ownerId?: string;
    window?: Array<string>;
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      lat?: number;
      lng?: number;
    };
    account?: {
      __typename?: 'PropertyAccount';
      id?: string;
      name: string;
      settings?: {
        __typename?: 'AccountSettings';
        resident_request_scheduling?: ResidentRequestScheduling;
      };
    };
  };
  currentResidencies?: Array<{
    __typename?: 'Residency';
    id: string;
    startZ: string;
    endZ?: string;
    securityDeposit?: number;
    propertyId: string;
    unitId: string;
    rrule?: string;
    type?: LeaseType;
    occupancy?: string;
    gst?: number;
    utilities?: Array<string>;
    effects?: Array<{
      __typename?: 'ResidencyEffect';
      id: string;
      feeId: string;
      effect: number;
      startZ: string;
    }>;
    additionalProvisions?: {
      __typename?: 'AdditionalProvisions';
      pets?: number;
      parking?: boolean;
      storage?: boolean;
      smoking?: boolean;
    };
    residents: Array<{
      __typename?: 'ResidentAssociation';
      id: string;
      residentId: string;
      leaseHolder?: boolean;
      resident: {
        __typename?: 'ResidentRecord';
        id: string;
        accountId: string;
        name: string;
        emails?: Array<string>;
        phones?: Array<string>;
      };
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
    }>;
  }>;
  futureResidencies?: Array<{
    __typename?: 'Residency';
    id: string;
    startZ: string;
    endZ?: string;
    securityDeposit?: number;
    propertyId: string;
    unitId: string;
    rrule?: string;
    type?: LeaseType;
    occupancy?: string;
    gst?: number;
    utilities?: Array<string>;
    effects?: Array<{
      __typename?: 'ResidencyEffect';
      id: string;
      feeId: string;
      effect: number;
      startZ: string;
    }>;
    additionalProvisions?: {
      __typename?: 'AdditionalProvisions';
      pets?: number;
      parking?: boolean;
      storage?: boolean;
      smoking?: boolean;
    };
    residents: Array<{
      __typename?: 'ResidentAssociation';
      id: string;
      residentId: string;
      leaseHolder?: boolean;
      resident: {
        __typename?: 'ResidentRecord';
        id: string;
        accountId: string;
        name: string;
        emails?: Array<string>;
        phones?: Array<string>;
      };
      payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
    }>;
  }>;
};

export type ResidentRecordFieldsFragment = {
  __typename?: 'ResidentRecord';
  id: string;
  accountId: string;
  name: string;
  emails?: Array<string>;
  phones?: Array<string>;
};

export type UnitResidencyFieldsFragment = {
  __typename?: 'Residency';
  id: string;
  startZ: string;
  endZ?: string;
  securityDeposit?: number;
  propertyId: string;
  unitId: string;
  rrule?: string;
  type?: LeaseType;
  occupancy?: string;
  gst?: number;
  utilities?: Array<string>;
  effects?: Array<{
    __typename?: 'ResidencyEffect';
    id: string;
    feeId: string;
    effect: number;
    startZ: string;
  }>;
  additionalProvisions?: {
    __typename?: 'AdditionalProvisions';
    pets?: number;
    parking?: boolean;
    storage?: boolean;
    smoking?: boolean;
  };
  residents: Array<{
    __typename?: 'ResidentAssociation';
    id: string;
    residentId: string;
    leaseHolder?: boolean;
    resident: {
      __typename?: 'ResidentRecord';
      id: string;
      accountId: string;
      name: string;
      emails?: Array<string>;
      phones?: Array<string>;
    };
    payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
  }>;
};

export type AddLoginMutationVariables = Exact<{
  input: AddLoginInput;
}>;

export type AddLoginMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    addLogin: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        email: string;
        logins?: Array<{
          __typename?: 'ProfileLogin';
          id: string;
          email: string;
          status: ProfileLoginStatus;
          userAttributes?: { __typename?: 'ProfileLoginUserAttributes'; identities?: string };
        }>;
      };
    };
  };
};

export type GetBooksActivationQueryVariables = Exact<{
  accountIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetBooksActivationQuery = {
  __typename?: 'Query';
  booksActivation?: { __typename?: 'BooksActivatedPayload'; anyActivated?: boolean };
};

export type GetHolidaysQueryVariables = Exact<{ [key: string]: never }>;

export type GetHolidaysQuery = { __typename?: 'Query'; holidays?: Array<string> };

export type DeleteLoginMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteLoginMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    deleteLogin: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        logins?: Array<{
          __typename?: 'ProfileLogin';
          id: string;
          email: string;
          status: ProfileLoginStatus;
          userAttributes?: { __typename?: 'ProfileLoginUserAttributes'; identities?: string };
        }>;
      };
    };
  };
};

export type GetManagerQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetManagerQuery = {
  __typename?: 'Query';
  manager?: { __typename?: 'Manager'; id: string; accountId: string; name?: string };
};

export type ProfileLoginFieldsFragment = {
  __typename?: 'ProfileLogin';
  id: string;
  email: string;
  status: ProfileLoginStatus;
  userAttributes?: { __typename?: 'ProfileLoginUserAttributes'; identities?: string };
};

export type GetProfileLoginsQueryVariables = Exact<{ [key: string]: never }>;

export type GetProfileLoginsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserQuery';
    profile?: {
      __typename?: 'Profile';
      id: string;
      email: string;
      logins?: Array<{
        __typename?: 'ProfileLogin';
        id: string;
        email: string;
        status: ProfileLoginStatus;
        userAttributes?: { __typename?: 'ProfileLoginUserAttributes'; identities?: string };
      }>;
    };
  };
};

export type ResidentsQueryVariables = Exact<{ [key: string]: never }>;

export type ResidentsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserQuery';
    profile?: {
      __typename?: 'Profile';
      id: string;
      residents?: Array<{
        __typename?: 'Resident';
        id: string;
        accountId: string;
        propertyIds?: Array<string>;
        unitIds?: Array<string>;
        isOwner?: boolean;
        propertyHeaders?: Array<{
          __typename?: 'PropertyHeader';
          id: string;
          name?: string;
          key?: string;
          imageKey?: string;
          address?: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            lat?: number;
            lng?: number;
          };
          sharedMeta?: {
            __typename?: 'SharedMeta';
            shared: boolean;
            role?: string;
            permissions?: Array<string>;
          };
        }>;
        unitHeaders?: Array<{
          __typename?: 'UnitHeader';
          id: string;
          propertyId: string;
          propertyName?: string;
          name?: string;
          occupancy?: string;
          propertyImageKey?: string;
          address?: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            lat?: number;
            lng?: number;
          };
          sharedMeta?: {
            __typename?: 'SharedMeta';
            shared: boolean;
            role?: string;
            permissions?: Array<string>;
          };
        }>;
        accountHeader?: {
          __typename?: 'Account';
          id?: string;
          name?: string;
          address?: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
            lat?: number;
            lng?: number;
          };
          settings?: {
            __typename?: 'AccountSettings';
            resident_request_scheduling?: ResidentRequestScheduling;
          };
        };
        sharerHeader?: { __typename?: 'ProfileHeader'; id: string; name?: string; email?: string };
        documents?: Array<{
          __typename?: 'Document';
          key: string;
          name?: string;
          size?: number;
          createdZ?: string;
        }>;
        autopayEnrollments?: Array<{
          __typename?: 'AutopayEnrollment';
          id: string;
          residencyId?: string;
          residentId?: string;
          propertyId: string;
          unitId: string;
          status: AutopayEnrollmentStatus;
          invitedZ?: string;
          activatedZ?: string;
          declinedZ?: string;
          cancelledZ?: string;
          posted?: string;
          statusHistory?: Array<{
            __typename?: 'AutopayEnrollmentStatusHistory';
            status: AutopayEnrollmentStatus;
            createdZ: string;
            description?: string;
          }>;
          bankAccount?: {
            __typename?: 'BankAccount';
            id: string;
            institutionName: string;
            accountDetails?: {
              __typename?: 'AccountDetail';
              accountNumber4Digit?: string;
              currency?: string;
              type?: string;
            };
          };
          clearables?: Array<{
            __typename?: 'FixedEnrollmentClearable';
            id: string;
            amount: number;
          }>;
        }>;
        currentResidencies?: Array<{
          __typename?: 'Residency';
          id: string;
          startZ: string;
          endZ?: string;
          securityDeposit?: number;
          propertyId: string;
          unitId: string;
          rrule?: string;
          type?: LeaseType;
          occupancy?: string;
          gst?: number;
          unitOwnerId?: string;
          utilities?: Array<string>;
          effects?: Array<{
            __typename?: 'ResidencyEffect';
            id: string;
            feeId: string;
            effect: number;
            startZ: string;
          }>;
          additionalProvisions?: {
            __typename?: 'AdditionalProvisions';
            pets?: number;
            parking?: boolean;
            storage?: boolean;
            smoking?: boolean;
          };
          unit?: {
            __typename?: 'ManagerUnit';
            id: string;
            name: string;
            ownerId?: string;
            parking?: Array<string>;
            storage?: Array<string>;
          };
          property?: {
            __typename?: 'ManagerProperty';
            id: string;
            accountId: string;
            name: string;
            key?: string;
            autopayCollectionsEnabled?: boolean;
            imageKey?: string;
            timezone?: string;
            window?: Array<string>;
            ownerId?: string;
            address?: {
              __typename?: 'Address';
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
            account?: { __typename?: 'PropertyAccount'; name: string; phone?: string };
          };
          leaseAgreement?: { __typename?: 'LeaseAgreement'; agreementFileKey?: string };
          moveInInspection?: {
            __typename?: 'LeaseInspection';
            id: string;
            category?: InspectionCategory;
            status?: InspectionStatus;
            completedZ?: string;
          };
          moveOutInspection?: {
            __typename?: 'LeaseInspection';
            id: string;
            category?: InspectionCategory;
            status?: InspectionStatus;
            completedZ?: string;
          };
        }>;
        futureResidencies?: Array<{
          __typename?: 'Residency';
          id: string;
          startZ: string;
          endZ?: string;
          securityDeposit?: number;
          propertyId: string;
          unitId: string;
          rrule?: string;
          type?: LeaseType;
          occupancy?: string;
          gst?: number;
          unitOwnerId?: string;
          utilities?: Array<string>;
          effects?: Array<{
            __typename?: 'ResidencyEffect';
            id: string;
            feeId: string;
            effect: number;
            startZ: string;
          }>;
          additionalProvisions?: {
            __typename?: 'AdditionalProvisions';
            pets?: number;
            parking?: boolean;
            storage?: boolean;
            smoking?: boolean;
          };
          unit?: {
            __typename?: 'ManagerUnit';
            id: string;
            name: string;
            ownerId?: string;
            parking?: Array<string>;
            storage?: Array<string>;
          };
          property?: {
            __typename?: 'ManagerProperty';
            id: string;
            accountId: string;
            name: string;
            key?: string;
            autopayCollectionsEnabled?: boolean;
            imageKey?: string;
            timezone?: string;
            window?: Array<string>;
            ownerId?: string;
            address?: {
              __typename?: 'Address';
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
            account?: { __typename?: 'PropertyAccount'; name: string; phone?: string };
          };
          leaseAgreement?: { __typename?: 'LeaseAgreement'; agreementFileKey?: string };
          moveInInspection?: {
            __typename?: 'LeaseInspection';
            id: string;
            category?: InspectionCategory;
            status?: InspectionStatus;
            completedZ?: string;
          };
          moveOutInspection?: {
            __typename?: 'LeaseInspection';
            id: string;
            category?: InspectionCategory;
            status?: InspectionStatus;
            completedZ?: string;
          };
        }>;
        autopayDetail?: {
          __typename?: 'AutopayDetail';
          id: string;
          autopayStatus?: string;
          autopayCollectionsEnabled?: boolean;
          autopayInstitution?: string;
          autopayAccount?: string;
          autopayTransit?: string;
          autopayClientId?: string;
        };
      }>;
    };
  };
};

export type GetUnitQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUnitQuery = {
  __typename?: 'Query';
  unit?: {
    __typename?: 'ManagerUnit';
    id: string;
    name: string;
    sqft: number;
    bedrooms: number;
    bathrooms: number;
    additionalInformation?: string;
    occupancy?: string;
    unitFactor?: number;
    condoFee?: number;
    condoFeeGst?: number;
    parking?: Array<string>;
    storage?: Array<string>;
    propertyId?: string;
    reserveAmount?: number;
    ownerId?: string;
    condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
    property?: {
      __typename?: 'ManagerProperty';
      id: string;
      name: string;
      phone?: string;
      imageKey?: string;
      timezone?: string;
      key?: string;
      accountId: string;
      ownerId?: string;
      window?: Array<string>;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
        lat?: number;
        lng?: number;
      };
      account?: {
        __typename?: 'PropertyAccount';
        id?: string;
        name: string;
        settings?: {
          __typename?: 'AccountSettings';
          resident_request_scheduling?: ResidentRequestScheduling;
        };
      };
    };
    currentResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      startZ: string;
      endZ?: string;
      securityDeposit?: number;
      propertyId: string;
      unitId: string;
      rrule?: string;
      type?: LeaseType;
      occupancy?: string;
      gst?: number;
      utilities?: Array<string>;
      effects?: Array<{
        __typename?: 'ResidencyEffect';
        id: string;
        feeId: string;
        effect: number;
        startZ: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        resident: {
          __typename?: 'ResidentRecord';
          id: string;
          accountId: string;
          name: string;
          emails?: Array<string>;
          phones?: Array<string>;
        };
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
      }>;
    }>;
    futureResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      startZ: string;
      endZ?: string;
      securityDeposit?: number;
      propertyId: string;
      unitId: string;
      rrule?: string;
      type?: LeaseType;
      occupancy?: string;
      gst?: number;
      utilities?: Array<string>;
      effects?: Array<{
        __typename?: 'ResidencyEffect';
        id: string;
        feeId: string;
        effect: number;
        startZ: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
      residents: Array<{
        __typename?: 'ResidentAssociation';
        id: string;
        residentId: string;
        leaseHolder?: boolean;
        resident: {
          __typename?: 'ResidentRecord';
          id: string;
          accountId: string;
          name: string;
          emails?: Array<string>;
          phones?: Array<string>;
        };
        payeeFrac?: { __typename?: 'Fraction'; numerator: number; denominator: number };
      }>;
    }>;
  };
};

export type UpdateProfileEmailMutationVariables = Exact<{
  input: UpdateProfileEmailInput;
}>;

export type UpdateProfileEmailMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    updateProfileEmail: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        email: string;
        logins?: Array<{
          __typename?: 'ProfileLogin';
          id: string;
          email: string;
          status: ProfileLoginStatus;
          userAttributes?: { __typename?: 'ProfileLoginUserAttributes'; identities?: string };
        }>;
      };
    };
  };
};

export type AnnouncementAttachmentPresignedUrlQueryVariables = Exact<{
  key: Scalars['String']['input'];
  inboxMessageId: Scalars['String']['input'];
}>;

export type AnnouncementAttachmentPresignedUrlQuery = {
  __typename?: 'Query';
  announcementAttachmentPresignedUrl?: {
    __typename?: 'DocumentLinkPayload';
    link?: string;
    error?: string;
  };
};

export type ListAnnouncementInboxQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<InboxMessageStates>;
}>;

export type ListAnnouncementInboxQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserQuery';
    profile?: {
      __typename?: 'Profile';
      id: string;
      listInboxMessages: {
        __typename?: 'InboxMessageConnection';
        nextToken?: string;
        items?: Array<{
          __typename?: 'InboxMessage';
          id: string;
          accountId: string;
          emailBody?: string;
          emailSubject?: string;
          publishZ?: string;
          readZ?: string;
          archivedZ?: string;
          announcementId?: string;
        }>;
      };
    };
  };
};

export type GetInboxMessageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetInboxMessageQuery = {
  __typename?: 'Query';
  inboxMessage?: {
    __typename?: 'InboxMessage';
    id: string;
    accountId: string;
    emailBody?: string;
    emailSubject?: string;
    publishZ?: string;
    readZ?: string;
    archivedZ?: string;
    attachments?: Array<{
      __typename?: 'InboxMessageAttachment';
      key: string;
      name?: string;
      type?: string;
    }>;
  };
};

export type GetInboxMessageForActionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetInboxMessageForActionsQuery = {
  __typename?: 'Query';
  inboxMessage?: { __typename?: 'InboxMessage'; id: string; readZ?: string; archivedZ?: string };
};

export type UpdateInboxMessageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateInboxMessageInput;
}>;

export type UpdateInboxMessageMutation = {
  __typename?: 'Mutation';
  updateInboxMessage?: {
    __typename?: 'InboxMessagePayload';
    success?: boolean;
    error?: string;
    inboxMessage?: { __typename?: 'InboxMessage'; id: string; readZ?: string; archivedZ?: string };
  };
};

export type ExpenseEntryFieldsFragment = {
  __typename?: 'ExpenseEntry';
  id: string;
  glId: string;
  ownerId: string;
  propertyId: string;
  unitId?: string;
  amount: number;
  description?: string;
  ref?: string;
  posted: string;
  glName?: string;
  glBalanceType?: string;
  glAccountType?: string;
  requestId?: string;
  gstAmount?: number;
  clearableId?: string;
  paidDate?: string;
  outstanding?: number;
  documents?: Array<{
    __typename?: 'Document';
    name?: string;
    key: string;
    size?: number;
    createdZ?: string;
    typename?: string;
  }>;
};

export type GetAccountDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetAccountDetailsQuery = {
  __typename?: 'Query';
  accountSettings?: {
    __typename?: 'Account';
    id?: string;
    name?: string;
    logoKey?: string;
    books?: { __typename?: 'AccountBooks'; gstNumber?: string };
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      suite?: string;
      lat?: number;
      lng?: number;
    };
  };
};

export type GetPropertyExpensesQueryVariables = Exact<{
  filter: PropertyExpensesFilterItemInput;
}>;

export type GetPropertyExpensesQuery = {
  __typename?: 'Query';
  propertyExpenses?: Array<{
    __typename?: 'ExpenseEntry';
    id: string;
    glId: string;
    ownerId: string;
    propertyId: string;
    unitId?: string;
    amount: number;
    description?: string;
    ref?: string;
    posted: string;
    glName?: string;
    glBalanceType?: string;
    glAccountType?: string;
    requestId?: string;
    gstAmount?: number;
    clearableId?: string;
    paidDate?: string;
    outstanding?: number;
    documents?: Array<{
      __typename?: 'Document';
      name?: string;
      key: string;
      size?: number;
      createdZ?: string;
      typename?: string;
    }>;
  }>;
};

export type GetExpensesDocumentLinkQueryVariables = Exact<{
  filter: PropertyExpensesDownloadInput;
}>;

export type GetExpensesDocumentLinkQuery = {
  __typename?: 'Query';
  propertyExpensesDocumentLink?: {
    __typename?: 'DocumentLinkPayload';
    link?: string;
    error?: string;
  };
};

export type GetOwnerNameAndAddressQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOwnerNameAndAddressQuery = {
  __typename?: 'Query';
  owner?: {
    __typename?: 'Owner';
    id: string;
    name: string;
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  };
};

export type GetRequestDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetRequestDetailsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserQuery';
    profile?: {
      __typename?: 'Profile';
      id: string;
      request?: {
        __typename?: 'ProfileRequest';
        accountId?: string;
        id: string;
        category?: string;
        name?: string;
        originalSubject?: string;
        originalRequest?: string;
        status?: string;
        taxExempted?: boolean;
        resolution?: string;
        costLines?: Array<{
          __typename?: 'CostLine';
          id: string;
          amount: number;
          visitNumber?: number;
          markup?: number;
          description?: string;
          total: number;
        }>;
        calculatedTaxes?: Array<{ __typename?: 'CalculatedTax'; label: string; amount?: number }>;
        unit?: {
          __typename?: 'RequestUnit';
          name?: string;
          ownerId?: string;
          property?: {
            __typename?: 'RequestProperty';
            ownerId?: string;
            name?: string;
            phone?: string;
            key?: string;
            address?: {
              __typename?: 'Address';
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
            account?: { __typename?: 'RequestAccount'; name?: string; phone?: string };
          };
        };
      };
    };
  };
};

export type OwnerFieldsFragment = {
  __typename?: 'Owner';
  id: string;
  name: string;
  email?: string;
  yearEndMonth?: number;
  gstNumber?: string;
  gstFiling?: FilingFrequency;
  feeRate?: number;
  feeAmount?: number;
  flatFee?: number;
  correspondenceMethod?: CorrespondenceMethod;
  address?: {
    __typename?: 'Address';
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    lat?: number;
    lng?: number;
  };
  assetFees?: Array<{
    __typename?: 'AssetFee';
    assetId: string;
    type: AssetType;
    feeRate?: number;
    feeAmount?: number;
    flatFee?: number;
  }>;
};

export type PropertyUnitResidencyFieldsFragment = {
  __typename?: 'Residency';
  id: string;
  startZ: string;
  endZ?: string;
  renewZ?: string;
  terms?: Array<string>;
  moveOutReason?: MoveOutReason;
  securityDeposit?: number;
  propertyId: string;
  unitId: string;
  rrule?: string;
  type?: LeaseType;
  gst?: number;
  occupancy?: string;
  utilities?: Array<string>;
  effects?: Array<{
    __typename?: 'ResidencyEffect';
    id: string;
    feeId: string;
    effect: number;
    startZ: string;
  }>;
  additionalProvisions?: {
    __typename?: 'AdditionalProvisions';
    pets?: number;
    parking?: boolean;
    storage?: boolean;
    smoking?: boolean;
  };
};

export type PropertyUnitFieldsFragment = {
  __typename?: 'ManagerUnit';
  id: string;
  name: string;
  occupancy?: string;
  condoFee?: number;
  condoFeeGst?: number;
  condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
  currentResidencies?: Array<{
    __typename?: 'Residency';
    id: string;
    startZ: string;
    endZ?: string;
    renewZ?: string;
    terms?: Array<string>;
    moveOutReason?: MoveOutReason;
    securityDeposit?: number;
    propertyId: string;
    unitId: string;
    rrule?: string;
    type?: LeaseType;
    gst?: number;
    occupancy?: string;
    utilities?: Array<string>;
    effects?: Array<{
      __typename?: 'ResidencyEffect';
      id: string;
      feeId: string;
      effect: number;
      startZ: string;
    }>;
    additionalProvisions?: {
      __typename?: 'AdditionalProvisions';
      pets?: number;
      parking?: boolean;
      storage?: boolean;
      smoking?: boolean;
    };
  }>;
  futureResidencies?: Array<{
    __typename?: 'Residency';
    id: string;
    startZ: string;
    endZ?: string;
    renewZ?: string;
    terms?: Array<string>;
    moveOutReason?: MoveOutReason;
    securityDeposit?: number;
    propertyId: string;
    unitId: string;
    rrule?: string;
    type?: LeaseType;
    gst?: number;
    occupancy?: string;
    utilities?: Array<string>;
    effects?: Array<{
      __typename?: 'ResidencyEffect';
      id: string;
      feeId: string;
      effect: number;
      startZ: string;
    }>;
    additionalProvisions?: {
      __typename?: 'AdditionalProvisions';
      pets?: number;
      parking?: boolean;
      storage?: boolean;
      smoking?: boolean;
    };
  }>;
  owner?: { __typename?: 'Owner'; id: string; name: string };
};

export type PropertyFieldsFragment = {
  __typename?: 'ManagerProperty';
  id: string;
  name: string;
  phone?: string;
  imageKey?: string;
  timezone?: string;
  totalUnits?: number;
  leasedUnits?: number;
  commercialLeasedUnits?: number;
  commonAreas?: number;
  ownerUnits?: number;
  condoUnits?: number;
  autopayCollectionsEnabled?: boolean;
  reserveAmount?: number;
  address?: {
    __typename?: 'Address';
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    lat?: number;
    lng?: number;
  };
  units?: Array<{
    __typename?: 'ManagerUnit';
    id: string;
    name: string;
    occupancy?: string;
    condoFee?: number;
    condoFeeGst?: number;
    condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
    currentResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      terms?: Array<string>;
      moveOutReason?: MoveOutReason;
      securityDeposit?: number;
      propertyId: string;
      unitId: string;
      rrule?: string;
      type?: LeaseType;
      gst?: number;
      occupancy?: string;
      utilities?: Array<string>;
      effects?: Array<{
        __typename?: 'ResidencyEffect';
        id: string;
        feeId: string;
        effect: number;
        startZ: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    }>;
    futureResidencies?: Array<{
      __typename?: 'Residency';
      id: string;
      startZ: string;
      endZ?: string;
      renewZ?: string;
      terms?: Array<string>;
      moveOutReason?: MoveOutReason;
      securityDeposit?: number;
      propertyId: string;
      unitId: string;
      rrule?: string;
      type?: LeaseType;
      gst?: number;
      occupancy?: string;
      utilities?: Array<string>;
      effects?: Array<{
        __typename?: 'ResidencyEffect';
        id: string;
        feeId: string;
        effect: number;
        startZ: string;
      }>;
      additionalProvisions?: {
        __typename?: 'AdditionalProvisions';
        pets?: number;
        parking?: boolean;
        storage?: boolean;
        smoking?: boolean;
      };
    }>;
    owner?: { __typename?: 'Owner'; id: string; name: string };
  }>;
};

export type GetOwnerQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOwnerQuery = {
  __typename?: 'Query';
  owner?: {
    __typename?: 'Owner';
    id: string;
    name: string;
    email?: string;
    yearEndMonth?: number;
    gstNumber?: string;
    gstFiling?: FilingFrequency;
    feeRate?: number;
    feeAmount?: number;
    flatFee?: number;
    correspondenceMethod?: CorrespondenceMethod;
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      lat?: number;
      lng?: number;
    };
    assetFees?: Array<{
      __typename?: 'AssetFee';
      assetId: string;
      type: AssetType;
      feeRate?: number;
      feeAmount?: number;
      flatFee?: number;
    }>;
  };
};

export type GetPropertyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'ManagerProperty';
    id: string;
    name: string;
    phone?: string;
    imageKey?: string;
    timezone?: string;
    totalUnits?: number;
    leasedUnits?: number;
    commercialLeasedUnits?: number;
    commonAreas?: number;
    ownerUnits?: number;
    condoUnits?: number;
    autopayCollectionsEnabled?: boolean;
    reserveAmount?: number;
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      lat?: number;
      lng?: number;
    };
    units?: Array<{
      __typename?: 'ManagerUnit';
      id: string;
      name: string;
      occupancy?: string;
      condoFee?: number;
      condoFeeGst?: number;
      condoUnitFees?: Array<{ __typename?: 'CondoUnitFee'; feeId: string; amount?: number }>;
      currentResidencies?: Array<{
        __typename?: 'Residency';
        id: string;
        startZ: string;
        endZ?: string;
        renewZ?: string;
        terms?: Array<string>;
        moveOutReason?: MoveOutReason;
        securityDeposit?: number;
        propertyId: string;
        unitId: string;
        rrule?: string;
        type?: LeaseType;
        gst?: number;
        occupancy?: string;
        utilities?: Array<string>;
        effects?: Array<{
          __typename?: 'ResidencyEffect';
          id: string;
          feeId: string;
          effect: number;
          startZ: string;
        }>;
        additionalProvisions?: {
          __typename?: 'AdditionalProvisions';
          pets?: number;
          parking?: boolean;
          storage?: boolean;
          smoking?: boolean;
        };
      }>;
      futureResidencies?: Array<{
        __typename?: 'Residency';
        id: string;
        startZ: string;
        endZ?: string;
        renewZ?: string;
        terms?: Array<string>;
        moveOutReason?: MoveOutReason;
        securityDeposit?: number;
        propertyId: string;
        unitId: string;
        rrule?: string;
        type?: LeaseType;
        gst?: number;
        occupancy?: string;
        utilities?: Array<string>;
        effects?: Array<{
          __typename?: 'ResidencyEffect';
          id: string;
          feeId: string;
          effect: number;
          startZ: string;
        }>;
        additionalProvisions?: {
          __typename?: 'AdditionalProvisions';
          pets?: number;
          parking?: boolean;
          storage?: boolean;
          smoking?: boolean;
        };
      }>;
      owner?: { __typename?: 'Owner'; id: string; name: string };
    }>;
  };
};

export type GetPropertyNameAndAddressQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyNameAndAddressQuery = {
  __typename?: 'Query';
  property?: {
    __typename?: 'ManagerProperty';
    name: string;
    ownerId?: string;
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  };
};

export type CancelPadMutationVariables = Exact<{
  enrollmentId: Scalars['ID']['input'];
}>;

export type CancelPadMutation = {
  __typename?: 'Mutation';
  cancelPad?: {
    __typename?: 'AutopayEnrollmentPayload';
    error?: string;
    success?: boolean;
    enrollment?: {
      __typename?: 'AutopayEnrollment';
      id: string;
      residencyId?: string;
      residentId?: string;
      propertyId: string;
      unitId: string;
      status: AutopayEnrollmentStatus;
      invitedZ?: string;
      activatedZ?: string;
      declinedZ?: string;
      cancelledZ?: string;
      posted?: string;
      statusHistory?: Array<{
        __typename?: 'AutopayEnrollmentStatusHistory';
        status: AutopayEnrollmentStatus;
        createdZ: string;
        description?: string;
      }>;
      bankAccount?: {
        __typename?: 'BankAccount';
        id: string;
        institutionName: string;
        accountDetails?: {
          __typename?: 'AccountDetail';
          accountNumber4Digit?: string;
          currency?: string;
          type?: string;
        };
      };
      clearables?: Array<{ __typename?: 'FixedEnrollmentClearable'; id: string; amount: number }>;
    };
  };
};

export type DeclinePadInviteMutationVariables = Exact<{
  enrollmentId: Scalars['ID']['input'];
}>;

export type DeclinePadInviteMutation = {
  __typename?: 'Mutation';
  declinePadInvite?: {
    __typename?: 'AutopayEnrollmentPayload';
    error?: string;
    success?: boolean;
    enrollment?: {
      __typename?: 'AutopayEnrollment';
      id: string;
      residencyId?: string;
      residentId?: string;
      propertyId: string;
      unitId: string;
      status: AutopayEnrollmentStatus;
      invitedZ?: string;
      activatedZ?: string;
      declinedZ?: string;
      cancelledZ?: string;
      posted?: string;
      statusHistory?: Array<{
        __typename?: 'AutopayEnrollmentStatusHistory';
        status: AutopayEnrollmentStatus;
        createdZ: string;
        description?: string;
      }>;
      bankAccount?: {
        __typename?: 'BankAccount';
        id: string;
        institutionName: string;
        accountDetails?: {
          __typename?: 'AccountDetail';
          accountNumber4Digit?: string;
          currency?: string;
          type?: string;
        };
      };
      clearables?: Array<{ __typename?: 'FixedEnrollmentClearable'; id: string; amount: number }>;
    };
  };
};

export type SavePadMutationVariables = Exact<{
  enrollmentId: Scalars['ID']['input'];
  input: SavePadInput;
}>;

export type SavePadMutation = {
  __typename?: 'Mutation';
  savePad?: {
    __typename?: 'AutopayEnrollmentPayload';
    error?: string;
    success?: boolean;
    enrollment?: {
      __typename?: 'AutopayEnrollment';
      id: string;
      residencyId?: string;
      residentId?: string;
      propertyId: string;
      unitId: string;
      status: AutopayEnrollmentStatus;
      invitedZ?: string;
      activatedZ?: string;
      declinedZ?: string;
      cancelledZ?: string;
      posted?: string;
      statusHistory?: Array<{
        __typename?: 'AutopayEnrollmentStatusHistory';
        status: AutopayEnrollmentStatus;
        createdZ: string;
        description?: string;
      }>;
      bankAccount?: {
        __typename?: 'BankAccount';
        id: string;
        institutionName: string;
        accountDetails?: {
          __typename?: 'AccountDetail';
          accountNumber4Digit?: string;
          currency?: string;
          type?: string;
        };
      };
      clearables?: Array<{ __typename?: 'FixedEnrollmentClearable'; id: string; amount: number }>;
    };
  };
};

export type UpdatePadMutationVariables = Exact<{
  enrollmentId: Scalars['ID']['input'];
  input: UpdatePadInput;
}>;

export type UpdatePadMutation = {
  __typename?: 'Mutation';
  updatePad?: {
    __typename?: 'AutopayEnrollmentPayload';
    error?: string;
    success?: boolean;
    enrollment?: {
      __typename?: 'AutopayEnrollment';
      id: string;
      residencyId?: string;
      residentId?: string;
      propertyId: string;
      unitId: string;
      status: AutopayEnrollmentStatus;
      invitedZ?: string;
      activatedZ?: string;
      declinedZ?: string;
      cancelledZ?: string;
      posted?: string;
      statusHistory?: Array<{
        __typename?: 'AutopayEnrollmentStatusHistory';
        status: AutopayEnrollmentStatus;
        createdZ: string;
        description?: string;
      }>;
      bankAccount?: {
        __typename?: 'BankAccount';
        id: string;
        institutionName: string;
        accountDetails?: {
          __typename?: 'AccountDetail';
          accountNumber4Digit?: string;
          currency?: string;
          type?: string;
        };
      };
      clearables?: Array<{ __typename?: 'FixedEnrollmentClearable'; id: string; amount: number }>;
    };
  };
};

export type GetClearablePaymentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  payeeAccountId: PayeeClearablesFilterItemInput;
}>;

export type GetClearablePaymentsQuery = {
  __typename?: 'Query';
  payeeClearable?: {
    __typename?: 'Clearable';
    id: string;
    due: string;
    posted: string;
    glId: string;
    ownerId: string;
    payeeId: string;
    payeeName?: string;
    unitId?: string;
    unitName?: string;
    propertyId: string;
    propertyName?: string;
    accountId: string;
    accountName?: string;
    payee: PayeeType;
    amount: number;
    outstanding: number;
    cleared?: string;
    ref?: string;
    balanceType: BalanceType;
    refund?: boolean;
    description?: string;
    enrollmentId?: string;
    fixedEnrollmentId?: string;
    payeeAutopayStatus?: string;
    autopaySinglePosted?: string;
    autopaySingleProfileId?: string;
    payeeMailingAddress?: {
      __typename?: 'Address';
      country?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      suite?: string;
      lat?: number;
      lng?: number;
    };
    propertyAddress?: {
      __typename?: 'Address';
      country?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      suite?: string;
      lat?: number;
      lng?: number;
    };
    accountAddress?: {
      __typename?: 'Address';
      country?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
      suite?: string;
      lat?: number;
      lng?: number;
    };
    payments?: Array<{
      __typename?: 'ClearablePaymentEffect';
      id: string;
      posted: string;
      effect: number;
      paymentMethod?: PaymentMethod;
    }>;
  };
};

export type ClearableListFieldsFragment = {
  __typename?: 'Clearable';
  id: string;
  due: string;
  posted: string;
  glId: string;
  ownerId: string;
  payeeId: string;
  payeeName?: string;
  unitId?: string;
  unitName?: string;
  propertyId: string;
  propertyName?: string;
  accountId: string;
  accountName?: string;
  payee: PayeeType;
  amount: number;
  outstanding: number;
  cleared?: string;
  ref?: string;
  balanceType: BalanceType;
  refund?: boolean;
  description?: string;
  enrollmentId?: string;
  fixedEnrollmentId?: string;
  payeeAutopayStatus?: string;
  autopaySinglePosted?: string;
  autopaySingleProfileId?: string;
};

export type ClearableDetailFieldsFragment = {
  __typename?: 'Clearable';
  id: string;
  due: string;
  posted: string;
  glId: string;
  ownerId: string;
  payeeId: string;
  payeeName?: string;
  unitId?: string;
  unitName?: string;
  propertyId: string;
  propertyName?: string;
  accountId: string;
  accountName?: string;
  payee: PayeeType;
  amount: number;
  outstanding: number;
  cleared?: string;
  ref?: string;
  balanceType: BalanceType;
  refund?: boolean;
  description?: string;
  enrollmentId?: string;
  fixedEnrollmentId?: string;
  payeeAutopayStatus?: string;
  autopaySinglePosted?: string;
  autopaySingleProfileId?: string;
  payeeMailingAddress?: {
    __typename?: 'Address';
    country?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    suite?: string;
    lat?: number;
    lng?: number;
  };
  propertyAddress?: {
    __typename?: 'Address';
    country?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    suite?: string;
    lat?: number;
    lng?: number;
  };
  accountAddress?: {
    __typename?: 'Address';
    country?: string;
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
    suite?: string;
    lat?: number;
    lng?: number;
  };
  payments?: Array<{
    __typename?: 'ClearablePaymentEffect';
    id: string;
    posted: string;
    effect: number;
    paymentMethod?: PaymentMethod;
  }>;
};

export type CreateAutopaySingleCollectionMutationVariables = Exact<{
  input: CreateAutopaySingleInput;
}>;

export type CreateAutopaySingleCollectionMutation = {
  __typename?: 'Mutation';
  createAutopaySingle?: {
    __typename?: 'AutopaySinglePayload';
    success?: boolean;
    error?: string;
    autopaySingle?: {
      __typename?: 'AutopaySingle';
      id: string;
      amount: number;
      clearableIds?: Array<string>;
      singlePosted: string;
    };
  };
};

export type GetPayeeClearablesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  payeeAccountIds: Array<PayeeClearablesFilterItemInput> | PayeeClearablesFilterItemInput;
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  showPaid: Scalars['Boolean']['input'];
}>;

export type GetPayeeClearablesQuery = {
  __typename?: 'Query';
  payeeClearables?: {
    __typename?: 'ClearablesPage';
    totalCount: number;
    totalByUnit?: Array<{ __typename?: 'TotalByUnit'; unitId: string; totalOutstanding?: number }>;
    totalByProperty?: Array<{
      __typename?: 'TotalByProperty';
      propertyId: string;
      totalOutstanding?: number;
    }>;
    totalByAccount?: Array<{
      __typename?: 'TotalByAccount';
      accountId: string;
      totalOutstanding?: number;
    }>;
    pageInfo: { __typename?: 'RelayPageInfo'; hasNextPage: boolean; endCursor?: string };
    edges: Array<{
      __typename?: 'ClearableEdge';
      node: {
        __typename?: 'Clearable';
        id: string;
        due: string;
        posted: string;
        glId: string;
        ownerId: string;
        payeeId: string;
        payeeName?: string;
        unitId?: string;
        unitName?: string;
        propertyId: string;
        propertyName?: string;
        accountId: string;
        accountName?: string;
        payee: PayeeType;
        amount: number;
        outstanding: number;
        cleared?: string;
        ref?: string;
        balanceType: BalanceType;
        refund?: boolean;
        description?: string;
        enrollmentId?: string;
        fixedEnrollmentId?: string;
        payeeAutopayStatus?: string;
        autopaySinglePosted?: string;
        autopaySingleProfileId?: string;
      };
    }>;
  };
};

export type GetManagerAccountAutopayQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetManagerAccountAutopayQuery = {
  __typename?: 'Query';
  accountAutopay?: {
    __typename?: 'Account';
    id?: string;
    books?: {
      __typename?: 'AccountBooks';
      autopay?: {
        __typename?: 'AccountAutopay';
        activationStatus?: string;
        activated?: boolean;
        version?: string;
        enableCollection?: boolean;
      };
    };
  };
};

export type SuspendAutopayMutationVariables = Exact<{
  payeeId: Scalars['ID']['input'];
}>;

export type SuspendAutopayMutation = {
  __typename?: 'Mutation';
  suspendAutopay?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type ReactivateAutopayMutationVariables = Exact<{
  payeeId: Scalars['ID']['input'];
}>;

export type ReactivateAutopayMutation = {
  __typename?: 'Mutation';
  reactivateAutopay?: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type GetAutopayEmbedUrlMutationVariables = Exact<{
  payeeId: Scalars['ID']['input'];
}>;

export type GetAutopayEmbedUrlMutation = {
  __typename?: 'Mutation';
  createAutopayEmbedUrl?: {
    __typename?: 'CreateAutopayEmbedUrlPayload';
    success?: boolean;
    error?: string;
    url?: string;
    iframeKey?: string;
  };
};

export type CampaignFieldsFragment = {
  __typename?: 'Campaign';
  id: string;
  profileId: string;
  accountId: string;
  application?: {
    __typename?: 'Application';
    id: string;
    applicationId?: string;
    campaignId: string;
    listedUnitId: string;
    submittedZ?: string;
    preSubmittedZ: string;
    name: string;
    emails?: Array<string>;
    phone?: string;
    dob?: string;
    status?: ApplicationStatus;
    moveInDate?: string;
    screeningAgreement?: boolean;
    additionalInformation?: string;
    approvedZ?: string;
    declinedZ?: string;
    workHistory?: Array<{
      __typename?: 'WorkHistory';
      id: string;
      name: string;
      location: string;
      phone?: string;
      startDate?: string;
      endDate?: string;
      amount?: number;
      frequency?: SalaryFrequency;
    }>;
    otherIncome?: Array<{
      __typename?: 'Income';
      id: string;
      source: IncomeSource;
      amount: number;
    }>;
    addressHistory?: Array<{
      __typename?: 'AddressHistory';
      id: string;
      name: string;
      phone?: string;
      email?: string;
      startDate?: string;
      endDate?: string;
      rentalType?: RentalType;
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    }>;
    household?: {
      __typename?: 'ApplicationHousehold';
      additionalTenants?: Array<{
        __typename?: 'ApplicationAdditionalTenant';
        id: string;
        name: string;
        email?: string;
        phone?: string;
        leaseholder?: boolean;
        sentZ?: string;
        payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
      }>;
      pets?: Array<{
        __typename?: 'Pet';
        type: string;
        count: number;
        details?: Array<{
          __typename?: 'PetDetails';
          type?: string;
          breed?: string;
          weight?: string;
          name?: string;
          color?: string;
          license?: string;
          isService?: boolean;
        }>;
      }>;
      vehicles?: Array<{
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        make: string;
        model: string;
        color?: string;
      }>;
    };
    leaseAgreement?: {
      __typename?: 'LeaseAgreement';
      id: string;
      offeredZ?: string;
      agreementFileKey?: string;
      acceptedZ?: string;
      signatures?: Array<{
        __typename?: 'Signature';
        id?: string;
        signature: string;
        signerName?: string;
        signerId?: string;
      }>;
    };
    documents?: Array<{
      __typename?: 'Document';
      key: string;
      name?: string;
      size?: number;
      createdZ?: string;
      typename?: string;
    }>;
  };
};

export type UnitFloorplanFieldsFragment = {
  __typename?: 'ManagerUnitFloorplan';
  id: string;
  name: string;
  floorplan?: {
    __typename?: 'Floorplan';
    id: string;
    name: string;
    links?: Array<string>;
    publicPhotos?: Array<{ __typename?: 'Image'; name?: string; key?: string }>;
    generatedSummary?: {
      __typename?: 'GeneratedFloorplanSummary';
      summary?: string;
      features?: Array<{
        __typename?: 'GeneratedFloorplanFeature';
        label: string;
        feature: string;
      }>;
    };
  };
};

export type GetApplicationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetApplicationsQuery = {
  __typename?: 'Query';
  applications?: Array<{
    __typename?: 'Application';
    id: string;
    applicationId?: string;
    campaignId: string;
    listedUnitId: string;
    submittedZ?: string;
    preSubmittedZ: string;
    name: string;
    emails?: Array<string>;
    phone?: string;
    dob?: string;
    status?: ApplicationStatus;
    moveInDate?: string;
    screeningAgreement?: boolean;
    additionalInformation?: string;
    approvedZ?: string;
    declinedZ?: string;
    workHistory?: Array<{
      __typename?: 'WorkHistory';
      id: string;
      name: string;
      location: string;
      phone?: string;
      startDate?: string;
      endDate?: string;
      amount?: number;
      frequency?: SalaryFrequency;
    }>;
    otherIncome?: Array<{
      __typename?: 'Income';
      id: string;
      source: IncomeSource;
      amount: number;
    }>;
    addressHistory?: Array<{
      __typename?: 'AddressHistory';
      id: string;
      name: string;
      phone?: string;
      email?: string;
      startDate?: string;
      endDate?: string;
      rentalType?: RentalType;
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    }>;
    household?: {
      __typename?: 'ApplicationHousehold';
      additionalTenants?: Array<{
        __typename?: 'ApplicationAdditionalTenant';
        id: string;
        name: string;
        email?: string;
        phone?: string;
        leaseholder?: boolean;
        sentZ?: string;
        payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
      }>;
      pets?: Array<{
        __typename?: 'Pet';
        type: string;
        count: number;
        details?: Array<{
          __typename?: 'PetDetails';
          type?: string;
          breed?: string;
          weight?: string;
          name?: string;
          color?: string;
          license?: string;
          isService?: boolean;
        }>;
      }>;
      vehicles?: Array<{
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        make: string;
        model: string;
        color?: string;
      }>;
    };
    leaseAgreement?: {
      __typename?: 'LeaseAgreement';
      id: string;
      offeredZ?: string;
      agreementFileKey?: string;
      acceptedZ?: string;
      signatures?: Array<{
        __typename?: 'Signature';
        id?: string;
        signature: string;
        signerName?: string;
        signerId?: string;
      }>;
    };
    documents?: Array<{
      __typename?: 'Document';
      key: string;
      name?: string;
      size?: number;
      createdZ?: string;
      typename?: string;
    }>;
  }>;
};

export type GetApplicationsByApplicationIdQueryVariables = Exact<{
  applicationId: Scalars['ID']['input'];
}>;

export type GetApplicationsByApplicationIdQuery = {
  __typename?: 'Query';
  applicationsByApplicationId?: Array<{
    __typename?: 'Application';
    id: string;
    applicationId?: string;
    campaignId: string;
    listedUnitId: string;
    submittedZ?: string;
    preSubmittedZ: string;
    name: string;
    emails?: Array<string>;
    phone?: string;
    dob?: string;
    status?: ApplicationStatus;
    moveInDate?: string;
    screeningAgreement?: boolean;
    additionalInformation?: string;
    approvedZ?: string;
    declinedZ?: string;
    workHistory?: Array<{
      __typename?: 'WorkHistory';
      id: string;
      name: string;
      location: string;
      phone?: string;
      startDate?: string;
      endDate?: string;
      amount?: number;
      frequency?: SalaryFrequency;
    }>;
    otherIncome?: Array<{
      __typename?: 'Income';
      id: string;
      source: IncomeSource;
      amount: number;
    }>;
    addressHistory?: Array<{
      __typename?: 'AddressHistory';
      id: string;
      name: string;
      phone?: string;
      email?: string;
      startDate?: string;
      endDate?: string;
      rentalType?: RentalType;
      address: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    }>;
    household?: {
      __typename?: 'ApplicationHousehold';
      additionalTenants?: Array<{
        __typename?: 'ApplicationAdditionalTenant';
        id: string;
        name: string;
        email?: string;
        phone?: string;
        leaseholder?: boolean;
        sentZ?: string;
        payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
      }>;
      pets?: Array<{
        __typename?: 'Pet';
        type: string;
        count: number;
        details?: Array<{
          __typename?: 'PetDetails';
          type?: string;
          breed?: string;
          weight?: string;
          name?: string;
          color?: string;
          license?: string;
          isService?: boolean;
        }>;
      }>;
      vehicles?: Array<{
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        make: string;
        model: string;
        color?: string;
      }>;
    };
    leaseAgreement?: {
      __typename?: 'LeaseAgreement';
      id: string;
      offeredZ?: string;
      agreementFileKey?: string;
      acceptedZ?: string;
      signatures?: Array<{
        __typename?: 'Signature';
        id?: string;
        signature: string;
        signerName?: string;
        signerId?: string;
      }>;
    };
    documents?: Array<{
      __typename?: 'Document';
      key: string;
      name?: string;
      size?: number;
      createdZ?: string;
      typename?: string;
    }>;
  }>;
};

export type GetCampaignQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCampaignQuery = {
  __typename?: 'Query';
  campaign?: {
    __typename?: 'Campaign';
    id: string;
    profileId: string;
    accountId: string;
    application?: {
      __typename?: 'Application';
      id: string;
      applicationId?: string;
      campaignId: string;
      listedUnitId: string;
      submittedZ?: string;
      preSubmittedZ: string;
      name: string;
      emails?: Array<string>;
      phone?: string;
      dob?: string;
      status?: ApplicationStatus;
      moveInDate?: string;
      screeningAgreement?: boolean;
      additionalInformation?: string;
      approvedZ?: string;
      declinedZ?: string;
      workHistory?: Array<{
        __typename?: 'WorkHistory';
        id: string;
        name: string;
        location: string;
        phone?: string;
        startDate?: string;
        endDate?: string;
        amount?: number;
        frequency?: SalaryFrequency;
      }>;
      otherIncome?: Array<{
        __typename?: 'Income';
        id: string;
        source: IncomeSource;
        amount: number;
      }>;
      addressHistory?: Array<{
        __typename?: 'AddressHistory';
        id: string;
        name: string;
        phone?: string;
        email?: string;
        startDate?: string;
        endDate?: string;
        rentalType?: RentalType;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      household?: {
        __typename?: 'ApplicationHousehold';
        additionalTenants?: Array<{
          __typename?: 'ApplicationAdditionalTenant';
          id: string;
          name: string;
          email?: string;
          phone?: string;
          leaseholder?: boolean;
          sentZ?: string;
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
        }>;
        pets?: Array<{
          __typename?: 'Pet';
          type: string;
          count: number;
          details?: Array<{
            __typename?: 'PetDetails';
            type?: string;
            breed?: string;
            weight?: string;
            name?: string;
            color?: string;
            license?: string;
            isService?: boolean;
          }>;
        }>;
        vehicles?: Array<{
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          make: string;
          model: string;
          color?: string;
        }>;
      };
      leaseAgreement?: {
        __typename?: 'LeaseAgreement';
        id: string;
        offeredZ?: string;
        agreementFileKey?: string;
        acceptedZ?: string;
        signatures?: Array<{
          __typename?: 'Signature';
          id?: string;
          signature: string;
          signerName?: string;
          signerId?: string;
        }>;
      };
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ?: string;
        typename?: string;
      }>;
    };
  };
};

export type GetCampaignsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCampaignsQuery = {
  __typename?: 'Query';
  campaigns?: Array<{
    __typename?: 'Campaign';
    id: string;
    profileId: string;
    accountId: string;
    application?: {
      __typename?: 'Application';
      id: string;
      applicationId?: string;
      campaignId: string;
      listedUnitId: string;
      submittedZ?: string;
      preSubmittedZ: string;
      name: string;
      emails?: Array<string>;
      phone?: string;
      dob?: string;
      status?: ApplicationStatus;
      moveInDate?: string;
      screeningAgreement?: boolean;
      additionalInformation?: string;
      approvedZ?: string;
      declinedZ?: string;
      workHistory?: Array<{
        __typename?: 'WorkHistory';
        id: string;
        name: string;
        location: string;
        phone?: string;
        startDate?: string;
        endDate?: string;
        amount?: number;
        frequency?: SalaryFrequency;
      }>;
      otherIncome?: Array<{
        __typename?: 'Income';
        id: string;
        source: IncomeSource;
        amount: number;
      }>;
      addressHistory?: Array<{
        __typename?: 'AddressHistory';
        id: string;
        name: string;
        phone?: string;
        email?: string;
        startDate?: string;
        endDate?: string;
        rentalType?: RentalType;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      household?: {
        __typename?: 'ApplicationHousehold';
        additionalTenants?: Array<{
          __typename?: 'ApplicationAdditionalTenant';
          id: string;
          name: string;
          email?: string;
          phone?: string;
          leaseholder?: boolean;
          sentZ?: string;
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
        }>;
        pets?: Array<{
          __typename?: 'Pet';
          type: string;
          count: number;
          details?: Array<{
            __typename?: 'PetDetails';
            type?: string;
            breed?: string;
            weight?: string;
            name?: string;
            color?: string;
            license?: string;
            isService?: boolean;
          }>;
        }>;
        vehicles?: Array<{
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          make: string;
          model: string;
          color?: string;
        }>;
      };
      leaseAgreement?: {
        __typename?: 'LeaseAgreement';
        id: string;
        offeredZ?: string;
        agreementFileKey?: string;
        acceptedZ?: string;
        signatures?: Array<{
          __typename?: 'Signature';
          id?: string;
          signature: string;
          signerName?: string;
          signerId?: string;
        }>;
      };
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ?: string;
        typename?: string;
      }>;
    };
  }>;
};

export type CreateCampaignMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: CreateCampaignInput;
}>;

export type CreateCampaignMutation = {
  __typename?: 'Mutation';
  createCampaign?: {
    __typename?: 'CampaignPayload';
    success?: boolean;
    error?: string;
    campaign?: {
      __typename?: 'Campaign';
      id: string;
      profileId: string;
      accountId: string;
      application?: {
        __typename?: 'Application';
        id: string;
        applicationId?: string;
        campaignId: string;
        listedUnitId: string;
        submittedZ?: string;
        preSubmittedZ: string;
        name: string;
        emails?: Array<string>;
        phone?: string;
        dob?: string;
        status?: ApplicationStatus;
        moveInDate?: string;
        screeningAgreement?: boolean;
        additionalInformation?: string;
        approvedZ?: string;
        declinedZ?: string;
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate?: string;
          endDate?: string;
          amount?: number;
          frequency?: SalaryFrequency;
        }>;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate?: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
        household?: {
          __typename?: 'ApplicationHousehold';
          additionalTenants?: Array<{
            __typename?: 'ApplicationAdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            sentZ?: string;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
        leaseAgreement?: {
          __typename?: 'LeaseAgreement';
          id: string;
          offeredZ?: string;
          agreementFileKey?: string;
          acceptedZ?: string;
          signatures?: Array<{
            __typename?: 'Signature';
            id?: string;
            signature: string;
            signerName?: string;
            signerId?: string;
          }>;
        };
        documents?: Array<{
          __typename?: 'Document';
          key: string;
          name?: string;
          size?: number;
          createdZ?: string;
          typename?: string;
        }>;
      };
    };
  };
};

export type GetUnitFloorplanQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUnitFloorplanQuery = {
  __typename?: 'Query';
  unitFloorplan?: {
    __typename?: 'ManagerUnitFloorplan';
    id: string;
    name: string;
    floorplan?: {
      __typename?: 'Floorplan';
      id: string;
      name: string;
      links?: Array<string>;
      publicPhotos?: Array<{ __typename?: 'Image'; name?: string; key?: string }>;
      generatedSummary?: {
        __typename?: 'GeneratedFloorplanSummary';
        summary?: string;
        features?: Array<{
          __typename?: 'GeneratedFloorplanFeature';
          label: string;
          feature: string;
        }>;
      };
    };
  };
};

export type QueryResidentsQueryVariables = Exact<{ [key: string]: never }>;

export type QueryResidentsQuery = {
  __typename?: 'Query';
  residents?: Array<{
    __typename?: 'Resident';
    id: string;
    profileId: string;
    accountId: string;
    documents?: Array<{
      __typename?: 'Document';
      key: string;
      name?: string;
      size?: number;
      createdZ?: string;
    }>;
  }>;
};

export type GetDocumentLinkQueryVariables = Exact<{
  residentId: Scalars['ID']['input'];
  key: Scalars['String']['input'];
}>;

export type GetDocumentLinkQuery = {
  __typename?: 'Query';
  documentLink?: { __typename?: 'DocumentLinkPayload'; link?: string; error?: string };
};

export type LeaseAgreementFieldsFragment = {
  __typename?: 'LeaseAgreement';
  id: string;
  offeredZ?: string;
  agreementFileKey?: string;
  acceptedZ?: string;
  signatures?: Array<{
    __typename?: 'Signature';
    id?: string;
    signature: string;
    signerName?: string;
    signerId?: string;
  }>;
};

export type SignatureFieldsFragment = {
  __typename?: 'Signature';
  id?: string;
  signature: string;
  signerName?: string;
  signerId?: string;
};

export type SignLeaseOfferMutationVariables = Exact<{
  input: SignLeaseOfferInput;
}>;

export type SignLeaseOfferMutation = {
  __typename?: 'Mutation';
  signLeaseOffer?: {
    __typename?: 'ApplicationPayload';
    success?: boolean;
    error?: string;
    application?: {
      __typename?: 'Application';
      id: string;
      leaseAgreement?: {
        __typename?: 'LeaseAgreement';
        id: string;
        offeredZ?: string;
        agreementFileKey?: string;
        acceptedZ?: string;
        signatures?: Array<{
          __typename?: 'Signature';
          id?: string;
          signature: string;
          signerName?: string;
          signerId?: string;
        }>;
      };
    };
  };
};

export type ProfileRequestFieldsFragment = {
  __typename?: 'ProfileRequest';
  id: string;
  accountId?: string;
  firstOccurred?: string;
  category?: string;
  name?: string;
  originalSubject?: string;
  originalRequest?: string;
  status?: string;
  ownerId?: string;
  details?: string;
  availabilityWindows?: Array<string>;
  pendingNotifications?: Array<string>;
  consents?: Array<string>;
  entryConsentDetails?: string;
  minutes?: number;
  appointmentZ?: string;
  approval?: { __typename?: 'MaintenanceApproval'; id: string; amount: number };
  fields?: Array<{
    __typename?: 'Field';
    label?: string;
    type?: string;
    value?: string;
    info?: string;
    imageKey?: string;
  }>;
  unit?: {
    __typename?: 'RequestUnit';
    name?: string;
    property?: {
      __typename?: 'RequestProperty';
      name?: string;
      phone?: string;
      key?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
      account?: { __typename?: 'RequestAccount'; name?: string; phone?: string };
    };
  };
};

export type CreateRequestMutationVariables = Exact<{
  input: CreateRequestInput;
}>;

export type CreateRequestMutation = {
  __typename?: 'Mutation';
  createRequest?: {
    __typename?: 'CreateRequestPayload';
    success?: boolean;
    error?: string;
    request?: {
      __typename?: 'ProfileRequest';
      id: string;
      accountId?: string;
      firstOccurred?: string;
      category?: string;
      name?: string;
      originalSubject?: string;
      originalRequest?: string;
      status?: string;
      ownerId?: string;
      details?: string;
      availabilityWindows?: Array<string>;
      pendingNotifications?: Array<string>;
      consents?: Array<string>;
      entryConsentDetails?: string;
      minutes?: number;
      appointmentZ?: string;
      approval?: { __typename?: 'MaintenanceApproval'; id: string; amount: number };
      fields?: Array<{
        __typename?: 'Field';
        label?: string;
        type?: string;
        value?: string;
        info?: string;
        imageKey?: string;
      }>;
      unit?: {
        __typename?: 'RequestUnit';
        name?: string;
        property?: {
          __typename?: 'RequestProperty';
          name?: string;
          phone?: string;
          key?: string;
          address?: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          account?: { __typename?: 'RequestAccount'; name?: string; phone?: string };
        };
      };
    };
  };
};

export type FinalizeRequestMutationVariables = Exact<{
  input: FinalizeRequestInput;
}>;

export type FinalizeRequestMutation = {
  __typename?: 'Mutation';
  finalizeRequest?: {
    __typename?: 'CreateRequestPayload';
    success?: boolean;
    error?: string;
    request?: {
      __typename?: 'ProfileRequest';
      id: string;
      accountId?: string;
      firstOccurred?: string;
      category?: string;
      name?: string;
      originalSubject?: string;
      originalRequest?: string;
      status?: string;
      ownerId?: string;
      details?: string;
      availabilityWindows?: Array<string>;
      pendingNotifications?: Array<string>;
      consents?: Array<string>;
      entryConsentDetails?: string;
      minutes?: number;
      appointmentZ?: string;
      approval?: { __typename?: 'MaintenanceApproval'; id: string; amount: number };
      fields?: Array<{
        __typename?: 'Field';
        label?: string;
        type?: string;
        value?: string;
        info?: string;
        imageKey?: string;
      }>;
      unit?: {
        __typename?: 'RequestUnit';
        name?: string;
        property?: {
          __typename?: 'RequestProperty';
          name?: string;
          phone?: string;
          key?: string;
          address?: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          account?: { __typename?: 'RequestAccount'; name?: string; phone?: string };
        };
      };
    };
  };
};

export type RequestDetailsFieldsFragment = {
  __typename?: 'Request';
  id?: string;
  subject?: string;
  accountId?: string;
  firstOccurred?: string;
  category?: string;
  name?: string;
  originalSubject?: string;
  originalRequest?: string;
  status?: string;
  details?: string;
  resolution?: string;
  ownerId?: string;
  taxExempted?: boolean;
  approval?: {
    __typename?: 'MaintenanceApproval';
    id: string;
    amount: number;
    status: string;
    approvedZ?: string;
    approvedComment?: string;
    declinedZ?: string;
    declinedComment?: string;
    description?: string;
  };
  tenant?: { __typename?: 'Tenant'; name: string };
  unit?: {
    __typename?: 'Unit';
    id?: string;
    name?: string;
    ownerId?: string;
    property?: {
      __typename?: 'Property';
      name?: string;
      phone?: string;
      key?: string;
      ownerId?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
      account?: {
        __typename?: 'Account';
        settings?: {
          __typename?: 'AccountSettings';
          resident_request_scheduling?: ResidentRequestScheduling;
        };
      };
    };
  };
  visits?: Array<{
    __typename?: 'Visit';
    id?: string;
    appointmentZ?: string;
    minutes?: number;
    timezone?: string;
    status?: string;
    dueZ?: string;
    delay?: string;
  }>;
  costLines?: Array<{
    __typename?: 'CostLine';
    id: string;
    amount: number;
    markup?: number;
    description?: string;
    total: number;
  }>;
  calculatedTaxes?: Array<{ __typename?: 'CalculatedTax'; label: string; amount?: number }>;
};

export type RequestVisitFieldsFragment = {
  __typename?: 'Visit';
  id?: string;
  appointmentZ?: string;
  minutes?: number;
  timezone?: string;
  status?: string;
  dueZ?: string;
  delay?: string;
};

export type ProfileRequestVisitFieldsFragment = {
  __typename?: 'Visit';
  id?: string;
  appointmentZ?: string;
  minutes?: number;
  timezone?: string;
  status?: string;
  dueZ?: string;
  operatorName?: string;
  delay?: string;
};

export type GetRequestByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  tenantId: Scalars['ID']['input'];
}>;

export type GetRequestByIdQuery = {
  __typename?: 'Query';
  requestById?: {
    __typename?: 'Request';
    id?: string;
    subject?: string;
    accountId?: string;
    firstOccurred?: string;
    category?: string;
    name?: string;
    originalSubject?: string;
    originalRequest?: string;
    status?: string;
    details?: string;
    resolution?: string;
    ownerId?: string;
    taxExempted?: boolean;
    approval?: {
      __typename?: 'MaintenanceApproval';
      id: string;
      amount: number;
      status: string;
      approvedZ?: string;
      approvedComment?: string;
      declinedZ?: string;
      declinedComment?: string;
      description?: string;
    };
    tenant?: { __typename?: 'Tenant'; name: string };
    unit?: {
      __typename?: 'Unit';
      id?: string;
      name?: string;
      ownerId?: string;
      property?: {
        __typename?: 'Property';
        name?: string;
        phone?: string;
        key?: string;
        ownerId?: string;
        address?: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
        account?: {
          __typename?: 'Account';
          settings?: {
            __typename?: 'AccountSettings';
            resident_request_scheduling?: ResidentRequestScheduling;
          };
        };
      };
    };
    visits?: Array<{
      __typename?: 'Visit';
      id?: string;
      appointmentZ?: string;
      minutes?: number;
      timezone?: string;
      status?: string;
      dueZ?: string;
      delay?: string;
    }>;
    costLines?: Array<{
      __typename?: 'CostLine';
      id: string;
      amount: number;
      markup?: number;
      description?: string;
      total: number;
    }>;
    calculatedTaxes?: Array<{ __typename?: 'CalculatedTax'; label: string; amount?: number }>;
  };
};

export type ApproveRequestMutationVariables = Exact<{
  input: ApproveRequestInput;
}>;

export type ApproveRequestMutation = {
  __typename?: 'Mutation';
  approveMaintenanceRequest?: {
    __typename?: 'RequestPayload';
    success?: boolean;
    error?: string;
    request?: {
      __typename?: 'Request';
      id?: string;
      subject?: string;
      accountId?: string;
      firstOccurred?: string;
      category?: string;
      name?: string;
      originalSubject?: string;
      originalRequest?: string;
      status?: string;
      details?: string;
      resolution?: string;
      ownerId?: string;
      taxExempted?: boolean;
      approval?: {
        __typename?: 'MaintenanceApproval';
        id: string;
        amount: number;
        status: string;
        approvedZ?: string;
        approvedComment?: string;
        declinedZ?: string;
        declinedComment?: string;
        description?: string;
      };
      tenant?: { __typename?: 'Tenant'; name: string };
      unit?: {
        __typename?: 'Unit';
        id?: string;
        name?: string;
        ownerId?: string;
        property?: {
          __typename?: 'Property';
          name?: string;
          phone?: string;
          key?: string;
          ownerId?: string;
          address?: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          account?: {
            __typename?: 'Account';
            settings?: {
              __typename?: 'AccountSettings';
              resident_request_scheduling?: ResidentRequestScheduling;
            };
          };
        };
      };
      visits?: Array<{
        __typename?: 'Visit';
        id?: string;
        appointmentZ?: string;
        minutes?: number;
        timezone?: string;
        status?: string;
        dueZ?: string;
        delay?: string;
      }>;
      costLines?: Array<{
        __typename?: 'CostLine';
        id: string;
        amount: number;
        markup?: number;
        description?: string;
        total: number;
      }>;
      calculatedTaxes?: Array<{ __typename?: 'CalculatedTax'; label: string; amount?: number }>;
    };
  };
};

export type DeclineRequestMutationVariables = Exact<{
  input: DeclineRequestInput;
}>;

export type DeclineRequestMutation = {
  __typename?: 'Mutation';
  declineMaintenanceRequest?: {
    __typename?: 'RequestPayload';
    success?: boolean;
    error?: string;
    request?: {
      __typename?: 'Request';
      id?: string;
      subject?: string;
      accountId?: string;
      firstOccurred?: string;
      category?: string;
      name?: string;
      originalSubject?: string;
      originalRequest?: string;
      status?: string;
      details?: string;
      resolution?: string;
      ownerId?: string;
      taxExempted?: boolean;
      approval?: {
        __typename?: 'MaintenanceApproval';
        id: string;
        amount: number;
        status: string;
        approvedZ?: string;
        approvedComment?: string;
        declinedZ?: string;
        declinedComment?: string;
        description?: string;
      };
      tenant?: { __typename?: 'Tenant'; name: string };
      unit?: {
        __typename?: 'Unit';
        id?: string;
        name?: string;
        ownerId?: string;
        property?: {
          __typename?: 'Property';
          name?: string;
          phone?: string;
          key?: string;
          ownerId?: string;
          address?: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
          account?: {
            __typename?: 'Account';
            settings?: {
              __typename?: 'AccountSettings';
              resident_request_scheduling?: ResidentRequestScheduling;
            };
          };
        };
      };
      visits?: Array<{
        __typename?: 'Visit';
        id?: string;
        appointmentZ?: string;
        minutes?: number;
        timezone?: string;
        status?: string;
        dueZ?: string;
        delay?: string;
      }>;
      costLines?: Array<{
        __typename?: 'CostLine';
        id: string;
        amount: number;
        markup?: number;
        description?: string;
        total: number;
      }>;
      calculatedTaxes?: Array<{ __typename?: 'CalculatedTax'; label: string; amount?: number }>;
    };
  };
};

export type RequestListFieldsFragment = {
  __typename?: 'Request';
  id?: string;
  subject?: string;
  accountId?: string;
  firstOccurred?: string;
  category?: string;
  name?: string;
  originalSubject?: string;
  originalRequest?: string;
  status?: string;
  approval?: { __typename?: 'MaintenanceApproval'; id: string; amount: number; status: string };
  tenant?: { __typename?: 'Tenant'; id: string; name: string };
  unit?: {
    __typename?: 'Unit';
    id?: string;
    name?: string;
    ownerId?: string;
    property?: {
      __typename?: 'Property';
      name?: string;
      phone?: string;
      key?: string;
      ownerId?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
      account?: {
        __typename?: 'Account';
        settings?: {
          __typename?: 'AccountSettings';
          resident_request_scheduling?: ResidentRequestScheduling;
        };
      };
    };
  };
  visits?: Array<{
    __typename?: 'Visit';
    id?: string;
    appointmentZ?: string;
    minutes?: number;
    timezone?: string;
    status?: string;
    dueZ?: string;
  }>;
};

export type RequestUnitFieldsFragment = {
  __typename?: 'Unit';
  id?: string;
  name?: string;
  ownerId?: string;
  property?: {
    __typename?: 'Property';
    name?: string;
    phone?: string;
    key?: string;
    ownerId?: string;
    address?: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
    account?: {
      __typename?: 'Account';
      settings?: {
        __typename?: 'AccountSettings';
        resident_request_scheduling?: ResidentRequestScheduling;
      };
    };
  };
};

export type GetCategoryTemplateQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  category: Scalars['String']['input'];
}>;

export type GetCategoryTemplateQuery = {
  __typename?: 'Query';
  categoryTemplate?: {
    __typename?: 'Template';
    id: string;
    category?: string;
    minutes?: number;
    response?: string;
    fields?: Array<{
      __typename?: 'TemplateField';
      label?: string;
      type?: string;
      info?: string;
      imageKey?: string;
    }>;
  };
};

export type GetRequestsQueryVariables = Exact<{
  tenantId: Scalars['ID']['input'];
}>;

export type GetRequestsQuery = {
  __typename?: 'Query';
  requests?: Array<{
    __typename?: 'Request';
    id?: string;
    subject?: string;
    accountId?: string;
    firstOccurred?: string;
    category?: string;
    name?: string;
    originalSubject?: string;
    originalRequest?: string;
    status?: string;
    approval?: { __typename?: 'MaintenanceApproval'; id: string; amount: number; status: string };
    tenant?: { __typename?: 'Tenant'; id: string; name: string };
    unit?: {
      __typename?: 'Unit';
      id?: string;
      name?: string;
      ownerId?: string;
      property?: {
        __typename?: 'Property';
        name?: string;
        phone?: string;
        key?: string;
        ownerId?: string;
        address?: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
        account?: {
          __typename?: 'Account';
          settings?: {
            __typename?: 'AccountSettings';
            resident_request_scheduling?: ResidentRequestScheduling;
          };
        };
      };
    };
    visits?: Array<{
      __typename?: 'Visit';
      id?: string;
      appointmentZ?: string;
      minutes?: number;
      timezone?: string;
      status?: string;
      dueZ?: string;
    }>;
  }>;
};

export type GetRequestsByPropertyQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type GetRequestsByPropertyQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserQuery';
    profile?: {
      __typename?: 'Profile';
      id: string;
      requestsByProperty: {
        __typename?: 'ProfileRequestConnection';
        items?: Array<{
          __typename?: 'ProfileRequest';
          id: string;
          firstOccurred?: string;
          category?: string;
          originalSubject?: string;
          createdZ?: string;
          originalRequest?: string;
          status?: string;
          name?: string;
          unit?: {
            __typename?: 'RequestUnit';
            name?: string;
            property?: {
              __typename?: 'RequestProperty';
              id?: string;
              name?: string;
              address?: {
                __typename?: 'Address';
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
              };
            };
          };
        }>;
      };
    };
  };
};

export type GetProfileRequestQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProfileRequestQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserQuery';
    profile?: {
      __typename?: 'Profile';
      id: string;
      request?: {
        __typename?: 'ProfileRequest';
        accountId?: string;
        id: string;
        firstOccurred?: string;
        category?: string;
        name?: string;
        originalSubject?: string;
        originalRequest?: string;
        status?: string;
        taxExempted?: boolean;
        resolution?: string;
        ownerId?: string;
        approval?: {
          __typename?: 'MaintenanceApproval';
          id: string;
          amount: number;
          status: string;
          description?: string;
          approvedComment?: string;
          approvedZ?: string;
          declinedComment?: string;
          declinedZ?: string;
        };
        costLines?: Array<{
          __typename?: 'CostLine';
          id: string;
          amount: number;
          visitNumber?: number;
          markup?: number;
          description?: string;
          total: number;
        }>;
        calculatedTaxes?: Array<{ __typename?: 'CalculatedTax'; label: string; amount?: number }>;
        visits?: Array<{
          __typename?: 'Visit';
          id?: string;
          appointmentZ?: string;
          minutes?: number;
          timezone?: string;
          status?: string;
          dueZ?: string;
          operatorName?: string;
          delay?: string;
        }>;
        unit?: {
          __typename?: 'RequestUnit';
          name?: string;
          ownerId?: string;
          property?: {
            __typename?: 'RequestProperty';
            ownerId?: string;
            name?: string;
            phone?: string;
            key?: string;
            address?: {
              __typename?: 'Address';
              street?: string;
              city?: string;
              province?: string;
              postal?: string;
            };
            account?: { __typename?: 'RequestAccount'; name?: string; phone?: string };
          };
        };
      };
    };
  };
};

export type ListProfileRequestsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListProfileRequestsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserQuery';
    profile?: {
      __typename?: 'Profile';
      id: string;
      listRequests: {
        __typename?: 'ProfileRequestConnection';
        nextToken?: string;
        items?: Array<{
          __typename?: 'ProfileRequest';
          id: string;
          firstOccurred?: string;
          category?: string;
          name?: string;
          originalSubject?: string;
          originalRequest?: string;
          status?: string;
          accountId?: string;
          createdZ?: string;
          unit?: {
            __typename?: 'RequestUnit';
            name?: string;
            property?: {
              __typename?: 'RequestProperty';
              id?: string;
              name?: string;
              phone?: string;
              key?: string;
              imageKey?: string;
              address?: {
                __typename?: 'Address';
                street?: string;
                city?: string;
                province?: string;
                postal?: string;
              };
            };
          };
          visits?: Array<{
            __typename?: 'Visit';
            id?: string;
            appointmentZ?: string;
            minutes?: number;
            timezone?: string;
            status?: string;
            dueZ?: string;
            operatorName?: string;
            delay?: string;
          }>;
        }>;
      };
    };
  };
};

export type AddressHistoryFieldsFragment = {
  __typename?: 'AddressHistory';
  id: string;
  name: string;
  phone?: string;
  email?: string;
  startDate?: string;
  endDate?: string;
  rentalType?: RentalType;
  address: {
    __typename?: 'Address';
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
  };
};

export type CampaignApplicationRulesFieldFragment = {
  __typename?: 'CampaignApplicationRule';
  requiredDocs?: Array<{
    __typename?: 'CampaignApplicationDocRule';
    quantity: number;
    docTypes: Array<string>;
  }>;
};

export type AccountSettingsFieldsFragment = {
  __typename?: 'Account';
  id?: string;
  settings?: {
    __typename?: 'AccountSettings';
    resident_request_scheduling?: ResidentRequestScheduling;
    campaignApplicationRules?: {
      __typename?: 'CampaignApplicationRule';
      requiredDocs?: Array<{
        __typename?: 'CampaignApplicationDocRule';
        quantity: number;
        docTypes: Array<string>;
      }>;
    };
  };
};

export type ApplicationFieldsFragment = {
  __typename?: 'Application';
  id: string;
  applicationId?: string;
  campaignId: string;
  listedUnitId: string;
  submittedZ?: string;
  preSubmittedZ: string;
  name: string;
  emails?: Array<string>;
  phone?: string;
  dob?: string;
  status?: ApplicationStatus;
  moveInDate?: string;
  screeningAgreement?: boolean;
  additionalInformation?: string;
  approvedZ?: string;
  declinedZ?: string;
  workHistory?: Array<{
    __typename?: 'WorkHistory';
    id: string;
    name: string;
    location: string;
    phone?: string;
    startDate?: string;
    endDate?: string;
    amount?: number;
    frequency?: SalaryFrequency;
  }>;
  otherIncome?: Array<{ __typename?: 'Income'; id: string; source: IncomeSource; amount: number }>;
  addressHistory?: Array<{
    __typename?: 'AddressHistory';
    id: string;
    name: string;
    phone?: string;
    email?: string;
    startDate?: string;
    endDate?: string;
    rentalType?: RentalType;
    address: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  }>;
  household?: {
    __typename?: 'ApplicationHousehold';
    additionalTenants?: Array<{
      __typename?: 'ApplicationAdditionalTenant';
      id: string;
      name: string;
      email?: string;
      phone?: string;
      leaseholder?: boolean;
      sentZ?: string;
      payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
    }>;
    pets?: Array<{
      __typename?: 'Pet';
      type: string;
      count: number;
      details?: Array<{
        __typename?: 'PetDetails';
        type?: string;
        breed?: string;
        weight?: string;
        name?: string;
        color?: string;
        license?: string;
        isService?: boolean;
      }>;
    }>;
    vehicles?: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      make: string;
      model: string;
      color?: string;
    }>;
  };
  leaseAgreement?: {
    __typename?: 'LeaseAgreement';
    id: string;
    offeredZ?: string;
    agreementFileKey?: string;
    acceptedZ?: string;
    signatures?: Array<{
      __typename?: 'Signature';
      id?: string;
      signature: string;
      signerName?: string;
      signerId?: string;
    }>;
  };
  documents?: Array<{
    __typename?: 'Document';
    key: string;
    name?: string;
    size?: number;
    createdZ?: string;
    typename?: string;
  }>;
};

export type GetAccountSettingsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetAccountSettingsQuery = {
  __typename?: 'Query';
  accountSettings?: {
    __typename?: 'Account';
    id?: string;
    settings?: {
      __typename?: 'AccountSettings';
      resident_request_scheduling?: ResidentRequestScheduling;
      campaignApplicationRules?: {
        __typename?: 'CampaignApplicationRule';
        requiredDocs?: Array<{
          __typename?: 'CampaignApplicationDocRule';
          quantity: number;
          docTypes: Array<string>;
        }>;
      };
    };
  };
};

export type CreateApplicationMutationVariables = Exact<{
  input: CreateApplicationInput;
}>;

export type CreateApplicationMutation = {
  __typename?: 'Mutation';
  createApplication?: {
    __typename?: 'ApplicationPayload';
    success?: boolean;
    error?: string;
    application?: {
      __typename?: 'Application';
      id: string;
      applicationId?: string;
      campaignId: string;
      listedUnitId: string;
      submittedZ?: string;
      preSubmittedZ: string;
      name: string;
      emails?: Array<string>;
      phone?: string;
      dob?: string;
      status?: ApplicationStatus;
      moveInDate?: string;
      screeningAgreement?: boolean;
      additionalInformation?: string;
      approvedZ?: string;
      declinedZ?: string;
      workHistory?: Array<{
        __typename?: 'WorkHistory';
        id: string;
        name: string;
        location: string;
        phone?: string;
        startDate?: string;
        endDate?: string;
        amount?: number;
        frequency?: SalaryFrequency;
      }>;
      otherIncome?: Array<{
        __typename?: 'Income';
        id: string;
        source: IncomeSource;
        amount: number;
      }>;
      addressHistory?: Array<{
        __typename?: 'AddressHistory';
        id: string;
        name: string;
        phone?: string;
        email?: string;
        startDate?: string;
        endDate?: string;
        rentalType?: RentalType;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      household?: {
        __typename?: 'ApplicationHousehold';
        additionalTenants?: Array<{
          __typename?: 'ApplicationAdditionalTenant';
          id: string;
          name: string;
          email?: string;
          phone?: string;
          leaseholder?: boolean;
          sentZ?: string;
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
        }>;
        pets?: Array<{
          __typename?: 'Pet';
          type: string;
          count: number;
          details?: Array<{
            __typename?: 'PetDetails';
            type?: string;
            breed?: string;
            weight?: string;
            name?: string;
            color?: string;
            license?: string;
            isService?: boolean;
          }>;
        }>;
        vehicles?: Array<{
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          make: string;
          model: string;
          color?: string;
        }>;
      };
      leaseAgreement?: {
        __typename?: 'LeaseAgreement';
        id: string;
        offeredZ?: string;
        agreementFileKey?: string;
        acceptedZ?: string;
        signatures?: Array<{
          __typename?: 'Signature';
          id?: string;
          signature: string;
          signerName?: string;
          signerId?: string;
        }>;
      };
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ?: string;
        typename?: string;
      }>;
    };
  };
};

export type UpdateAdditionalTenantsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateAdditionalTenantsInput;
}>;

export type UpdateAdditionalTenantsMutation = {
  __typename?: 'Mutation';
  updateAdditionalTenants?: {
    __typename?: 'ApplicationPayload';
    success?: boolean;
    error?: string;
    application?: {
      __typename?: 'Application';
      id: string;
      applicationId?: string;
      campaignId: string;
      listedUnitId: string;
      submittedZ?: string;
      preSubmittedZ: string;
      name: string;
      emails?: Array<string>;
      phone?: string;
      dob?: string;
      status?: ApplicationStatus;
      moveInDate?: string;
      screeningAgreement?: boolean;
      additionalInformation?: string;
      approvedZ?: string;
      declinedZ?: string;
      workHistory?: Array<{
        __typename?: 'WorkHistory';
        id: string;
        name: string;
        location: string;
        phone?: string;
        startDate?: string;
        endDate?: string;
        amount?: number;
        frequency?: SalaryFrequency;
      }>;
      otherIncome?: Array<{
        __typename?: 'Income';
        id: string;
        source: IncomeSource;
        amount: number;
      }>;
      addressHistory?: Array<{
        __typename?: 'AddressHistory';
        id: string;
        name: string;
        phone?: string;
        email?: string;
        startDate?: string;
        endDate?: string;
        rentalType?: RentalType;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      household?: {
        __typename?: 'ApplicationHousehold';
        additionalTenants?: Array<{
          __typename?: 'ApplicationAdditionalTenant';
          id: string;
          name: string;
          email?: string;
          phone?: string;
          leaseholder?: boolean;
          sentZ?: string;
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
        }>;
        pets?: Array<{
          __typename?: 'Pet';
          type: string;
          count: number;
          details?: Array<{
            __typename?: 'PetDetails';
            type?: string;
            breed?: string;
            weight?: string;
            name?: string;
            color?: string;
            license?: string;
            isService?: boolean;
          }>;
        }>;
        vehicles?: Array<{
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          make: string;
          model: string;
          color?: string;
        }>;
      };
      leaseAgreement?: {
        __typename?: 'LeaseAgreement';
        id: string;
        offeredZ?: string;
        agreementFileKey?: string;
        acceptedZ?: string;
        signatures?: Array<{
          __typename?: 'Signature';
          id?: string;
          signature: string;
          signerName?: string;
          signerId?: string;
        }>;
      };
      documents?: Array<{
        __typename?: 'Document';
        key: string;
        name?: string;
        size?: number;
        createdZ?: string;
        typename?: string;
      }>;
    };
  };
};

export type BankAccountFieldsFragment = {
  __typename?: 'BankAccount';
  id: string;
  institutionName: string;
  accountDetails?: {
    __typename?: 'AccountDetail';
    accountNumber4Digit?: string;
    currency?: string;
    type?: string;
  };
};

export type AutopayFieldsFragment = {
  __typename?: 'Autopay';
  bankAccounts?: Array<{
    __typename?: 'BankAccount';
    id: string;
    institutionName: string;
    accountDetails?: {
      __typename?: 'AccountDetail';
      accountNumber4Digit?: string;
      currency?: string;
      type?: string;
    };
  }>;
};

export type DocumentFieldsFragment = {
  __typename?: 'Document';
  key: string;
  name?: string;
  size?: number;
  createdZ?: string;
  typename?: string;
};

export type TenantFieldsFragment = {
  __typename?: 'AdditionalTenant';
  id: string;
  name: string;
  email?: string;
  phone?: string;
  leaseholder?: boolean;
  payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
};

export type PetFieldsFragment = {
  __typename?: 'Pet';
  type: string;
  count: number;
  details?: Array<{
    __typename?: 'PetDetails';
    type?: string;
    breed?: string;
    weight?: string;
    name?: string;
    color?: string;
    license?: string;
    isService?: boolean;
  }>;
};

export type VehicleFieldsFragment = {
  __typename?: 'Vehicle';
  id: string;
  plate: string;
  make: string;
  model: string;
  color?: string;
};

export type HouseholdFieldsFragment = {
  __typename?: 'Household';
  payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
  additionalTenants?: Array<{
    __typename?: 'AdditionalTenant';
    id: string;
    name: string;
    email?: string;
    phone?: string;
    leaseholder?: boolean;
    payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
  }>;
  pets?: Array<{
    __typename?: 'Pet';
    type: string;
    count: number;
    details?: Array<{
      __typename?: 'PetDetails';
      type?: string;
      breed?: string;
      weight?: string;
      name?: string;
      color?: string;
      license?: string;
      isService?: boolean;
    }>;
  }>;
  vehicles?: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    make: string;
    model: string;
    color?: string;
  }>;
};

export type ApplicationTenantFieldsFragment = {
  __typename?: 'ApplicationAdditionalTenant';
  id: string;
  name: string;
  email?: string;
  phone?: string;
  leaseholder?: boolean;
  sentZ?: string;
  payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
};

export type ApplicationHouseholdFieldsFragment = {
  __typename?: 'ApplicationHousehold';
  additionalTenants?: Array<{
    __typename?: 'ApplicationAdditionalTenant';
    id: string;
    name: string;
    email?: string;
    phone?: string;
    leaseholder?: boolean;
    sentZ?: string;
    payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
  }>;
  pets?: Array<{
    __typename?: 'Pet';
    type: string;
    count: number;
    details?: Array<{
      __typename?: 'PetDetails';
      type?: string;
      breed?: string;
      weight?: string;
      name?: string;
      color?: string;
      license?: string;
      isService?: boolean;
    }>;
  }>;
  vehicles?: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    make: string;
    model: string;
    color?: string;
  }>;
};

export type OtherIncomeFieldsFragment = {
  __typename?: 'Income';
  id: string;
  source: IncomeSource;
  amount: number;
};

export type ProfileFieldsFragment = {
  __typename?: 'Profile';
  id: string;
  name: string;
  email: string;
  emails?: Array<string>;
  phone?: string;
  dob?: string;
  consents?: Array<string>;
  workHistory?: Array<{
    __typename?: 'WorkHistory';
    id: string;
    name: string;
    location: string;
    phone?: string;
    startDate?: string;
    endDate?: string;
    amount?: number;
    frequency?: SalaryFrequency;
  }>;
  otherIncome?: Array<{ __typename?: 'Income'; id: string; source: IncomeSource; amount: number }>;
  addressHistory?: Array<{
    __typename?: 'AddressHistory';
    id: string;
    name: string;
    phone?: string;
    email?: string;
    startDate?: string;
    endDate?: string;
    rentalType?: RentalType;
    address: {
      __typename?: 'Address';
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  }>;
  household?: {
    __typename?: 'Household';
    payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
    additionalTenants?: Array<{
      __typename?: 'AdditionalTenant';
      id: string;
      name: string;
      email?: string;
      phone?: string;
      leaseholder?: boolean;
      payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
    }>;
    pets?: Array<{
      __typename?: 'Pet';
      type: string;
      count: number;
      details?: Array<{
        __typename?: 'PetDetails';
        type?: string;
        breed?: string;
        weight?: string;
        name?: string;
        color?: string;
        license?: string;
        isService?: boolean;
      }>;
    }>;
    vehicles?: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      make: string;
      model: string;
      color?: string;
    }>;
  };
  tenants?: Array<{ __typename?: 'ProfileTenant'; id: string; accountId: string }>;
  owners?: Array<{ __typename?: 'ProfileOwner'; id: string; accountId: string }>;
  emergencyContacts?: Array<{
    __typename?: 'EmergencyContact';
    name: string;
    relationship?: ContactRelationship;
    phone: string;
    consentToShare: boolean;
  }>;
  autopay?: {
    __typename?: 'Autopay';
    bankAccounts?: Array<{
      __typename?: 'BankAccount';
      id: string;
      institutionName: string;
      accountDetails?: {
        __typename?: 'AccountDetail';
        accountNumber4Digit?: string;
        currency?: string;
        type?: string;
      };
    }>;
  };
};

export type WorkHistoryFieldsFragment = {
  __typename?: 'WorkHistory';
  id: string;
  name: string;
  location: string;
  phone?: string;
  startDate?: string;
  endDate?: string;
  amount?: number;
  frequency?: SalaryFrequency;
};

export type GetProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetProfileQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserQuery';
    profile?: {
      __typename?: 'Profile';
      id: string;
      name: string;
      email: string;
      emails?: Array<string>;
      phone?: string;
      dob?: string;
      consents?: Array<string>;
      workHistory?: Array<{
        __typename?: 'WorkHistory';
        id: string;
        name: string;
        location: string;
        phone?: string;
        startDate?: string;
        endDate?: string;
        amount?: number;
        frequency?: SalaryFrequency;
      }>;
      otherIncome?: Array<{
        __typename?: 'Income';
        id: string;
        source: IncomeSource;
        amount: number;
      }>;
      addressHistory?: Array<{
        __typename?: 'AddressHistory';
        id: string;
        name: string;
        phone?: string;
        email?: string;
        startDate?: string;
        endDate?: string;
        rentalType?: RentalType;
        address: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
      }>;
      household?: {
        __typename?: 'Household';
        payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
        additionalTenants?: Array<{
          __typename?: 'AdditionalTenant';
          id: string;
          name: string;
          email?: string;
          phone?: string;
          leaseholder?: boolean;
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
        }>;
        pets?: Array<{
          __typename?: 'Pet';
          type: string;
          count: number;
          details?: Array<{
            __typename?: 'PetDetails';
            type?: string;
            breed?: string;
            weight?: string;
            name?: string;
            color?: string;
            license?: string;
            isService?: boolean;
          }>;
        }>;
        vehicles?: Array<{
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          make: string;
          model: string;
          color?: string;
        }>;
      };
      tenants?: Array<{ __typename?: 'ProfileTenant'; id: string; accountId: string }>;
      owners?: Array<{ __typename?: 'ProfileOwner'; id: string; accountId: string }>;
      emergencyContacts?: Array<{
        __typename?: 'EmergencyContact';
        name: string;
        relationship?: ContactRelationship;
        phone: string;
        consentToShare: boolean;
      }>;
      autopay?: {
        __typename?: 'Autopay';
        bankAccounts?: Array<{
          __typename?: 'BankAccount';
          id: string;
          institutionName: string;
          accountDetails?: {
            __typename?: 'AccountDetail';
            accountNumber4Digit?: string;
            currency?: string;
            type?: string;
          };
        }>;
      };
    };
  };
};

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;

export type UpdateProfileMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    updateProfile: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        name: string;
        email: string;
        emails?: Array<string>;
        phone?: string;
        dob?: string;
        consents?: Array<string>;
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate?: string;
          endDate?: string;
          amount?: number;
          frequency?: SalaryFrequency;
        }>;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate?: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
        tenants?: Array<{ __typename?: 'ProfileTenant'; id: string; accountId: string }>;
        owners?: Array<{ __typename?: 'ProfileOwner'; id: string; accountId: string }>;
        emergencyContacts?: Array<{
          __typename?: 'EmergencyContact';
          name: string;
          relationship?: ContactRelationship;
          phone: string;
          consentToShare: boolean;
        }>;
        autopay?: {
          __typename?: 'Autopay';
          bankAccounts?: Array<{
            __typename?: 'BankAccount';
            id: string;
            institutionName: string;
            accountDetails?: {
              __typename?: 'AccountDetail';
              accountNumber4Digit?: string;
              currency?: string;
              type?: string;
            };
          }>;
        };
      };
    };
  };
};

export type AddProfileWorkHistoryMutationVariables = Exact<{
  input: AddWorkHistoryInput;
}>;

export type AddProfileWorkHistoryMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    addWorkHistory: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate?: string;
          endDate?: string;
          amount?: number;
          frequency?: SalaryFrequency;
        }>;
      };
    };
  };
};

export type EditProfileWorkHistoryMutationVariables = Exact<{
  input: UpdateWorkHistoryInput;
}>;

export type EditProfileWorkHistoryMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    editWorkHistory: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate?: string;
          endDate?: string;
          amount?: number;
          frequency?: SalaryFrequency;
        }>;
      };
    };
  };
};

export type DeleteProfileWorkHistoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteProfileWorkHistoryMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    deleteWorkHistory: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate?: string;
          endDate?: string;
          amount?: number;
          frequency?: SalaryFrequency;
        }>;
      };
    };
  };
};

export type SaveNoWorkHistoryMutationVariables = Exact<{ [key: string]: never }>;

export type SaveNoWorkHistoryMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    saveNoWorkHistory: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate?: string;
          endDate?: string;
          amount?: number;
          frequency?: SalaryFrequency;
        }>;
      };
    };
  };
};

export type AddProfileAddressHistoryMutationVariables = Exact<{
  input: AddAddressHistoryInput;
}>;

export type AddProfileAddressHistoryMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    addAddressHistory: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate?: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
      };
    };
  };
};

export type EditProfileAddressHistoryMutationVariables = Exact<{
  input: UpdateAddressHistoryInput;
}>;

export type EditProfileAddressHistoryMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    editAddressHistory: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate?: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
      };
    };
  };
};

export type DeleteProfileAddressHistoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteProfileAddressHistoryMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    deleteAddressHistory: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate?: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
      };
    };
  };
};

export type SaveNoAddressHistoryMutationVariables = Exact<{ [key: string]: never }>;

export type SaveNoAddressHistoryMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    saveNoAddressHistory: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate?: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
      };
    };
  };
};

export type SaveNoAdditionalTenantsMutationVariables = Exact<{ [key: string]: never }>;

export type SaveNoAdditionalTenantsMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    saveNoAdditionalTenants: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
      };
    };
  };
};

export type AddAdditionalTenantMutationVariables = Exact<{
  input: AddAdditionalTenantInput;
}>;

export type AddAdditionalTenantMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    addAdditionalTenant: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
      };
    };
  };
};

export type EditAdditionalTenantMutationVariables = Exact<{
  input: UpdateAdditionalTenantInput;
}>;

export type EditAdditionalTenantMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    editAdditionalTenant: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
      };
    };
  };
};

export type DeleteAdditionalTenantMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteAdditionalTenantMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    deleteAdditionalTenant: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
      };
    };
  };
};

export type SaveNoPetsMutationVariables = Exact<{ [key: string]: never }>;

export type SaveNoPetsMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    saveNoPets: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
      };
    };
  };
};

export type UpdatePetsMutationVariables = Exact<{
  input?: InputMaybe<Array<PetInput> | PetInput>;
}>;

export type UpdatePetsMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    updatePets: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
      };
    };
  };
};

export type SaveNoVehiclesMutationVariables = Exact<{ [key: string]: never }>;

export type SaveNoVehiclesMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    saveNoVehicles: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
      };
    };
  };
};

export type AddVehicleMutationVariables = Exact<{
  input: AddVehicleInput;
}>;

export type AddVehicleMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    addVehicle: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
      };
    };
  };
};

export type EditVehicleMutationVariables = Exact<{
  input: UpdateVehicleInput;
}>;

export type EditVehicleMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    editVehicle: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
      };
    };
  };
};

export type DeleteVehicleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteVehicleMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    deleteVehicle: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
      };
    };
  };
};

export type UpdateProfileConsentsMutationVariables = Exact<{
  input: UpdateProfileConsentsInput;
}>;

export type UpdateProfileConsentsMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    updateProfileConsents: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        name: string;
        email: string;
        emails?: Array<string>;
        phone?: string;
        dob?: string;
        consents?: Array<string>;
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate?: string;
          endDate?: string;
          amount?: number;
          frequency?: SalaryFrequency;
        }>;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate?: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
        tenants?: Array<{ __typename?: 'ProfileTenant'; id: string; accountId: string }>;
        owners?: Array<{ __typename?: 'ProfileOwner'; id: string; accountId: string }>;
        emergencyContacts?: Array<{
          __typename?: 'EmergencyContact';
          name: string;
          relationship?: ContactRelationship;
          phone: string;
          consentToShare: boolean;
        }>;
        autopay?: {
          __typename?: 'Autopay';
          bankAccounts?: Array<{
            __typename?: 'BankAccount';
            id: string;
            institutionName: string;
            accountDetails?: {
              __typename?: 'AccountDetail';
              accountNumber4Digit?: string;
              currency?: string;
              type?: string;
            };
          }>;
        };
      };
    };
  };
};

export type AddBankAccountMutationVariables = Exact<{
  input: AddBankAccountInput;
}>;

export type AddBankAccountMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    addBankAccount: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        name: string;
        email: string;
        emails?: Array<string>;
        phone?: string;
        dob?: string;
        consents?: Array<string>;
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate?: string;
          endDate?: string;
          amount?: number;
          frequency?: SalaryFrequency;
        }>;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate?: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
        tenants?: Array<{ __typename?: 'ProfileTenant'; id: string; accountId: string }>;
        owners?: Array<{ __typename?: 'ProfileOwner'; id: string; accountId: string }>;
        emergencyContacts?: Array<{
          __typename?: 'EmergencyContact';
          name: string;
          relationship?: ContactRelationship;
          phone: string;
          consentToShare: boolean;
        }>;
        autopay?: {
          __typename?: 'Autopay';
          bankAccounts?: Array<{
            __typename?: 'BankAccount';
            id: string;
            institutionName: string;
            accountDetails?: {
              __typename?: 'AccountDetail';
              accountNumber4Digit?: string;
              currency?: string;
              type?: string;
            };
          }>;
        };
      };
    };
  };
};

export type DeleteBankAccountMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteBankAccountMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    deleteBankAccount: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        name: string;
        email: string;
        emails?: Array<string>;
        phone?: string;
        dob?: string;
        consents?: Array<string>;
        workHistory?: Array<{
          __typename?: 'WorkHistory';
          id: string;
          name: string;
          location: string;
          phone?: string;
          startDate?: string;
          endDate?: string;
          amount?: number;
          frequency?: SalaryFrequency;
        }>;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
        addressHistory?: Array<{
          __typename?: 'AddressHistory';
          id: string;
          name: string;
          phone?: string;
          email?: string;
          startDate?: string;
          endDate?: string;
          rentalType?: RentalType;
          address: {
            __typename?: 'Address';
            street?: string;
            city?: string;
            province?: string;
            postal?: string;
          };
        }>;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
        tenants?: Array<{ __typename?: 'ProfileTenant'; id: string; accountId: string }>;
        owners?: Array<{ __typename?: 'ProfileOwner'; id: string; accountId: string }>;
        emergencyContacts?: Array<{
          __typename?: 'EmergencyContact';
          name: string;
          relationship?: ContactRelationship;
          phone: string;
          consentToShare: boolean;
        }>;
        autopay?: {
          __typename?: 'Autopay';
          bankAccounts?: Array<{
            __typename?: 'BankAccount';
            id: string;
            institutionName: string;
            accountDetails?: {
              __typename?: 'AccountDetail';
              accountNumber4Digit?: string;
              currency?: string;
              type?: string;
            };
          }>;
        };
      };
    };
  };
};

export type AddProfileOtherIncomeMutationVariables = Exact<{
  input: AddOtherIncomeInput;
}>;

export type AddProfileOtherIncomeMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    addOtherIncome: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
      };
    };
  };
};

export type EditProfileOtherIncomeMutationVariables = Exact<{
  input: UpdateOtherIncomeInput;
}>;

export type EditProfileOtherIncomeMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    editOtherIncome: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
      };
    };
  };
};

export type DeleteProfileOtherIncomeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteProfileOtherIncomeMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    deleteOtherIncome: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
      };
    };
  };
};

export type SaveNoOtherIncomeMutationVariables = Exact<{ [key: string]: never }>;

export type SaveNoOtherIncomeMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    saveNoOtherIncome: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        otherIncome?: Array<{
          __typename?: 'Income';
          id: string;
          source: IncomeSource;
          amount: number;
        }>;
      };
    };
  };
};

export type UpdateTenantsPayeeFracMutationVariables = Exact<{
  input: UpdateTenantsPayeeFracInput;
}>;

export type UpdateTenantsPayeeFracMutation = {
  __typename?: 'Mutation';
  user?: {
    __typename?: 'UserMutation';
    updateTenantsPayeeFrac: {
      __typename?: 'UpdateProfilePayload';
      success?: boolean;
      error?: string;
      profile?: {
        __typename?: 'Profile';
        id: string;
        household?: {
          __typename?: 'Household';
          payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          additionalTenants?: Array<{
            __typename?: 'AdditionalTenant';
            id: string;
            name: string;
            email?: string;
            phone?: string;
            leaseholder?: boolean;
            payeeFrac?: { __typename?: 'Fraction'; denominator: number; numerator: number };
          }>;
          pets?: Array<{
            __typename?: 'Pet';
            type: string;
            count: number;
            details?: Array<{
              __typename?: 'PetDetails';
              type?: string;
              breed?: string;
              weight?: string;
              name?: string;
              color?: string;
              license?: string;
              isService?: boolean;
            }>;
          }>;
          vehicles?: Array<{
            __typename?: 'Vehicle';
            id: string;
            plate: string;
            make: string;
            model: string;
            color?: string;
          }>;
        };
      };
    };
  };
};

export type InsurancePolicyFieldsFragment = {
  __typename?: 'InsurancePolicy';
  id: string;
  profileId: string;
  propertyId: string;
  unitId: string;
  residencyId: string;
  residentId: string;
  accountId: string;
  policyNumber: string;
  liability?: number;
  effective: string;
  expiry: string;
  providerName: string;
  policyDocumentKey?: string;
  sentZ?: string;
};

export type GetInspectionUrlQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  input: InspectionUrlInput;
}>;

export type GetInspectionUrlQuery = {
  __typename?: 'Query';
  inspectionUrl?: {
    __typename?: 'InspectionUrlPayload';
    success?: boolean;
    inspectionUrlObject?: {
      __typename?: 'InspectionUrlObject';
      fileUrl: string;
      filename: string;
      message?: string;
    };
  };
};

export type GetInsurancePolicesQueryVariables = Exact<{
  filter?: InputMaybe<InsurancePolicyFilterInput>;
}>;

export type GetInsurancePolicesQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserQuery';
    profile?: {
      __typename?: 'Profile';
      id: string;
      insurancePolicies?: Array<{
        __typename?: 'InsurancePolicy';
        id: string;
        profileId: string;
        propertyId: string;
        unitId: string;
        residencyId: string;
        residentId: string;
        accountId: string;
        policyNumber: string;
        liability?: number;
        effective: string;
        expiry: string;
        providerName: string;
        policyDocumentKey?: string;
        sentZ?: string;
      }>;
    };
  };
};

export type AddInsurancePolicyMutationVariables = Exact<{
  input: InsurancePolicyInput;
}>;

export type AddInsurancePolicyMutation = {
  __typename?: 'Mutation';
  addInsurancePolicy?: {
    __typename?: 'InsurancePolicyPayload';
    success?: boolean;
    error?: string;
    insurancePolicy?: {
      __typename?: 'InsurancePolicy';
      id: string;
      profileId: string;
      propertyId: string;
      unitId: string;
      residencyId: string;
      residentId: string;
      accountId: string;
      policyNumber: string;
      liability?: number;
      effective: string;
      expiry: string;
      providerName: string;
      policyDocumentKey?: string;
      sentZ?: string;
    };
  };
};

export type EditInsurancePolicyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: InsurancePolicyInput;
}>;

export type EditInsurancePolicyMutation = {
  __typename?: 'Mutation';
  editInsurancePolicy?: {
    __typename?: 'InsurancePolicyPayload';
    success?: boolean;
    error?: string;
    insurancePolicy?: {
      __typename?: 'InsurancePolicy';
      id: string;
      profileId: string;
      propertyId: string;
      unitId: string;
      residencyId: string;
      residentId: string;
      accountId: string;
      policyNumber: string;
      liability?: number;
      effective: string;
      expiry: string;
      providerName: string;
      policyDocumentKey?: string;
      sentZ?: string;
    };
  };
};

export type DeleteInsurancePolicyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteInsurancePolicyMutation = {
  __typename?: 'Mutation';
  deleteInsurancePolicy?: {
    __typename?: 'InsurancePolicyPayload';
    success?: boolean;
    error?: string;
  };
};

export type SendInsurancePolicyDocumentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SendInsurancePolicyDocumentMutation = {
  __typename?: 'Mutation';
  sendInsurancePolicyDocument?: {
    __typename?: 'InsurancePolicyPayload';
    success?: boolean;
    error?: string;
    insurancePolicy?: {
      __typename?: 'InsurancePolicy';
      id: string;
      profileId: string;
      propertyId: string;
      unitId: string;
      residencyId: string;
      residentId: string;
      accountId: string;
      policyNumber: string;
      liability?: number;
      effective: string;
      expiry: string;
      providerName: string;
      policyDocumentKey?: string;
      sentZ?: string;
    };
  };
};

export type EnableSharingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type EnableSharingMutation = {
  __typename?: 'Mutation';
  assetsShare?: {
    __typename?: 'AssetsSharePayload';
    success?: boolean;
    error?: string;
    assetsShare?: {
      __typename?: 'AssetsShare';
      id: string;
      profileId: string;
      shared?: boolean;
      expiryZ?: string;
      assets: Array<{
        __typename?: 'Asset';
        id: string;
        accountId: string;
        assetType: string;
        permissions?: Array<string>;
        shared: boolean;
        role?: string;
      }>;
    };
  };
};

export type DisableSharingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DisableSharingMutation = {
  __typename?: 'Mutation';
  assetsShareSoftDelete?: {
    __typename?: 'AssetsSharePayload';
    success?: boolean;
    error?: string;
    assetsShare?: {
      __typename?: 'AssetsShare';
      id: string;
      profileId: string;
      shared?: boolean;
      expiryZ?: string;
      assets: Array<{
        __typename?: 'Asset';
        id: string;
        accountId: string;
        assetType: string;
        permissions?: Array<string>;
        shared: boolean;
        role?: string;
      }>;
    };
  };
};

export type UpdateSharedAssetsMutationVariables = Exact<{
  input: UpdateSharedAssetsInput;
}>;

export type UpdateSharedAssetsMutation = {
  __typename?: 'Mutation';
  updateSharedAssets?: {
    __typename?: 'AssetsSharePayload';
    success?: boolean;
    error?: string;
    assetsShare?: {
      __typename?: 'AssetsShare';
      id: string;
      profileId: string;
      shared?: boolean;
      expiryZ?: string;
      assets: Array<{
        __typename?: 'Asset';
        id: string;
        accountId: string;
        assetType: string;
        permissions?: Array<string>;
        shared: boolean;
        role?: string;
      }>;
    };
  };
};

export type ShareesQueryVariables = Exact<{ [key: string]: never }>;

export type ShareesQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserQuery';
    profile?: {
      __typename?: 'Profile';
      id: string;
      assetsShares?: Array<{
        __typename?: 'AssetsShare';
        id: string;
        profileId: string;
        shared?: boolean;
        shareeName?: string;
        shareeEmail?: string;
        expiryZ?: string;
        assets: Array<{
          __typename?: 'Asset';
          id: string;
          accountId: string;
          shared: boolean;
          assetType: string;
          role?: string;
          permissions?: Array<string>;
        }>;
      }>;
    };
  };
};

export type SharingInvitationMutationVariables = Exact<{
  input: AssetsShareInput;
}>;

export type SharingInvitationMutation = {
  __typename?: 'Mutation';
  assetsShareInvitation?: {
    __typename?: 'AssetsSharePayload';
    success?: boolean;
    error?: string;
    assetsShare?: {
      __typename?: 'AssetsShare';
      id: string;
      profileId: string;
      shared?: boolean;
      shareeName?: string;
      shareeEmail?: string;
      expiryZ?: string;
      assets: Array<{
        __typename?: 'Asset';
        id: string;
        accountId: string;
        assetType: string;
        permissions?: Array<string>;
        shared: boolean;
        role?: string;
      }>;
    };
  };
};

export type NewAssetShareFragment = {
  __typename?: 'AssetsShare';
  id: string;
  shared?: boolean;
  shareeName?: string;
  shareeEmail?: string;
  assets: Array<{
    __typename?: 'Asset';
    id: string;
    shared: boolean;
    assetType: string;
    accountId: string;
    permissions?: Array<string>;
    role?: string;
  }>;
};

export type ProfileEventsSubscriptionVariables = Exact<{
  profileId: Scalars['ID']['input'];
}>;

export type ProfileEventsSubscription = {
  __typename?: 'Subscription';
  onProfileEvent?: {
    __typename?: 'ProfileEvent';
    profileId: string;
    source: EventSource;
    detail?: string;
    detailType: string;
    id: string;
    time: string;
  };
};

export type SubmitAppointmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: AppointmentChangeInput;
}>;

export type SubmitAppointmentMutation = {
  __typename?: 'Mutation';
  submitAppointment?: { __typename?: 'Request'; id?: string };
};

export type SendRequestLinkMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SendRequestLinkMutation = {
  __typename?: 'Mutation';
  sendRequestLink?: { __typename?: 'SendRequestLinkPayload'; success?: boolean };
};

export type CancelRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CancelRequestMutation = {
  __typename?: 'Mutation';
  cancelRequest?: { __typename?: 'Request'; id?: string; status?: string };
};

export type RecategorizeRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  newCategory?: InputMaybe<Scalars['String']['input']>;
}>;

export type RecategorizeRequestMutation = {
  __typename?: 'Mutation';
  recategorizeRequest?: {
    __typename?: 'Request';
    id?: string;
    category?: string;
    fields?: Array<{
      __typename?: 'Field';
      label?: string;
      type?: string;
      value?: string;
      info?: string;
    }>;
  };
};

export type GetRequestQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetRequestQuery = {
  __typename?: 'Query';
  request?: {
    __typename?: 'Request';
    id?: string;
    status?: string;
    originalSubject?: string;
    originalRequest?: string;
    category?: string;
    firstOccurred?: string;
    details?: string;
    availabilityWindows?: Array<string>;
    pendingNotifications?: Array<string>;
    consents?: Array<string>;
    entryConsentDetails?: string;
    minutes?: number;
    appointmentZ?: string;
    approval?: {
      __typename?: 'MaintenanceApproval';
      id: string;
      amount: number;
      approvedZ?: string;
      declinedZ?: string;
    };
    visits?: Array<{
      __typename?: 'Visit';
      id?: string;
      appointmentZ?: string;
      minutes?: number;
      timezone?: string;
    }>;
    appointmentPreferences?: Array<{ __typename?: 'Interval'; start: string; end: string }>;
    fields?: Array<{
      __typename?: 'Field';
      label?: string;
      type?: string;
      value?: string;
      info?: string;
      imageKey?: string;
    }>;
    tenant?: { __typename?: 'Tenant'; id: string; name: string };
    costLines?: Array<{
      __typename?: 'CostLine';
      id: string;
      date?: string;
      visitNumber?: number;
      description?: string;
      amount: number;
      markup?: number;
      total: number;
    }>;
    unit?: {
      __typename?: 'Unit';
      id?: string;
      name?: string;
      property?: {
        __typename?: 'Property';
        phone?: string;
        key?: string;
        timezone?: string;
        address?: {
          __typename?: 'Address';
          street?: string;
          city?: string;
          province?: string;
          postal?: string;
        };
        account?: {
          __typename?: 'Account';
          name?: string;
          phone?: string;
          settings?: {
            __typename?: 'AccountSettings';
            resident_request_scheduling?: ResidentRequestScheduling;
          };
        };
      };
    };
  };
};

export type UpdateDraftMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input?: InputMaybe<UpdateRequestInput>;
}>;

export type UpdateDraftMutation = {
  __typename?: 'Mutation';
  updateDraft?: {
    __typename?: 'Request';
    id?: string;
    details?: string;
    firstOccurred?: string;
    pendingNotifications?: Array<string>;
    appointmentPreferences?: Array<{ __typename?: 'Interval'; start: string; end: string }>;
    fields?: Array<{ __typename?: 'Field'; value?: string }>;
  };
};

export type SubmitRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SubmitRequestMutation = {
  __typename?: 'Mutation';
  submitRequest?: { __typename?: 'Request'; id?: string; status?: string };
};

export type RequestFieldsFragment = {
  __typename?: 'Request';
  id?: string;
  status?: string;
  originalSubject?: string;
  originalRequest?: string;
  category?: string;
  firstOccurred?: string;
  details?: string;
  availabilityWindows?: Array<string>;
  pendingNotifications?: Array<string>;
  consents?: Array<string>;
  entryConsentDetails?: string;
  minutes?: number;
  appointmentZ?: string;
  approval?: {
    __typename?: 'MaintenanceApproval';
    id: string;
    amount: number;
    approvedZ?: string;
    declinedZ?: string;
  };
  visits?: Array<{
    __typename?: 'Visit';
    id?: string;
    appointmentZ?: string;
    minutes?: number;
    timezone?: string;
  }>;
  appointmentPreferences?: Array<{ __typename?: 'Interval'; start: string; end: string }>;
  fields?: Array<{
    __typename?: 'Field';
    label?: string;
    type?: string;
    value?: string;
    info?: string;
    imageKey?: string;
  }>;
  tenant?: { __typename?: 'Tenant'; id: string; name: string };
  costLines?: Array<{
    __typename?: 'CostLine';
    id: string;
    date?: string;
    visitNumber?: number;
    description?: string;
    amount: number;
    markup?: number;
    total: number;
  }>;
  unit?: {
    __typename?: 'Unit';
    id?: string;
    name?: string;
    property?: {
      __typename?: 'Property';
      phone?: string;
      key?: string;
      timezone?: string;
      address?: {
        __typename?: 'Address';
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
      account?: {
        __typename?: 'Account';
        name?: string;
        phone?: string;
        settings?: {
          __typename?: 'AccountSettings';
          resident_request_scheduling?: ResidentRequestScheduling;
        };
      };
    };
  };
};

export type TemplateFieldsFragment = {
  __typename?: 'Template';
  id: string;
  category?: string;
  minutes?: number;
  response?: string;
  fields?: Array<{
    __typename?: 'TemplateField';
    label?: string;
    type?: string;
    info?: string;
    imageKey?: string;
  }>;
};

export const TenantLeaseFieldsFragmentDoc = gql`
  fragment TenantLeaseFields on TenantLease {
    leaseId
    accountId
    unitId
    propertyId
    status
    leaseHolder
  }
`;
export const LeaseFieldsFragmentDoc = gql`
  fragment LeaseFields on Lease {
    id
    start
    end
    rent
    securityDeposit
    status
    occupancy
    unitId
    originalLeaseId
    autoRenewSetup {
      enabled
      termMonths
    }
    additionalProvisions {
      pets
      parking
      storage
      smoking
    }
    property {
      id
      name
      accountId
      timezone
      address {
        street
        city
        province
        postal
      }
      imageKey
      account {
        name
        phone
      }
      window
    }
    unit {
      id
      name
      occupancy
    }
    leaseAgreement {
      agreementFileKey
    }
    gst
    rateSchedule {
      start
      rent
    }
    rentRollFees {
      feeId
      amount
    }
    incentives {
      id
      start
      end
      discountAmount
      type
      description
    }
  }
`;
export const ManagerFieldsFragmentDoc = gql`
  fragment ManagerFields on Manager {
    id
    accountId
    name
  }
`;
export const PropertyHeaderFieldsFragmentDoc = gql`
  fragment PropertyHeaderFields on PropertyHeader {
    id
    name
    key
    address {
      street
      city
      province
      postal
      lat
      lng
    }
    imageKey
    sharedMeta {
      shared
      role
      permissions
    }
  }
`;
export const UnitHeaderFieldsFragmentDoc = gql`
  fragment UnitHeaderFields on UnitHeader {
    id
    propertyId
    propertyName
    name
    occupancy
    address {
      street
      city
      province
      postal
      lat
      lng
    }
    propertyImageKey
    sharedMeta {
      shared
      role
      permissions
    }
  }
`;
export const AccountFieldsFragmentDoc = gql`
  fragment AccountFields on Account {
    id
    name
    address {
      street
      city
      province
      postal
      lat
      lng
    }
    settings {
      resident_request_scheduling
    }
  }
`;
export const BankAccountFieldsFragmentDoc = gql`
  fragment BankAccountFields on BankAccount {
    id
    institutionName
    accountDetails {
      accountNumber4Digit
      currency
      type
    }
  }
`;
export const AutopayEnrollmentFieldsFragmentDoc = gql`
  fragment AutopayEnrollmentFields on AutopayEnrollment {
    id
    residencyId
    residentId
    propertyId
    unitId
    status
    invitedZ
    statusHistory {
      status
      createdZ
      description
    }
    activatedZ
    declinedZ
    cancelledZ
    bankAccount {
      ...BankAccountFields
    }
    posted
    clearables {
      id
      amount
    }
  }
  ${BankAccountFieldsFragmentDoc}
`;
export const LeaseInspectionFieldsFragmentDoc = gql`
  fragment LeaseInspectionFields on LeaseInspection {
    id
    category
    status
    completedZ
  }
`;
export const ResidencyFieldsFragmentDoc = gql`
  fragment ResidencyFields on Residency {
    id
    startZ
    endZ
    effects {
      id
      feeId
      effect
      startZ
    }
    securityDeposit
    propertyId
    unitId
    rrule
    type
    occupancy
    gst
    unitOwnerId
    additionalProvisions {
      pets
      parking
      storage
      smoking
    }
    utilities
    unit {
      id
      name
      ownerId
      parking
      storage
    }
    property {
      id
      accountId
      name
      key
      autopayCollectionsEnabled
      address {
        street
        city
        province
        postal
      }
      imageKey
      account {
        name
        phone
      }
      timezone
      window
      ownerId
    }
    leaseAgreement {
      agreementFileKey
    }
    moveInInspection {
      ...LeaseInspectionFields
    }
    moveOutInspection {
      ...LeaseInspectionFields
    }
  }
  ${LeaseInspectionFieldsFragmentDoc}
`;
export const ResidentFieldsFragmentDoc = gql`
  fragment ResidentFields on Resident {
    id
    accountId
    propertyIds
    propertyHeaders {
      ...PropertyHeaderFields
    }
    unitIds
    unitHeaders {
      ...UnitHeaderFields
    }
    accountHeader {
      ...AccountFields
    }
    sharerHeader {
      id
      name
      email
    }
    documents {
      key
      name
      size
      createdZ
    }
    autopayEnrollments {
      ...AutopayEnrollmentFields
    }
    currentResidencies {
      ...ResidencyFields
    }
    futureResidencies {
      ...ResidencyFields
    }
    isOwner
    autopayDetail {
      id
      autopayStatus
      autopayCollectionsEnabled
      autopayInstitution
      autopayAccount
      autopayTransit
      autopayClientId
    }
  }
  ${PropertyHeaderFieldsFragmentDoc}
  ${UnitHeaderFieldsFragmentDoc}
  ${AccountFieldsFragmentDoc}
  ${AutopayEnrollmentFieldsFragmentDoc}
  ${ResidencyFieldsFragmentDoc}
`;
export const ResidentRecordFieldsFragmentDoc = gql`
  fragment ResidentRecordFields on ResidentRecord {
    id
    accountId
    name
    emails
    phones
  }
`;
export const UnitResidencyFieldsFragmentDoc = gql`
  fragment UnitResidencyFields on Residency {
    id
    startZ
    endZ
    effects {
      id
      feeId
      effect
      startZ
    }
    securityDeposit
    propertyId
    unitId
    rrule
    type
    occupancy
    gst
    additionalProvisions {
      pets
      parking
      storage
      smoking
    }
    utilities
    residents {
      id
      residentId
      resident {
        ...ResidentRecordFields
      }
      leaseHolder
      payeeFrac {
        numerator
        denominator
      }
    }
  }
  ${ResidentRecordFieldsFragmentDoc}
`;
export const UnitFieldsFragmentDoc = gql`
  fragment UnitFields on ManagerUnit {
    id
    name
    sqft
    bedrooms
    bathrooms
    additionalInformation
    occupancy
    unitFactor
    condoFee
    condoFeeGst
    parking
    storage
    condoUnitFees {
      feeId
      amount
    }
    propertyId
    reserveAmount
    ownerId
    property {
      id
      name
      phone
      imageKey
      timezone
      key
      accountId
      address {
        street
        city
        province
        postal
        lat
        lng
      }
      ownerId
      window
      account {
        id
        name
        settings {
          resident_request_scheduling
        }
      }
    }
    currentResidencies {
      ...UnitResidencyFields
    }
    futureResidencies {
      ...UnitResidencyFields
    }
  }
  ${UnitResidencyFieldsFragmentDoc}
`;
export const ProfileLoginFieldsFragmentDoc = gql`
  fragment ProfileLoginFields on ProfileLogin {
    id
    email
    status
    userAttributes {
      identities
    }
  }
`;
export const ExpenseEntryFieldsFragmentDoc = gql`
  fragment ExpenseEntryFields on ExpenseEntry {
    id
    glId
    ownerId
    propertyId
    unitId
    amount
    description
    ref
    posted
    glName
    glBalanceType
    glAccountType
    requestId
    gstAmount
    documents {
      name
      key
      size
      createdZ
      typename
    }
    clearableId
    paidDate
    outstanding
  }
`;
export const OwnerFieldsFragmentDoc = gql`
  fragment OwnerFields on Owner {
    id
    name
    email
    address {
      street
      city
      province
      postal
      lat
      lng
    }
    yearEndMonth
    gstNumber
    gstFiling
    feeRate
    feeAmount
    flatFee
    assetFees {
      assetId
      type
      feeRate
      feeAmount
      flatFee
    }
    correspondenceMethod
  }
`;
export const PropertyUnitResidencyFieldsFragmentDoc = gql`
  fragment PropertyUnitResidencyFields on Residency {
    id
    startZ
    endZ
    renewZ
    terms
    moveOutReason
    effects {
      id
      feeId
      effect
      startZ
    }
    securityDeposit
    propertyId
    unitId
    rrule
    type
    gst
    occupancy
    additionalProvisions {
      pets
      parking
      storage
      smoking
    }
    utilities
  }
`;
export const PropertyUnitFieldsFragmentDoc = gql`
  fragment PropertyUnitFields on ManagerUnit {
    id
    name
    occupancy
    condoFee
    condoFeeGst
    condoUnitFees {
      feeId
      amount
    }
    currentResidencies {
      ...PropertyUnitResidencyFields
    }
    futureResidencies {
      ...PropertyUnitResidencyFields
    }
    owner {
      id
      name
    }
  }
  ${PropertyUnitResidencyFieldsFragmentDoc}
`;
export const PropertyFieldsFragmentDoc = gql`
  fragment PropertyFields on ManagerProperty {
    id
    name
    phone
    imageKey
    timezone
    totalUnits
    leasedUnits
    commercialLeasedUnits
    commonAreas
    ownerUnits
    condoUnits
    autopayCollectionsEnabled
    address {
      street
      city
      province
      postal
      lat
      lng
    }
    reserveAmount
    units {
      ...PropertyUnitFields
    }
  }
  ${PropertyUnitFieldsFragmentDoc}
`;
export const ClearableListFieldsFragmentDoc = gql`
  fragment ClearableListFields on Clearable {
    id
    due
    posted
    glId
    ownerId
    payeeId
    payeeName
    unitId
    unitName
    propertyId
    propertyName
    accountId
    accountName
    payee
    amount
    outstanding
    cleared
    ref
    balanceType
    refund
    description
    enrollmentId
    fixedEnrollmentId
    payeeAutopayStatus
    autopaySinglePosted
    autopaySingleProfileId
  }
`;
export const ClearableDetailFieldsFragmentDoc = gql`
  fragment ClearableDetailFields on Clearable {
    ...ClearableListFields
    payeeMailingAddress {
      country
      street
      city
      province
      postal
      suite
      lat
      lng
    }
    propertyAddress {
      country
      street
      city
      province
      postal
      suite
      lat
      lng
    }
    accountAddress {
      country
      street
      city
      province
      postal
      suite
      lat
      lng
    }
    payments {
      id
      posted
      effect
      paymentMethod
    }
  }
  ${ClearableListFieldsFragmentDoc}
`;
export const WorkHistoryFieldsFragmentDoc = gql`
  fragment WorkHistoryFields on WorkHistory {
    id
    name
    location
    phone
    startDate
    endDate
    amount
    frequency
  }
`;
export const OtherIncomeFieldsFragmentDoc = gql`
  fragment OtherIncomeFields on Income {
    id
    source
    amount
  }
`;
export const AddressHistoryFieldsFragmentDoc = gql`
  fragment AddressHistoryFields on AddressHistory {
    id
    name
    address {
      street
      city
      province
      postal
    }
    phone
    email
    startDate
    endDate
    rentalType
  }
`;
export const ApplicationTenantFieldsFragmentDoc = gql`
  fragment ApplicationTenantFields on ApplicationAdditionalTenant {
    id
    name
    email
    phone
    leaseholder
    sentZ
    payeeFrac {
      denominator
      numerator
    }
  }
`;
export const PetFieldsFragmentDoc = gql`
  fragment PetFields on Pet {
    type
    count
    details {
      type
      breed
      weight
      name
      color
      license
      isService
    }
  }
`;
export const VehicleFieldsFragmentDoc = gql`
  fragment VehicleFields on Vehicle {
    id
    plate
    make
    model
    color
  }
`;
export const ApplicationHouseholdFieldsFragmentDoc = gql`
  fragment ApplicationHouseholdFields on ApplicationHousehold {
    additionalTenants {
      ...ApplicationTenantFields
    }
    pets {
      ...PetFields
    }
    vehicles {
      ...VehicleFields
    }
  }
  ${ApplicationTenantFieldsFragmentDoc}
  ${PetFieldsFragmentDoc}
  ${VehicleFieldsFragmentDoc}
`;
export const SignatureFieldsFragmentDoc = gql`
  fragment SignatureFields on Signature {
    id
    signature
    signerName
    signerId
  }
`;
export const LeaseAgreementFieldsFragmentDoc = gql`
  fragment LeaseAgreementFields on LeaseAgreement {
    id
    offeredZ
    agreementFileKey
    signatures {
      ...SignatureFields
    }
    acceptedZ
  }
  ${SignatureFieldsFragmentDoc}
`;
export const DocumentFieldsFragmentDoc = gql`
  fragment DocumentFields on Document {
    key
    name
    size
    createdZ
    typename
  }
`;
export const ApplicationFieldsFragmentDoc = gql`
  fragment ApplicationFields on Application {
    id
    applicationId
    campaignId
    listedUnitId
    submittedZ
    preSubmittedZ
    name
    emails
    phone
    dob
    status
    moveInDate
    screeningAgreement
    additionalInformation
    workHistory {
      ...WorkHistoryFields
    }
    otherIncome {
      ...OtherIncomeFields
    }
    addressHistory {
      ...AddressHistoryFields
    }
    household {
      ...ApplicationHouseholdFields
    }
    approvedZ
    declinedZ
    leaseAgreement {
      ...LeaseAgreementFields
    }
    documents {
      ...DocumentFields
    }
  }
  ${WorkHistoryFieldsFragmentDoc}
  ${OtherIncomeFieldsFragmentDoc}
  ${AddressHistoryFieldsFragmentDoc}
  ${ApplicationHouseholdFieldsFragmentDoc}
  ${LeaseAgreementFieldsFragmentDoc}
  ${DocumentFieldsFragmentDoc}
`;
export const CampaignFieldsFragmentDoc = gql`
  fragment CampaignFields on Campaign {
    id
    profileId
    accountId
    application {
      ...ApplicationFields
    }
  }
  ${ApplicationFieldsFragmentDoc}
`;
export const UnitFloorplanFieldsFragmentDoc = gql`
  fragment UnitFloorplanFields on ManagerUnitFloorplan {
    id
    name
    floorplan {
      id
      name
      links
      publicPhotos {
        name
        key
      }
      generatedSummary {
        summary
        features {
          label
          feature
        }
      }
    }
  }
`;
export const ProfileRequestFieldsFragmentDoc = gql`
  fragment ProfileRequestFields on ProfileRequest {
    id
    accountId
    approval {
      id
      amount
    }
    firstOccurred
    category
    name
    originalSubject
    originalRequest
    status
    ownerId
    details
    availabilityWindows
    pendingNotifications
    consents
    entryConsentDetails
    minutes
    appointmentZ
    fields {
      label
      type
      value
      info
      imageKey
    }
    unit {
      name
      property {
        name
        phone
        key
        address {
          street
          city
          province
          postal
        }
        account {
          name
          phone
        }
      }
    }
  }
`;
export const RequestUnitFieldsFragmentDoc = gql`
  fragment RequestUnitFields on Unit {
    id
    name
    ownerId
    property {
      name
      phone
      key
      ownerId
      address {
        street
        city
        province
        postal
      }
      account {
        settings {
          resident_request_scheduling
        }
      }
    }
  }
`;
export const RequestVisitFieldsFragmentDoc = gql`
  fragment RequestVisitFields on Visit {
    id
    appointmentZ
    minutes
    timezone
    status
    dueZ
    delay
  }
`;
export const RequestDetailsFieldsFragmentDoc = gql`
  fragment RequestDetailsFields on Request {
    id
    subject
    approval {
      id
      amount
      status
      approvedZ
      approvedComment
      declinedZ
      declinedComment
      description
    }
    accountId
    firstOccurred
    category
    name
    originalSubject
    originalRequest
    status
    details
    resolution
    tenant {
      name
    }
    unit {
      ...RequestUnitFields
    }
    visits {
      ...RequestVisitFields
    }
    ownerId
    taxExempted
    costLines {
      id
      amount
      markup
      description
      total
    }
    calculatedTaxes {
      label
      amount
    }
  }
  ${RequestUnitFieldsFragmentDoc}
  ${RequestVisitFieldsFragmentDoc}
`;
export const ProfileRequestVisitFieldsFragmentDoc = gql`
  fragment ProfileRequestVisitFields on Visit {
    id
    appointmentZ
    minutes
    timezone
    status
    dueZ
    operatorName
    delay
  }
`;
export const RequestListFieldsFragmentDoc = gql`
  fragment RequestListFields on Request {
    id
    subject
    approval {
      id
      amount
      status
    }
    accountId
    firstOccurred
    category
    name
    originalSubject
    originalRequest
    status
    tenant {
      id
      name
    }
    unit {
      ...RequestUnitFields
    }
    visits {
      id
      appointmentZ
      minutes
      timezone
      status
      dueZ
    }
  }
  ${RequestUnitFieldsFragmentDoc}
`;
export const CampaignApplicationRulesFieldFragmentDoc = gql`
  fragment CampaignApplicationRulesField on CampaignApplicationRule {
    requiredDocs {
      quantity
      docTypes
    }
  }
`;
export const AccountSettingsFieldsFragmentDoc = gql`
  fragment AccountSettingsFields on Account {
    id
    settings {
      resident_request_scheduling
      campaignApplicationRules {
        ...CampaignApplicationRulesField
      }
    }
  }
  ${CampaignApplicationRulesFieldFragmentDoc}
`;
export const TenantFieldsFragmentDoc = gql`
  fragment TenantFields on AdditionalTenant {
    id
    name
    email
    phone
    leaseholder
    payeeFrac {
      denominator
      numerator
    }
  }
`;
export const HouseholdFieldsFragmentDoc = gql`
  fragment HouseholdFields on Household {
    payeeFrac {
      denominator
      numerator
    }
    additionalTenants {
      ...TenantFields
    }
    pets {
      ...PetFields
    }
    vehicles {
      ...VehicleFields
    }
  }
  ${TenantFieldsFragmentDoc}
  ${PetFieldsFragmentDoc}
  ${VehicleFieldsFragmentDoc}
`;
export const AutopayFieldsFragmentDoc = gql`
  fragment AutopayFields on Autopay {
    bankAccounts {
      ...BankAccountFields
    }
  }
  ${BankAccountFieldsFragmentDoc}
`;
export const ProfileFieldsFragmentDoc = gql`
  fragment ProfileFields on Profile {
    id
    name
    email
    emails
    phone
    dob
    workHistory {
      ...WorkHistoryFields
    }
    otherIncome {
      ...OtherIncomeFields
    }
    addressHistory {
      ...AddressHistoryFields
    }
    household {
      ...HouseholdFields
    }
    tenants {
      id
      accountId
    }
    owners {
      id
      accountId
    }
    consents
    emergencyContacts {
      name
      relationship
      phone
      consentToShare
    }
    autopay {
      ...AutopayFields
    }
  }
  ${WorkHistoryFieldsFragmentDoc}
  ${OtherIncomeFieldsFragmentDoc}
  ${AddressHistoryFieldsFragmentDoc}
  ${HouseholdFieldsFragmentDoc}
  ${AutopayFieldsFragmentDoc}
`;
export const InsurancePolicyFieldsFragmentDoc = gql`
  fragment InsurancePolicyFields on InsurancePolicy {
    id
    profileId
    propertyId
    unitId
    residencyId
    residentId
    accountId
    policyNumber
    liability
    effective
    expiry
    providerName
    policyDocumentKey
    sentZ
  }
`;
export const NewAssetShareFragmentDoc = gql`
  fragment newAssetShare on AssetsShare {
    id
    shared
    shareeName
    shareeEmail
    assets {
      id
      shared
      assetType
      accountId
      permissions
      role
    }
  }
`;
export const RequestFieldsFragmentDoc = gql`
  fragment RequestFields on Request {
    id
    approval {
      id
      amount
      approvedZ
      declinedZ
    }
    status
    originalSubject
    originalRequest
    category
    firstOccurred
    details
    availabilityWindows
    pendingNotifications
    consents
    entryConsentDetails
    minutes
    appointmentZ
    visits {
      id
      appointmentZ
      minutes
      timezone
    }
    appointmentPreferences {
      start
      end
    }
    fields {
      label
      type
      value
      info
      imageKey
    }
    tenant {
      id
      name
    }
    costLines {
      id
      date
      visitNumber
      description
      amount
      markup
      total
    }
    unit {
      id
      name
      property {
        phone
        key
        timezone
        address {
          street
          city
          province
          postal
        }
        account {
          name
          phone
          settings {
            resident_request_scheduling
          }
        }
      }
    }
  }
`;
export const TemplateFieldsFragmentDoc = gql`
  fragment TemplateFields on Template {
    id
    fields {
      label
      type
      info
      imageKey
    }
    category
    minutes
    response
  }
`;
export const GetTenantDocument = gql`
  query GetTenant($id: ID!) {
    tenantById(id: $id) {
      id
      name
      email
      phone
      leaseId
      accountId
      propertyId
      unitId
    }
  }
`;

/**
 * __useGetTenantQuery__
 *
 * To run a query within a React component, call `useGetTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantQuery(
  baseOptions: Apollo.QueryHookOptions<GetTenantQuery, GetTenantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
}
export function useGetTenantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTenantQuery, GetTenantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
}
export type GetTenantQueryHookResult = ReturnType<typeof useGetTenantQuery>;
export type GetTenantLazyQueryHookResult = ReturnType<typeof useGetTenantLazyQuery>;
export type GetTenantQueryResult = Apollo.QueryResult<GetTenantQuery, GetTenantQueryVariables>;
export const ProfileWithResidentsDocument = gql`
  query profileWithResidents {
    user {
      profile {
        ...ProfileFields
        residents {
          id
          accountId
          isOwner
          autopayDetail {
            id
            autopayStatus
            autopayCollectionsEnabled
            autopayInstitution
            autopayAccount
            autopayTransit
            autopayClientId
          }
        }
      }
    }
  }
  ${ProfileFieldsFragmentDoc}
`;

/**
 * __useProfileWithResidentsQuery__
 *
 * To run a query within a React component, call `useProfileWithResidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileWithResidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileWithResidentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileWithResidentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfileWithResidentsQuery,
    ProfileWithResidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfileWithResidentsQuery, ProfileWithResidentsQueryVariables>(
    ProfileWithResidentsDocument,
    options
  );
}
export function useProfileWithResidentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileWithResidentsQuery,
    ProfileWithResidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProfileWithResidentsQuery, ProfileWithResidentsQueryVariables>(
    ProfileWithResidentsDocument,
    options
  );
}
export type ProfileWithResidentsQueryHookResult = ReturnType<typeof useProfileWithResidentsQuery>;
export type ProfileWithResidentsLazyQueryHookResult = ReturnType<
  typeof useProfileWithResidentsLazyQuery
>;
export type ProfileWithResidentsQueryResult = Apollo.QueryResult<
  ProfileWithResidentsQuery,
  ProfileWithResidentsQueryVariables
>;
export const AddLoginDocument = gql`
  mutation AddLogin($input: AddLoginInput!) {
    user {
      addLogin(input: $input) {
        success
        error
        profile {
          id
          email
          logins {
            id
            ...ProfileLoginFields
          }
        }
      }
    }
  }
  ${ProfileLoginFieldsFragmentDoc}
`;
export type AddLoginMutationFn = Apollo.MutationFunction<
  AddLoginMutation,
  AddLoginMutationVariables
>;

/**
 * __useAddLoginMutation__
 *
 * To run a mutation, you first call `useAddLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLoginMutation, { data, loading, error }] = useAddLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<AddLoginMutation, AddLoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddLoginMutation, AddLoginMutationVariables>(AddLoginDocument, options);
}
export type AddLoginMutationHookResult = ReturnType<typeof useAddLoginMutation>;
export type AddLoginMutationResult = Apollo.MutationResult<AddLoginMutation>;
export type AddLoginMutationOptions = Apollo.BaseMutationOptions<
  AddLoginMutation,
  AddLoginMutationVariables
>;
export const GetBooksActivationDocument = gql`
  query GetBooksActivation($accountIds: [ID!]!) {
    booksActivation(accountIds: $accountIds) {
      anyActivated
    }
  }
`;

/**
 * __useGetBooksActivationQuery__
 *
 * To run a query within a React component, call `useGetBooksActivationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBooksActivationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBooksActivationQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *   },
 * });
 */
export function useGetBooksActivationQuery(
  baseOptions: Apollo.QueryHookOptions<GetBooksActivationQuery, GetBooksActivationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBooksActivationQuery, GetBooksActivationQueryVariables>(
    GetBooksActivationDocument,
    options
  );
}
export function useGetBooksActivationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBooksActivationQuery,
    GetBooksActivationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBooksActivationQuery, GetBooksActivationQueryVariables>(
    GetBooksActivationDocument,
    options
  );
}
export type GetBooksActivationQueryHookResult = ReturnType<typeof useGetBooksActivationQuery>;
export type GetBooksActivationLazyQueryHookResult = ReturnType<
  typeof useGetBooksActivationLazyQuery
>;
export type GetBooksActivationQueryResult = Apollo.QueryResult<
  GetBooksActivationQuery,
  GetBooksActivationQueryVariables
>;
export const GetHolidaysDocument = gql`
  query GetHolidays {
    holidays
  }
`;

/**
 * __useGetHolidaysQuery__
 *
 * To run a query within a React component, call `useGetHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHolidaysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHolidaysQuery(
  baseOptions?: Apollo.QueryHookOptions<GetHolidaysQuery, GetHolidaysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHolidaysQuery, GetHolidaysQueryVariables>(GetHolidaysDocument, options);
}
export function useGetHolidaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHolidaysQuery, GetHolidaysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHolidaysQuery, GetHolidaysQueryVariables>(
    GetHolidaysDocument,
    options
  );
}
export type GetHolidaysQueryHookResult = ReturnType<typeof useGetHolidaysQuery>;
export type GetHolidaysLazyQueryHookResult = ReturnType<typeof useGetHolidaysLazyQuery>;
export type GetHolidaysQueryResult = Apollo.QueryResult<
  GetHolidaysQuery,
  GetHolidaysQueryVariables
>;
export const DeleteLoginDocument = gql`
  mutation DeleteLogin($id: ID!) {
    user {
      deleteLogin(id: $id) {
        success
        error
        profile {
          id
          logins {
            id
            ...ProfileLoginFields
          }
        }
      }
    }
  }
  ${ProfileLoginFieldsFragmentDoc}
`;
export type DeleteLoginMutationFn = Apollo.MutationFunction<
  DeleteLoginMutation,
  DeleteLoginMutationVariables
>;

/**
 * __useDeleteLoginMutation__
 *
 * To run a mutation, you first call `useDeleteLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLoginMutation, { data, loading, error }] = useDeleteLoginMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteLoginMutation, DeleteLoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteLoginMutation, DeleteLoginMutationVariables>(
    DeleteLoginDocument,
    options
  );
}
export type DeleteLoginMutationHookResult = ReturnType<typeof useDeleteLoginMutation>;
export type DeleteLoginMutationResult = Apollo.MutationResult<DeleteLoginMutation>;
export type DeleteLoginMutationOptions = Apollo.BaseMutationOptions<
  DeleteLoginMutation,
  DeleteLoginMutationVariables
>;
export const GetManagerDocument = gql`
  query GetManager($id: ID!) {
    manager(id: $id) {
      ...ManagerFields
    }
  }
  ${ManagerFieldsFragmentDoc}
`;

/**
 * __useGetManagerQuery__
 *
 * To run a query within a React component, call `useGetManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetManagerQuery(
  baseOptions: Apollo.QueryHookOptions<GetManagerQuery, GetManagerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManagerQuery, GetManagerQueryVariables>(GetManagerDocument, options);
}
export function useGetManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManagerQuery, GetManagerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManagerQuery, GetManagerQueryVariables>(
    GetManagerDocument,
    options
  );
}
export type GetManagerQueryHookResult = ReturnType<typeof useGetManagerQuery>;
export type GetManagerLazyQueryHookResult = ReturnType<typeof useGetManagerLazyQuery>;
export type GetManagerQueryResult = Apollo.QueryResult<GetManagerQuery, GetManagerQueryVariables>;
export const GetProfileLoginsDocument = gql`
  query GetProfileLogins {
    user {
      profile {
        id
        email
        logins {
          id
          ...ProfileLoginFields
        }
      }
    }
  }
  ${ProfileLoginFieldsFragmentDoc}
`;

/**
 * __useGetProfileLoginsQuery__
 *
 * To run a query within a React component, call `useGetProfileLoginsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileLoginsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileLoginsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileLoginsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProfileLoginsQuery, GetProfileLoginsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileLoginsQuery, GetProfileLoginsQueryVariables>(
    GetProfileLoginsDocument,
    options
  );
}
export function useGetProfileLoginsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProfileLoginsQuery, GetProfileLoginsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileLoginsQuery, GetProfileLoginsQueryVariables>(
    GetProfileLoginsDocument,
    options
  );
}
export type GetProfileLoginsQueryHookResult = ReturnType<typeof useGetProfileLoginsQuery>;
export type GetProfileLoginsLazyQueryHookResult = ReturnType<typeof useGetProfileLoginsLazyQuery>;
export type GetProfileLoginsQueryResult = Apollo.QueryResult<
  GetProfileLoginsQuery,
  GetProfileLoginsQueryVariables
>;
export const ResidentsDocument = gql`
  query Residents {
    user {
      profile {
        id
        residents {
          ...ResidentFields
        }
      }
    }
  }
  ${ResidentFieldsFragmentDoc}
`;

/**
 * __useResidentsQuery__
 *
 * To run a query within a React component, call `useResidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResidentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useResidentsQuery(
  baseOptions?: Apollo.QueryHookOptions<ResidentsQuery, ResidentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResidentsQuery, ResidentsQueryVariables>(ResidentsDocument, options);
}
export function useResidentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ResidentsQuery, ResidentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResidentsQuery, ResidentsQueryVariables>(ResidentsDocument, options);
}
export type ResidentsQueryHookResult = ReturnType<typeof useResidentsQuery>;
export type ResidentsLazyQueryHookResult = ReturnType<typeof useResidentsLazyQuery>;
export type ResidentsQueryResult = Apollo.QueryResult<ResidentsQuery, ResidentsQueryVariables>;
export const GetUnitDocument = gql`
  query GetUnit($id: ID!) {
    unit(id: $id) {
      ...UnitFields
    }
  }
  ${UnitFieldsFragmentDoc}
`;

/**
 * __useGetUnitQuery__
 *
 * To run a query within a React component, call `useGetUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnitQuery(
  baseOptions: Apollo.QueryHookOptions<GetUnitQuery, GetUnitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnitQuery, GetUnitQueryVariables>(GetUnitDocument, options);
}
export function useGetUnitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUnitQuery, GetUnitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnitQuery, GetUnitQueryVariables>(GetUnitDocument, options);
}
export type GetUnitQueryHookResult = ReturnType<typeof useGetUnitQuery>;
export type GetUnitLazyQueryHookResult = ReturnType<typeof useGetUnitLazyQuery>;
export type GetUnitQueryResult = Apollo.QueryResult<GetUnitQuery, GetUnitQueryVariables>;
export const UpdateProfileEmailDocument = gql`
  mutation UpdateProfileEmail($input: UpdateProfileEmailInput!) {
    user {
      updateProfileEmail(input: $input) {
        success
        error
        profile {
          id
          email
          logins {
            id
            ...ProfileLoginFields
          }
        }
      }
    }
  }
  ${ProfileLoginFieldsFragmentDoc}
`;
export type UpdateProfileEmailMutationFn = Apollo.MutationFunction<
  UpdateProfileEmailMutation,
  UpdateProfileEmailMutationVariables
>;

/**
 * __useUpdateProfileEmailMutation__
 *
 * To run a mutation, you first call `useUpdateProfileEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileEmailMutation, { data, loading, error }] = useUpdateProfileEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileEmailMutation,
    UpdateProfileEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProfileEmailMutation, UpdateProfileEmailMutationVariables>(
    UpdateProfileEmailDocument,
    options
  );
}
export type UpdateProfileEmailMutationHookResult = ReturnType<typeof useUpdateProfileEmailMutation>;
export type UpdateProfileEmailMutationResult = Apollo.MutationResult<UpdateProfileEmailMutation>;
export type UpdateProfileEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileEmailMutation,
  UpdateProfileEmailMutationVariables
>;
export const AnnouncementAttachmentPresignedUrlDocument = gql`
  query AnnouncementAttachmentPresignedUrl($key: String!, $inboxMessageId: String!) {
    announcementAttachmentPresignedUrl(key: $key, inboxMessageId: $inboxMessageId) {
      link
      error
    }
  }
`;

/**
 * __useAnnouncementAttachmentPresignedUrlQuery__
 *
 * To run a query within a React component, call `useAnnouncementAttachmentPresignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementAttachmentPresignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementAttachmentPresignedUrlQuery({
 *   variables: {
 *      key: // value for 'key'
 *      inboxMessageId: // value for 'inboxMessageId'
 *   },
 * });
 */
export function useAnnouncementAttachmentPresignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnnouncementAttachmentPresignedUrlQuery,
    AnnouncementAttachmentPresignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnnouncementAttachmentPresignedUrlQuery,
    AnnouncementAttachmentPresignedUrlQueryVariables
  >(AnnouncementAttachmentPresignedUrlDocument, options);
}
export function useAnnouncementAttachmentPresignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnnouncementAttachmentPresignedUrlQuery,
    AnnouncementAttachmentPresignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnnouncementAttachmentPresignedUrlQuery,
    AnnouncementAttachmentPresignedUrlQueryVariables
  >(AnnouncementAttachmentPresignedUrlDocument, options);
}
export type AnnouncementAttachmentPresignedUrlQueryHookResult = ReturnType<
  typeof useAnnouncementAttachmentPresignedUrlQuery
>;
export type AnnouncementAttachmentPresignedUrlLazyQueryHookResult = ReturnType<
  typeof useAnnouncementAttachmentPresignedUrlLazyQuery
>;
export type AnnouncementAttachmentPresignedUrlQueryResult = Apollo.QueryResult<
  AnnouncementAttachmentPresignedUrlQuery,
  AnnouncementAttachmentPresignedUrlQueryVariables
>;
export const ListAnnouncementInboxDocument = gql`
  query ListAnnouncementInbox($nextToken: String, $state: InboxMessageStates = all) {
    user {
      profile {
        id
        listInboxMessages(
          nextToken: $nextToken
          filter: { entities: [announcement], state: $state }
        ) {
          items {
            id
            accountId
            emailBody
            emailSubject
            publishZ
            readZ
            archivedZ
            announcementId
          }
          nextToken
        }
      }
    }
  }
`;

/**
 * __useListAnnouncementInboxQuery__
 *
 * To run a query within a React component, call `useListAnnouncementInboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAnnouncementInboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAnnouncementInboxQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useListAnnouncementInboxQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAnnouncementInboxQuery,
    ListAnnouncementInboxQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListAnnouncementInboxQuery, ListAnnouncementInboxQueryVariables>(
    ListAnnouncementInboxDocument,
    options
  );
}
export function useListAnnouncementInboxLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAnnouncementInboxQuery,
    ListAnnouncementInboxQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListAnnouncementInboxQuery, ListAnnouncementInboxQueryVariables>(
    ListAnnouncementInboxDocument,
    options
  );
}
export type ListAnnouncementInboxQueryHookResult = ReturnType<typeof useListAnnouncementInboxQuery>;
export type ListAnnouncementInboxLazyQueryHookResult = ReturnType<
  typeof useListAnnouncementInboxLazyQuery
>;
export type ListAnnouncementInboxQueryResult = Apollo.QueryResult<
  ListAnnouncementInboxQuery,
  ListAnnouncementInboxQueryVariables
>;
export const GetInboxMessageDocument = gql`
  query GetInboxMessage($id: ID!) {
    inboxMessage(id: $id) {
      id
      accountId
      emailBody
      emailSubject
      publishZ
      readZ
      archivedZ
      attachments {
        key
        name
        type
      }
    }
  }
`;

/**
 * __useGetInboxMessageQuery__
 *
 * To run a query within a React component, call `useGetInboxMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboxMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboxMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInboxMessageQuery(
  baseOptions: Apollo.QueryHookOptions<GetInboxMessageQuery, GetInboxMessageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInboxMessageQuery, GetInboxMessageQueryVariables>(
    GetInboxMessageDocument,
    options
  );
}
export function useGetInboxMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInboxMessageQuery, GetInboxMessageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInboxMessageQuery, GetInboxMessageQueryVariables>(
    GetInboxMessageDocument,
    options
  );
}
export type GetInboxMessageQueryHookResult = ReturnType<typeof useGetInboxMessageQuery>;
export type GetInboxMessageLazyQueryHookResult = ReturnType<typeof useGetInboxMessageLazyQuery>;
export type GetInboxMessageQueryResult = Apollo.QueryResult<
  GetInboxMessageQuery,
  GetInboxMessageQueryVariables
>;
export const GetInboxMessageForActionsDocument = gql`
  query GetInboxMessageForActions($id: ID!) {
    inboxMessage(id: $id) {
      id
      readZ
      archivedZ
    }
  }
`;

/**
 * __useGetInboxMessageForActionsQuery__
 *
 * To run a query within a React component, call `useGetInboxMessageForActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboxMessageForActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboxMessageForActionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInboxMessageForActionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInboxMessageForActionsQuery,
    GetInboxMessageForActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInboxMessageForActionsQuery, GetInboxMessageForActionsQueryVariables>(
    GetInboxMessageForActionsDocument,
    options
  );
}
export function useGetInboxMessageForActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInboxMessageForActionsQuery,
    GetInboxMessageForActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInboxMessageForActionsQuery,
    GetInboxMessageForActionsQueryVariables
  >(GetInboxMessageForActionsDocument, options);
}
export type GetInboxMessageForActionsQueryHookResult = ReturnType<
  typeof useGetInboxMessageForActionsQuery
>;
export type GetInboxMessageForActionsLazyQueryHookResult = ReturnType<
  typeof useGetInboxMessageForActionsLazyQuery
>;
export type GetInboxMessageForActionsQueryResult = Apollo.QueryResult<
  GetInboxMessageForActionsQuery,
  GetInboxMessageForActionsQueryVariables
>;
export const UpdateInboxMessageDocument = gql`
  mutation UpdateInboxMessage($id: ID!, $input: UpdateInboxMessageInput!) {
    updateInboxMessage(id: $id, input: $input) {
      success
      error
      inboxMessage {
        id
        readZ
        archivedZ
      }
    }
  }
`;
export type UpdateInboxMessageMutationFn = Apollo.MutationFunction<
  UpdateInboxMessageMutation,
  UpdateInboxMessageMutationVariables
>;

/**
 * __useUpdateInboxMessageMutation__
 *
 * To run a mutation, you first call `useUpdateInboxMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInboxMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInboxMessageMutation, { data, loading, error }] = useUpdateInboxMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInboxMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInboxMessageMutation,
    UpdateInboxMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInboxMessageMutation, UpdateInboxMessageMutationVariables>(
    UpdateInboxMessageDocument,
    options
  );
}
export type UpdateInboxMessageMutationHookResult = ReturnType<typeof useUpdateInboxMessageMutation>;
export type UpdateInboxMessageMutationResult = Apollo.MutationResult<UpdateInboxMessageMutation>;
export type UpdateInboxMessageMutationOptions = Apollo.BaseMutationOptions<
  UpdateInboxMessageMutation,
  UpdateInboxMessageMutationVariables
>;
export const GetAccountDetailsDocument = gql`
  query GetAccountDetails($id: ID!) {
    accountSettings(id: $id) {
      id
      name
      books {
        gstNumber
      }
      logoKey
      address {
        street
        city
        province
        postal
        suite
        lat
        lng
      }
    }
  }
`;

/**
 * __useGetAccountDetailsQuery__
 *
 * To run a query within a React component, call `useGetAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>(
    GetAccountDetailsDocument,
    options
  );
}
export function useGetAccountDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>(
    GetAccountDetailsDocument,
    options
  );
}
export type GetAccountDetailsQueryHookResult = ReturnType<typeof useGetAccountDetailsQuery>;
export type GetAccountDetailsLazyQueryHookResult = ReturnType<typeof useGetAccountDetailsLazyQuery>;
export type GetAccountDetailsQueryResult = Apollo.QueryResult<
  GetAccountDetailsQuery,
  GetAccountDetailsQueryVariables
>;
export const GetPropertyExpensesDocument = gql`
  query GetPropertyExpenses($filter: PropertyExpensesFilterItemInput!) {
    propertyExpenses(filter: $filter) {
      ...ExpenseEntryFields
    }
  }
  ${ExpenseEntryFieldsFragmentDoc}
`;

/**
 * __useGetPropertyExpensesQuery__
 *
 * To run a query within a React component, call `useGetPropertyExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyExpensesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPropertyExpensesQuery(
  baseOptions: Apollo.QueryHookOptions<GetPropertyExpensesQuery, GetPropertyExpensesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyExpensesQuery, GetPropertyExpensesQueryVariables>(
    GetPropertyExpensesDocument,
    options
  );
}
export function useGetPropertyExpensesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyExpensesQuery,
    GetPropertyExpensesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyExpensesQuery, GetPropertyExpensesQueryVariables>(
    GetPropertyExpensesDocument,
    options
  );
}
export type GetPropertyExpensesQueryHookResult = ReturnType<typeof useGetPropertyExpensesQuery>;
export type GetPropertyExpensesLazyQueryHookResult = ReturnType<
  typeof useGetPropertyExpensesLazyQuery
>;
export type GetPropertyExpensesQueryResult = Apollo.QueryResult<
  GetPropertyExpensesQuery,
  GetPropertyExpensesQueryVariables
>;
export const GetExpensesDocumentLinkDocument = gql`
  query GetExpensesDocumentLink($filter: PropertyExpensesDownloadInput!) {
    propertyExpensesDocumentLink(filter: $filter) {
      link
      error
    }
  }
`;

/**
 * __useGetExpensesDocumentLinkQuery__
 *
 * To run a query within a React component, call `useGetExpensesDocumentLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpensesDocumentLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpensesDocumentLinkQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetExpensesDocumentLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExpensesDocumentLinkQuery,
    GetExpensesDocumentLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExpensesDocumentLinkQuery, GetExpensesDocumentLinkQueryVariables>(
    GetExpensesDocumentLinkDocument,
    options
  );
}
export function useGetExpensesDocumentLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExpensesDocumentLinkQuery,
    GetExpensesDocumentLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExpensesDocumentLinkQuery, GetExpensesDocumentLinkQueryVariables>(
    GetExpensesDocumentLinkDocument,
    options
  );
}
export type GetExpensesDocumentLinkQueryHookResult = ReturnType<
  typeof useGetExpensesDocumentLinkQuery
>;
export type GetExpensesDocumentLinkLazyQueryHookResult = ReturnType<
  typeof useGetExpensesDocumentLinkLazyQuery
>;
export type GetExpensesDocumentLinkQueryResult = Apollo.QueryResult<
  GetExpensesDocumentLinkQuery,
  GetExpensesDocumentLinkQueryVariables
>;
export const GetOwnerNameAndAddressDocument = gql`
  query GetOwnerNameAndAddress($id: ID!) {
    owner(id: $id) {
      id
      name
      address {
        street
        city
        province
        postal
      }
    }
  }
`;

/**
 * __useGetOwnerNameAndAddressQuery__
 *
 * To run a query within a React component, call `useGetOwnerNameAndAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerNameAndAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerNameAndAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOwnerNameAndAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOwnerNameAndAddressQuery,
    GetOwnerNameAndAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnerNameAndAddressQuery, GetOwnerNameAndAddressQueryVariables>(
    GetOwnerNameAndAddressDocument,
    options
  );
}
export function useGetOwnerNameAndAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOwnerNameAndAddressQuery,
    GetOwnerNameAndAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnerNameAndAddressQuery, GetOwnerNameAndAddressQueryVariables>(
    GetOwnerNameAndAddressDocument,
    options
  );
}
export type GetOwnerNameAndAddressQueryHookResult = ReturnType<
  typeof useGetOwnerNameAndAddressQuery
>;
export type GetOwnerNameAndAddressLazyQueryHookResult = ReturnType<
  typeof useGetOwnerNameAndAddressLazyQuery
>;
export type GetOwnerNameAndAddressQueryResult = Apollo.QueryResult<
  GetOwnerNameAndAddressQuery,
  GetOwnerNameAndAddressQueryVariables
>;
export const GetRequestDetailsDocument = gql`
  query GetRequestDetails($id: ID!) {
    user {
      profile {
        id
        request(id: $id) {
          accountId
          id
          category
          name
          originalSubject
          originalRequest
          status
          costLines {
            id
            amount
            visitNumber
            markup
            description
            total
          }
          calculatedTaxes {
            label
            amount
          }
          taxExempted
          resolution
          unit {
            name
            ownerId
            property {
              ownerId
              name
              phone
              key
              address {
                street
                city
                province
                postal
              }
              account {
                name
                phone
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetRequestDetailsQuery__
 *
 * To run a query within a React component, call `useGetRequestDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequestDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetRequestDetailsQuery, GetRequestDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRequestDetailsQuery, GetRequestDetailsQueryVariables>(
    GetRequestDetailsDocument,
    options
  );
}
export function useGetRequestDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequestDetailsQuery, GetRequestDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRequestDetailsQuery, GetRequestDetailsQueryVariables>(
    GetRequestDetailsDocument,
    options
  );
}
export type GetRequestDetailsQueryHookResult = ReturnType<typeof useGetRequestDetailsQuery>;
export type GetRequestDetailsLazyQueryHookResult = ReturnType<typeof useGetRequestDetailsLazyQuery>;
export type GetRequestDetailsQueryResult = Apollo.QueryResult<
  GetRequestDetailsQuery,
  GetRequestDetailsQueryVariables
>;
export const GetOwnerDocument = gql`
  query GetOwner($id: ID!) {
    owner(id: $id) {
      ...OwnerFields
    }
  }
  ${OwnerFieldsFragmentDoc}
`;

/**
 * __useGetOwnerQuery__
 *
 * To run a query within a React component, call `useGetOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOwnerQuery(
  baseOptions: Apollo.QueryHookOptions<GetOwnerQuery, GetOwnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnerQuery, GetOwnerQueryVariables>(GetOwnerDocument, options);
}
export function useGetOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOwnerQuery, GetOwnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnerQuery, GetOwnerQueryVariables>(GetOwnerDocument, options);
}
export type GetOwnerQueryHookResult = ReturnType<typeof useGetOwnerQuery>;
export type GetOwnerLazyQueryHookResult = ReturnType<typeof useGetOwnerLazyQuery>;
export type GetOwnerQueryResult = Apollo.QueryResult<GetOwnerQuery, GetOwnerQueryVariables>;
export const GetPropertyDocument = gql`
  query GetProperty($id: ID!) {
    property(id: $id) {
      ...PropertyFields
    }
  }
  ${PropertyFieldsFragmentDoc}
`;

/**
 * __useGetPropertyQuery__
 *
 * To run a query within a React component, call `useGetPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyQuery(
  baseOptions: Apollo.QueryHookOptions<GetPropertyQuery, GetPropertyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyQuery, GetPropertyQueryVariables>(GetPropertyDocument, options);
}
export function useGetPropertyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyQuery, GetPropertyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyQuery, GetPropertyQueryVariables>(
    GetPropertyDocument,
    options
  );
}
export type GetPropertyQueryHookResult = ReturnType<typeof useGetPropertyQuery>;
export type GetPropertyLazyQueryHookResult = ReturnType<typeof useGetPropertyLazyQuery>;
export type GetPropertyQueryResult = Apollo.QueryResult<
  GetPropertyQuery,
  GetPropertyQueryVariables
>;
export const GetPropertyNameAndAddressDocument = gql`
  query GetPropertyNameAndAddress($id: ID!) {
    property(id: $id) {
      name
      ownerId
      address {
        street
        city
        province
        postal
      }
    }
  }
`;

/**
 * __useGetPropertyNameAndAddressQuery__
 *
 * To run a query within a React component, call `useGetPropertyNameAndAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyNameAndAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyNameAndAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyNameAndAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyNameAndAddressQuery,
    GetPropertyNameAndAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyNameAndAddressQuery, GetPropertyNameAndAddressQueryVariables>(
    GetPropertyNameAndAddressDocument,
    options
  );
}
export function useGetPropertyNameAndAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyNameAndAddressQuery,
    GetPropertyNameAndAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPropertyNameAndAddressQuery,
    GetPropertyNameAndAddressQueryVariables
  >(GetPropertyNameAndAddressDocument, options);
}
export type GetPropertyNameAndAddressQueryHookResult = ReturnType<
  typeof useGetPropertyNameAndAddressQuery
>;
export type GetPropertyNameAndAddressLazyQueryHookResult = ReturnType<
  typeof useGetPropertyNameAndAddressLazyQuery
>;
export type GetPropertyNameAndAddressQueryResult = Apollo.QueryResult<
  GetPropertyNameAndAddressQuery,
  GetPropertyNameAndAddressQueryVariables
>;
export const CancelPadDocument = gql`
  mutation CancelPad($enrollmentId: ID!) {
    cancelPad(enrollmentId: $enrollmentId) {
      error
      success
      enrollment {
        ...AutopayEnrollmentFields
      }
    }
  }
  ${AutopayEnrollmentFieldsFragmentDoc}
`;
export type CancelPadMutationFn = Apollo.MutationFunction<
  CancelPadMutation,
  CancelPadMutationVariables
>;

/**
 * __useCancelPadMutation__
 *
 * To run a mutation, you first call `useCancelPadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPadMutation, { data, loading, error }] = useCancelPadMutation({
 *   variables: {
 *      enrollmentId: // value for 'enrollmentId'
 *   },
 * });
 */
export function useCancelPadMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelPadMutation, CancelPadMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelPadMutation, CancelPadMutationVariables>(
    CancelPadDocument,
    options
  );
}
export type CancelPadMutationHookResult = ReturnType<typeof useCancelPadMutation>;
export type CancelPadMutationResult = Apollo.MutationResult<CancelPadMutation>;
export type CancelPadMutationOptions = Apollo.BaseMutationOptions<
  CancelPadMutation,
  CancelPadMutationVariables
>;
export const DeclinePadInviteDocument = gql`
  mutation DeclinePadInvite($enrollmentId: ID!) {
    declinePadInvite(enrollmentId: $enrollmentId) {
      error
      success
      enrollment {
        ...AutopayEnrollmentFields
      }
    }
  }
  ${AutopayEnrollmentFieldsFragmentDoc}
`;
export type DeclinePadInviteMutationFn = Apollo.MutationFunction<
  DeclinePadInviteMutation,
  DeclinePadInviteMutationVariables
>;

/**
 * __useDeclinePadInviteMutation__
 *
 * To run a mutation, you first call `useDeclinePadInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclinePadInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declinePadInviteMutation, { data, loading, error }] = useDeclinePadInviteMutation({
 *   variables: {
 *      enrollmentId: // value for 'enrollmentId'
 *   },
 * });
 */
export function useDeclinePadInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclinePadInviteMutation,
    DeclinePadInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeclinePadInviteMutation, DeclinePadInviteMutationVariables>(
    DeclinePadInviteDocument,
    options
  );
}
export type DeclinePadInviteMutationHookResult = ReturnType<typeof useDeclinePadInviteMutation>;
export type DeclinePadInviteMutationResult = Apollo.MutationResult<DeclinePadInviteMutation>;
export type DeclinePadInviteMutationOptions = Apollo.BaseMutationOptions<
  DeclinePadInviteMutation,
  DeclinePadInviteMutationVariables
>;
export const SavePadDocument = gql`
  mutation SavePad($enrollmentId: ID!, $input: SavePadInput!) {
    savePad(enrollmentId: $enrollmentId, input: $input) {
      error
      success
      enrollment {
        ...AutopayEnrollmentFields
      }
    }
  }
  ${AutopayEnrollmentFieldsFragmentDoc}
`;
export type SavePadMutationFn = Apollo.MutationFunction<SavePadMutation, SavePadMutationVariables>;

/**
 * __useSavePadMutation__
 *
 * To run a mutation, you first call `useSavePadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePadMutation, { data, loading, error }] = useSavePadMutation({
 *   variables: {
 *      enrollmentId: // value for 'enrollmentId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePadMutation(
  baseOptions?: Apollo.MutationHookOptions<SavePadMutation, SavePadMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SavePadMutation, SavePadMutationVariables>(SavePadDocument, options);
}
export type SavePadMutationHookResult = ReturnType<typeof useSavePadMutation>;
export type SavePadMutationResult = Apollo.MutationResult<SavePadMutation>;
export type SavePadMutationOptions = Apollo.BaseMutationOptions<
  SavePadMutation,
  SavePadMutationVariables
>;
export const UpdatePadDocument = gql`
  mutation UpdatePad($enrollmentId: ID!, $input: UpdatePadInput!) {
    updatePad(enrollmentId: $enrollmentId, input: $input) {
      error
      success
      enrollment {
        ...AutopayEnrollmentFields
      }
    }
  }
  ${AutopayEnrollmentFieldsFragmentDoc}
`;
export type UpdatePadMutationFn = Apollo.MutationFunction<
  UpdatePadMutation,
  UpdatePadMutationVariables
>;

/**
 * __useUpdatePadMutation__
 *
 * To run a mutation, you first call `useUpdatePadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePadMutation, { data, loading, error }] = useUpdatePadMutation({
 *   variables: {
 *      enrollmentId: // value for 'enrollmentId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePadMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePadMutation, UpdatePadMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePadMutation, UpdatePadMutationVariables>(
    UpdatePadDocument,
    options
  );
}
export type UpdatePadMutationHookResult = ReturnType<typeof useUpdatePadMutation>;
export type UpdatePadMutationResult = Apollo.MutationResult<UpdatePadMutation>;
export type UpdatePadMutationOptions = Apollo.BaseMutationOptions<
  UpdatePadMutation,
  UpdatePadMutationVariables
>;
export const GetClearablePaymentsDocument = gql`
  query GetClearablePayments($id: ID!, $payeeAccountId: PayeeClearablesFilterItemInput!) {
    payeeClearable(id: $id, payeeAccountId: $payeeAccountId) {
      ...ClearableDetailFields
    }
  }
  ${ClearableDetailFieldsFragmentDoc}
`;

/**
 * __useGetClearablePaymentsQuery__
 *
 * To run a query within a React component, call `useGetClearablePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClearablePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClearablePaymentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      payeeAccountId: // value for 'payeeAccountId'
 *   },
 * });
 */
export function useGetClearablePaymentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClearablePaymentsQuery,
    GetClearablePaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClearablePaymentsQuery, GetClearablePaymentsQueryVariables>(
    GetClearablePaymentsDocument,
    options
  );
}
export function useGetClearablePaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClearablePaymentsQuery,
    GetClearablePaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClearablePaymentsQuery, GetClearablePaymentsQueryVariables>(
    GetClearablePaymentsDocument,
    options
  );
}
export type GetClearablePaymentsQueryHookResult = ReturnType<typeof useGetClearablePaymentsQuery>;
export type GetClearablePaymentsLazyQueryHookResult = ReturnType<
  typeof useGetClearablePaymentsLazyQuery
>;
export type GetClearablePaymentsQueryResult = Apollo.QueryResult<
  GetClearablePaymentsQuery,
  GetClearablePaymentsQueryVariables
>;
export const CreateAutopaySingleCollectionDocument = gql`
  mutation CreateAutopaySingleCollection($input: CreateAutopaySingleInput!) {
    createAutopaySingle(input: $input) {
      success
      error
      autopaySingle {
        id
        amount
        clearableIds
        singlePosted
      }
    }
  }
`;
export type CreateAutopaySingleCollectionMutationFn = Apollo.MutationFunction<
  CreateAutopaySingleCollectionMutation,
  CreateAutopaySingleCollectionMutationVariables
>;

/**
 * __useCreateAutopaySingleCollectionMutation__
 *
 * To run a mutation, you first call `useCreateAutopaySingleCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAutopaySingleCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAutopaySingleCollectionMutation, { data, loading, error }] = useCreateAutopaySingleCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAutopaySingleCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAutopaySingleCollectionMutation,
    CreateAutopaySingleCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAutopaySingleCollectionMutation,
    CreateAutopaySingleCollectionMutationVariables
  >(CreateAutopaySingleCollectionDocument, options);
}
export type CreateAutopaySingleCollectionMutationHookResult = ReturnType<
  typeof useCreateAutopaySingleCollectionMutation
>;
export type CreateAutopaySingleCollectionMutationResult =
  Apollo.MutationResult<CreateAutopaySingleCollectionMutation>;
export type CreateAutopaySingleCollectionMutationOptions = Apollo.BaseMutationOptions<
  CreateAutopaySingleCollectionMutation,
  CreateAutopaySingleCollectionMutationVariables
>;
export const GetPayeeClearablesDocument = gql`
  query GetPayeeClearables(
    $after: ID
    $first: Int
    $payeeAccountIds: [PayeeClearablesFilterItemInput!]!
    $propertyIds: [ID!]
    $showPaid: Boolean!
  ) {
    payeeClearables(
      first: $first
      after: $after
      payeeAccountIds: $payeeAccountIds
      propertyIds: $propertyIds
      showPaid: $showPaid
    ) {
      totalCount
      totalByUnit {
        unitId
        totalOutstanding
      }
      totalByProperty {
        propertyId
        totalOutstanding
      }
      totalByAccount {
        accountId
        totalOutstanding
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ClearableListFields
        }
      }
    }
  }
  ${ClearableListFieldsFragmentDoc}
`;

/**
 * __useGetPayeeClearablesQuery__
 *
 * To run a query within a React component, call `useGetPayeeClearablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayeeClearablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayeeClearablesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      payeeAccountIds: // value for 'payeeAccountIds'
 *      propertyIds: // value for 'propertyIds'
 *      showPaid: // value for 'showPaid'
 *   },
 * });
 */
export function useGetPayeeClearablesQuery(
  baseOptions: Apollo.QueryHookOptions<GetPayeeClearablesQuery, GetPayeeClearablesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPayeeClearablesQuery, GetPayeeClearablesQueryVariables>(
    GetPayeeClearablesDocument,
    options
  );
}
export function useGetPayeeClearablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayeeClearablesQuery,
    GetPayeeClearablesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPayeeClearablesQuery, GetPayeeClearablesQueryVariables>(
    GetPayeeClearablesDocument,
    options
  );
}
export type GetPayeeClearablesQueryHookResult = ReturnType<typeof useGetPayeeClearablesQuery>;
export type GetPayeeClearablesLazyQueryHookResult = ReturnType<
  typeof useGetPayeeClearablesLazyQuery
>;
export type GetPayeeClearablesQueryResult = Apollo.QueryResult<
  GetPayeeClearablesQuery,
  GetPayeeClearablesQueryVariables
>;
export const GetManagerAccountAutopayDocument = gql`
  query GetManagerAccountAutopay($id: ID!) {
    accountAutopay(id: $id) {
      id
      books {
        autopay {
          activationStatus
          activated
          version
          enableCollection
        }
      }
    }
  }
`;

/**
 * __useGetManagerAccountAutopayQuery__
 *
 * To run a query within a React component, call `useGetManagerAccountAutopayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerAccountAutopayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerAccountAutopayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetManagerAccountAutopayQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagerAccountAutopayQuery,
    GetManagerAccountAutopayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManagerAccountAutopayQuery, GetManagerAccountAutopayQueryVariables>(
    GetManagerAccountAutopayDocument,
    options
  );
}
export function useGetManagerAccountAutopayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagerAccountAutopayQuery,
    GetManagerAccountAutopayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManagerAccountAutopayQuery, GetManagerAccountAutopayQueryVariables>(
    GetManagerAccountAutopayDocument,
    options
  );
}
export type GetManagerAccountAutopayQueryHookResult = ReturnType<
  typeof useGetManagerAccountAutopayQuery
>;
export type GetManagerAccountAutopayLazyQueryHookResult = ReturnType<
  typeof useGetManagerAccountAutopayLazyQuery
>;
export type GetManagerAccountAutopayQueryResult = Apollo.QueryResult<
  GetManagerAccountAutopayQuery,
  GetManagerAccountAutopayQueryVariables
>;
export const SuspendAutopayDocument = gql`
  mutation SuspendAutopay($payeeId: ID!) {
    suspendAutopay(payeeId: $payeeId) {
      success
      error
    }
  }
`;
export type SuspendAutopayMutationFn = Apollo.MutationFunction<
  SuspendAutopayMutation,
  SuspendAutopayMutationVariables
>;

/**
 * __useSuspendAutopayMutation__
 *
 * To run a mutation, you first call `useSuspendAutopayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspendAutopayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspendAutopayMutation, { data, loading, error }] = useSuspendAutopayMutation({
 *   variables: {
 *      payeeId: // value for 'payeeId'
 *   },
 * });
 */
export function useSuspendAutopayMutation(
  baseOptions?: Apollo.MutationHookOptions<SuspendAutopayMutation, SuspendAutopayMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SuspendAutopayMutation, SuspendAutopayMutationVariables>(
    SuspendAutopayDocument,
    options
  );
}
export type SuspendAutopayMutationHookResult = ReturnType<typeof useSuspendAutopayMutation>;
export type SuspendAutopayMutationResult = Apollo.MutationResult<SuspendAutopayMutation>;
export type SuspendAutopayMutationOptions = Apollo.BaseMutationOptions<
  SuspendAutopayMutation,
  SuspendAutopayMutationVariables
>;
export const ReactivateAutopayDocument = gql`
  mutation ReactivateAutopay($payeeId: ID!) {
    reactivateAutopay(payeeId: $payeeId) {
      success
      error
    }
  }
`;
export type ReactivateAutopayMutationFn = Apollo.MutationFunction<
  ReactivateAutopayMutation,
  ReactivateAutopayMutationVariables
>;

/**
 * __useReactivateAutopayMutation__
 *
 * To run a mutation, you first call `useReactivateAutopayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateAutopayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateAutopayMutation, { data, loading, error }] = useReactivateAutopayMutation({
 *   variables: {
 *      payeeId: // value for 'payeeId'
 *   },
 * });
 */
export function useReactivateAutopayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReactivateAutopayMutation,
    ReactivateAutopayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReactivateAutopayMutation, ReactivateAutopayMutationVariables>(
    ReactivateAutopayDocument,
    options
  );
}
export type ReactivateAutopayMutationHookResult = ReturnType<typeof useReactivateAutopayMutation>;
export type ReactivateAutopayMutationResult = Apollo.MutationResult<ReactivateAutopayMutation>;
export type ReactivateAutopayMutationOptions = Apollo.BaseMutationOptions<
  ReactivateAutopayMutation,
  ReactivateAutopayMutationVariables
>;
export const GetAutopayEmbedUrlDocument = gql`
  mutation GetAutopayEmbedUrl($payeeId: ID!) {
    createAutopayEmbedUrl(payeeId: $payeeId) {
      success
      error
      url
      iframeKey
    }
  }
`;
export type GetAutopayEmbedUrlMutationFn = Apollo.MutationFunction<
  GetAutopayEmbedUrlMutation,
  GetAutopayEmbedUrlMutationVariables
>;

/**
 * __useGetAutopayEmbedUrlMutation__
 *
 * To run a mutation, you first call `useGetAutopayEmbedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAutopayEmbedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAutopayEmbedUrlMutation, { data, loading, error }] = useGetAutopayEmbedUrlMutation({
 *   variables: {
 *      payeeId: // value for 'payeeId'
 *   },
 * });
 */
export function useGetAutopayEmbedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetAutopayEmbedUrlMutation,
    GetAutopayEmbedUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetAutopayEmbedUrlMutation, GetAutopayEmbedUrlMutationVariables>(
    GetAutopayEmbedUrlDocument,
    options
  );
}
export type GetAutopayEmbedUrlMutationHookResult = ReturnType<typeof useGetAutopayEmbedUrlMutation>;
export type GetAutopayEmbedUrlMutationResult = Apollo.MutationResult<GetAutopayEmbedUrlMutation>;
export type GetAutopayEmbedUrlMutationOptions = Apollo.BaseMutationOptions<
  GetAutopayEmbedUrlMutation,
  GetAutopayEmbedUrlMutationVariables
>;
export const GetApplicationsDocument = gql`
  query GetApplications {
    applications {
      ...ApplicationFields
    }
  }
  ${ApplicationFieldsFragmentDoc}
`;

/**
 * __useGetApplicationsQuery__
 *
 * To run a query within a React component, call `useGetApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetApplicationsQuery, GetApplicationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApplicationsQuery, GetApplicationsQueryVariables>(
    GetApplicationsDocument,
    options
  );
}
export function useGetApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationsQuery, GetApplicationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApplicationsQuery, GetApplicationsQueryVariables>(
    GetApplicationsDocument,
    options
  );
}
export type GetApplicationsQueryHookResult = ReturnType<typeof useGetApplicationsQuery>;
export type GetApplicationsLazyQueryHookResult = ReturnType<typeof useGetApplicationsLazyQuery>;
export type GetApplicationsQueryResult = Apollo.QueryResult<
  GetApplicationsQuery,
  GetApplicationsQueryVariables
>;
export const GetApplicationsByApplicationIdDocument = gql`
  query GetApplicationsByApplicationId($applicationId: ID!) {
    applicationsByApplicationId(applicationId: $applicationId) {
      ...ApplicationFields
    }
  }
  ${ApplicationFieldsFragmentDoc}
`;

/**
 * __useGetApplicationsByApplicationIdQuery__
 *
 * To run a query within a React component, call `useGetApplicationsByApplicationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationsByApplicationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationsByApplicationIdQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useGetApplicationsByApplicationIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationsByApplicationIdQuery,
    GetApplicationsByApplicationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationsByApplicationIdQuery,
    GetApplicationsByApplicationIdQueryVariables
  >(GetApplicationsByApplicationIdDocument, options);
}
export function useGetApplicationsByApplicationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationsByApplicationIdQuery,
    GetApplicationsByApplicationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationsByApplicationIdQuery,
    GetApplicationsByApplicationIdQueryVariables
  >(GetApplicationsByApplicationIdDocument, options);
}
export type GetApplicationsByApplicationIdQueryHookResult = ReturnType<
  typeof useGetApplicationsByApplicationIdQuery
>;
export type GetApplicationsByApplicationIdLazyQueryHookResult = ReturnType<
  typeof useGetApplicationsByApplicationIdLazyQuery
>;
export type GetApplicationsByApplicationIdQueryResult = Apollo.QueryResult<
  GetApplicationsByApplicationIdQuery,
  GetApplicationsByApplicationIdQueryVariables
>;
export const GetCampaignDocument = gql`
  query GetCampaign($id: ID!) {
    campaign(id: $id) {
      ...CampaignFields
    }
  }
  ${CampaignFieldsFragmentDoc}
`;

/**
 * __useGetCampaignQuery__
 *
 * To run a query within a React component, call `useGetCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCampaignQuery(
  baseOptions: Apollo.QueryHookOptions<GetCampaignQuery, GetCampaignQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCampaignQuery, GetCampaignQueryVariables>(GetCampaignDocument, options);
}
export function useGetCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignQuery, GetCampaignQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCampaignQuery, GetCampaignQueryVariables>(
    GetCampaignDocument,
    options
  );
}
export type GetCampaignQueryHookResult = ReturnType<typeof useGetCampaignQuery>;
export type GetCampaignLazyQueryHookResult = ReturnType<typeof useGetCampaignLazyQuery>;
export type GetCampaignQueryResult = Apollo.QueryResult<
  GetCampaignQuery,
  GetCampaignQueryVariables
>;
export const GetCampaignsDocument = gql`
  query GetCampaigns {
    campaigns {
      ...CampaignFields
    }
  }
  ${CampaignFieldsFragmentDoc}
`;

/**
 * __useGetCampaignsQuery__
 *
 * To run a query within a React component, call `useGetCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCampaignsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(
    GetCampaignsDocument,
    options
  );
}
export function useGetCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(
    GetCampaignsDocument,
    options
  );
}
export type GetCampaignsQueryHookResult = ReturnType<typeof useGetCampaignsQuery>;
export type GetCampaignsLazyQueryHookResult = ReturnType<typeof useGetCampaignsLazyQuery>;
export type GetCampaignsQueryResult = Apollo.QueryResult<
  GetCampaignsQuery,
  GetCampaignsQueryVariables
>;
export const CreateCampaignDocument = gql`
  mutation CreateCampaign($id: ID!, $input: CreateCampaignInput!) {
    createCampaign(id: $id, input: $input) {
      success
      error
      campaign {
        ...CampaignFields
      }
    }
  }
  ${CampaignFieldsFragmentDoc}
`;
export type CreateCampaignMutationFn = Apollo.MutationFunction<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>;

/**
 * __useCreateCampaignMutation__
 *
 * To run a mutation, you first call `useCreateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignMutation, { data, loading, error }] = useCreateCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCampaignMutation, CreateCampaignMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCampaignMutation, CreateCampaignMutationVariables>(
    CreateCampaignDocument,
    options
  );
}
export type CreateCampaignMutationHookResult = ReturnType<typeof useCreateCampaignMutation>;
export type CreateCampaignMutationResult = Apollo.MutationResult<CreateCampaignMutation>;
export type CreateCampaignMutationOptions = Apollo.BaseMutationOptions<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>;
export const GetUnitFloorplanDocument = gql`
  query GetUnitFloorplan($id: ID!) {
    unitFloorplan(id: $id) {
      ...UnitFloorplanFields
    }
  }
  ${UnitFloorplanFieldsFragmentDoc}
`;

/**
 * __useGetUnitFloorplanQuery__
 *
 * To run a query within a React component, call `useGetUnitFloorplanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitFloorplanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitFloorplanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnitFloorplanQuery(
  baseOptions: Apollo.QueryHookOptions<GetUnitFloorplanQuery, GetUnitFloorplanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnitFloorplanQuery, GetUnitFloorplanQueryVariables>(
    GetUnitFloorplanDocument,
    options
  );
}
export function useGetUnitFloorplanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUnitFloorplanQuery, GetUnitFloorplanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnitFloorplanQuery, GetUnitFloorplanQueryVariables>(
    GetUnitFloorplanDocument,
    options
  );
}
export type GetUnitFloorplanQueryHookResult = ReturnType<typeof useGetUnitFloorplanQuery>;
export type GetUnitFloorplanLazyQueryHookResult = ReturnType<typeof useGetUnitFloorplanLazyQuery>;
export type GetUnitFloorplanQueryResult = Apollo.QueryResult<
  GetUnitFloorplanQuery,
  GetUnitFloorplanQueryVariables
>;
export const QueryResidentsDocument = gql`
  query QueryResidents {
    residents {
      id
      profileId
      accountId
      documents {
        key
        name
        size
        createdZ
      }
    }
  }
`;

/**
 * __useQueryResidentsQuery__
 *
 * To run a query within a React component, call `useQueryResidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryResidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryResidentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryResidentsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryResidentsQuery, QueryResidentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryResidentsQuery, QueryResidentsQueryVariables>(
    QueryResidentsDocument,
    options
  );
}
export function useQueryResidentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryResidentsQuery, QueryResidentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryResidentsQuery, QueryResidentsQueryVariables>(
    QueryResidentsDocument,
    options
  );
}
export type QueryResidentsQueryHookResult = ReturnType<typeof useQueryResidentsQuery>;
export type QueryResidentsLazyQueryHookResult = ReturnType<typeof useQueryResidentsLazyQuery>;
export type QueryResidentsQueryResult = Apollo.QueryResult<
  QueryResidentsQuery,
  QueryResidentsQueryVariables
>;
export const GetDocumentLinkDocument = gql`
  query GetDocumentLink($residentId: ID!, $key: String!) {
    documentLink(residentId: $residentId, key: $key) {
      link
      error
    }
  }
`;

/**
 * __useGetDocumentLinkQuery__
 *
 * To run a query within a React component, call `useGetDocumentLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentLinkQuery({
 *   variables: {
 *      residentId: // value for 'residentId'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetDocumentLinkQuery(
  baseOptions: Apollo.QueryHookOptions<GetDocumentLinkQuery, GetDocumentLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDocumentLinkQuery, GetDocumentLinkQueryVariables>(
    GetDocumentLinkDocument,
    options
  );
}
export function useGetDocumentLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentLinkQuery, GetDocumentLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDocumentLinkQuery, GetDocumentLinkQueryVariables>(
    GetDocumentLinkDocument,
    options
  );
}
export type GetDocumentLinkQueryHookResult = ReturnType<typeof useGetDocumentLinkQuery>;
export type GetDocumentLinkLazyQueryHookResult = ReturnType<typeof useGetDocumentLinkLazyQuery>;
export type GetDocumentLinkQueryResult = Apollo.QueryResult<
  GetDocumentLinkQuery,
  GetDocumentLinkQueryVariables
>;
export const SignLeaseOfferDocument = gql`
  mutation SignLeaseOffer($input: SignLeaseOfferInput!) {
    signLeaseOffer(input: $input) {
      success
      error
      application {
        id
        leaseAgreement {
          ...LeaseAgreementFields
        }
      }
    }
  }
  ${LeaseAgreementFieldsFragmentDoc}
`;
export type SignLeaseOfferMutationFn = Apollo.MutationFunction<
  SignLeaseOfferMutation,
  SignLeaseOfferMutationVariables
>;

/**
 * __useSignLeaseOfferMutation__
 *
 * To run a mutation, you first call `useSignLeaseOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignLeaseOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signLeaseOfferMutation, { data, loading, error }] = useSignLeaseOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignLeaseOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<SignLeaseOfferMutation, SignLeaseOfferMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignLeaseOfferMutation, SignLeaseOfferMutationVariables>(
    SignLeaseOfferDocument,
    options
  );
}
export type SignLeaseOfferMutationHookResult = ReturnType<typeof useSignLeaseOfferMutation>;
export type SignLeaseOfferMutationResult = Apollo.MutationResult<SignLeaseOfferMutation>;
export type SignLeaseOfferMutationOptions = Apollo.BaseMutationOptions<
  SignLeaseOfferMutation,
  SignLeaseOfferMutationVariables
>;
export const CreateRequestDocument = gql`
  mutation CreateRequest($input: CreateRequestInput!) {
    createRequest(input: $input) {
      success
      error
      request {
        ...ProfileRequestFields
      }
    }
  }
  ${ProfileRequestFieldsFragmentDoc}
`;
export type CreateRequestMutationFn = Apollo.MutationFunction<
  CreateRequestMutation,
  CreateRequestMutationVariables
>;

/**
 * __useCreateRequestMutation__
 *
 * To run a mutation, you first call `useCreateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestMutation, { data, loading, error }] = useCreateRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRequestMutation, CreateRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRequestMutation, CreateRequestMutationVariables>(
    CreateRequestDocument,
    options
  );
}
export type CreateRequestMutationHookResult = ReturnType<typeof useCreateRequestMutation>;
export type CreateRequestMutationResult = Apollo.MutationResult<CreateRequestMutation>;
export type CreateRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateRequestMutation,
  CreateRequestMutationVariables
>;
export const FinalizeRequestDocument = gql`
  mutation FinalizeRequest($input: FinalizeRequestInput!) {
    finalizeRequest(input: $input) {
      success
      error
      request {
        ...ProfileRequestFields
      }
    }
  }
  ${ProfileRequestFieldsFragmentDoc}
`;
export type FinalizeRequestMutationFn = Apollo.MutationFunction<
  FinalizeRequestMutation,
  FinalizeRequestMutationVariables
>;

/**
 * __useFinalizeRequestMutation__
 *
 * To run a mutation, you first call `useFinalizeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeRequestMutation, { data, loading, error }] = useFinalizeRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinalizeRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalizeRequestMutation,
    FinalizeRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinalizeRequestMutation, FinalizeRequestMutationVariables>(
    FinalizeRequestDocument,
    options
  );
}
export type FinalizeRequestMutationHookResult = ReturnType<typeof useFinalizeRequestMutation>;
export type FinalizeRequestMutationResult = Apollo.MutationResult<FinalizeRequestMutation>;
export type FinalizeRequestMutationOptions = Apollo.BaseMutationOptions<
  FinalizeRequestMutation,
  FinalizeRequestMutationVariables
>;
export const GetRequestByIdDocument = gql`
  query GetRequestById($id: ID!, $tenantId: ID!) {
    requestById(id: $id, tenantId: $tenantId) {
      ...RequestDetailsFields
    }
  }
  ${RequestDetailsFieldsFragmentDoc}
`;

/**
 * __useGetRequestByIdQuery__
 *
 * To run a query within a React component, call `useGetRequestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetRequestByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetRequestByIdQuery, GetRequestByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRequestByIdQuery, GetRequestByIdQueryVariables>(
    GetRequestByIdDocument,
    options
  );
}
export function useGetRequestByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequestByIdQuery, GetRequestByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRequestByIdQuery, GetRequestByIdQueryVariables>(
    GetRequestByIdDocument,
    options
  );
}
export type GetRequestByIdQueryHookResult = ReturnType<typeof useGetRequestByIdQuery>;
export type GetRequestByIdLazyQueryHookResult = ReturnType<typeof useGetRequestByIdLazyQuery>;
export type GetRequestByIdQueryResult = Apollo.QueryResult<
  GetRequestByIdQuery,
  GetRequestByIdQueryVariables
>;
export const ApproveRequestDocument = gql`
  mutation ApproveRequest($input: ApproveRequestInput!) {
    approveMaintenanceRequest(input: $input) {
      success
      error
      request {
        ...RequestDetailsFields
      }
    }
  }
  ${RequestDetailsFieldsFragmentDoc}
`;
export type ApproveRequestMutationFn = Apollo.MutationFunction<
  ApproveRequestMutation,
  ApproveRequestMutationVariables
>;

/**
 * __useApproveRequestMutation__
 *
 * To run a mutation, you first call `useApproveRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveRequestMutation, { data, loading, error }] = useApproveRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<ApproveRequestMutation, ApproveRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApproveRequestMutation, ApproveRequestMutationVariables>(
    ApproveRequestDocument,
    options
  );
}
export type ApproveRequestMutationHookResult = ReturnType<typeof useApproveRequestMutation>;
export type ApproveRequestMutationResult = Apollo.MutationResult<ApproveRequestMutation>;
export type ApproveRequestMutationOptions = Apollo.BaseMutationOptions<
  ApproveRequestMutation,
  ApproveRequestMutationVariables
>;
export const DeclineRequestDocument = gql`
  mutation DeclineRequest($input: DeclineRequestInput!) {
    declineMaintenanceRequest(input: $input) {
      success
      error
      request {
        ...RequestDetailsFields
      }
    }
  }
  ${RequestDetailsFieldsFragmentDoc}
`;
export type DeclineRequestMutationFn = Apollo.MutationFunction<
  DeclineRequestMutation,
  DeclineRequestMutationVariables
>;

/**
 * __useDeclineRequestMutation__
 *
 * To run a mutation, you first call `useDeclineRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineRequestMutation, { data, loading, error }] = useDeclineRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<DeclineRequestMutation, DeclineRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeclineRequestMutation, DeclineRequestMutationVariables>(
    DeclineRequestDocument,
    options
  );
}
export type DeclineRequestMutationHookResult = ReturnType<typeof useDeclineRequestMutation>;
export type DeclineRequestMutationResult = Apollo.MutationResult<DeclineRequestMutation>;
export type DeclineRequestMutationOptions = Apollo.BaseMutationOptions<
  DeclineRequestMutation,
  DeclineRequestMutationVariables
>;
export const GetCategoryTemplateDocument = gql`
  query GetCategoryTemplate($accountId: ID!, $category: String!) {
    categoryTemplate(accountId: $accountId, category: $category) {
      ...TemplateFields
    }
  }
  ${TemplateFieldsFragmentDoc}
`;

/**
 * __useGetCategoryTemplateQuery__
 *
 * To run a query within a React component, call `useGetCategoryTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryTemplateQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetCategoryTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<GetCategoryTemplateQuery, GetCategoryTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCategoryTemplateQuery, GetCategoryTemplateQueryVariables>(
    GetCategoryTemplateDocument,
    options
  );
}
export function useGetCategoryTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCategoryTemplateQuery,
    GetCategoryTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCategoryTemplateQuery, GetCategoryTemplateQueryVariables>(
    GetCategoryTemplateDocument,
    options
  );
}
export type GetCategoryTemplateQueryHookResult = ReturnType<typeof useGetCategoryTemplateQuery>;
export type GetCategoryTemplateLazyQueryHookResult = ReturnType<
  typeof useGetCategoryTemplateLazyQuery
>;
export type GetCategoryTemplateQueryResult = Apollo.QueryResult<
  GetCategoryTemplateQuery,
  GetCategoryTemplateQueryVariables
>;
export const GetRequestsDocument = gql`
  query GetRequests($tenantId: ID!) {
    requests(tenantId: $tenantId) {
      ...RequestListFields
    }
  }
  ${RequestListFieldsFragmentDoc}
`;

/**
 * __useGetRequestsQuery__
 *
 * To run a query within a React component, call `useGetRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<GetRequestsQuery, GetRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRequestsQuery, GetRequestsQueryVariables>(GetRequestsDocument, options);
}
export function useGetRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequestsQuery, GetRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRequestsQuery, GetRequestsQueryVariables>(
    GetRequestsDocument,
    options
  );
}
export type GetRequestsQueryHookResult = ReturnType<typeof useGetRequestsQuery>;
export type GetRequestsLazyQueryHookResult = ReturnType<typeof useGetRequestsLazyQuery>;
export type GetRequestsQueryResult = Apollo.QueryResult<
  GetRequestsQuery,
  GetRequestsQueryVariables
>;
export const GetRequestsByPropertyDocument = gql`
  query GetRequestsByProperty($propertyId: ID!) {
    user {
      profile {
        id
        requestsByProperty(propertyId: $propertyId) {
          items {
            id
            firstOccurred
            category
            originalSubject
            createdZ
            originalRequest
            status
            name
            unit {
              name
              property {
                id
                name
                address {
                  street
                  city
                  province
                  postal
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetRequestsByPropertyQuery__
 *
 * To run a query within a React component, call `useGetRequestsByPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestsByPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestsByPropertyQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetRequestsByPropertyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRequestsByPropertyQuery,
    GetRequestsByPropertyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRequestsByPropertyQuery, GetRequestsByPropertyQueryVariables>(
    GetRequestsByPropertyDocument,
    options
  );
}
export function useGetRequestsByPropertyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRequestsByPropertyQuery,
    GetRequestsByPropertyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRequestsByPropertyQuery, GetRequestsByPropertyQueryVariables>(
    GetRequestsByPropertyDocument,
    options
  );
}
export type GetRequestsByPropertyQueryHookResult = ReturnType<typeof useGetRequestsByPropertyQuery>;
export type GetRequestsByPropertyLazyQueryHookResult = ReturnType<
  typeof useGetRequestsByPropertyLazyQuery
>;
export type GetRequestsByPropertyQueryResult = Apollo.QueryResult<
  GetRequestsByPropertyQuery,
  GetRequestsByPropertyQueryVariables
>;
export const GetProfileRequestDocument = gql`
  query GetProfileRequest($id: ID!) {
    user {
      profile {
        id
        request(id: $id) {
          accountId
          id
          approval {
            id
            amount
            status
            description
            approvedComment
            approvedZ
            declinedComment
            declinedZ
          }
          firstOccurred
          category
          name
          originalSubject
          originalRequest
          status
          costLines {
            id
            amount
            visitNumber
            markup
            description
            total
          }
          calculatedTaxes {
            label
            amount
          }
          taxExempted
          resolution
          ownerId
          visits {
            ...ProfileRequestVisitFields
          }
          unit {
            name
            ownerId
            property {
              ownerId
              name
              phone
              key
              address {
                street
                city
                province
                postal
              }
              account {
                name
                phone
              }
            }
          }
        }
      }
    }
  }
  ${ProfileRequestVisitFieldsFragmentDoc}
`;

/**
 * __useGetProfileRequestQuery__
 *
 * To run a query within a React component, call `useGetProfileRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileRequestQuery(
  baseOptions: Apollo.QueryHookOptions<GetProfileRequestQuery, GetProfileRequestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileRequestQuery, GetProfileRequestQueryVariables>(
    GetProfileRequestDocument,
    options
  );
}
export function useGetProfileRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProfileRequestQuery, GetProfileRequestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileRequestQuery, GetProfileRequestQueryVariables>(
    GetProfileRequestDocument,
    options
  );
}
export type GetProfileRequestQueryHookResult = ReturnType<typeof useGetProfileRequestQuery>;
export type GetProfileRequestLazyQueryHookResult = ReturnType<typeof useGetProfileRequestLazyQuery>;
export type GetProfileRequestQueryResult = Apollo.QueryResult<
  GetProfileRequestQuery,
  GetProfileRequestQueryVariables
>;
export const ListProfileRequestsDocument = gql`
  query ListProfileRequests($nextToken: String) {
    user {
      profile {
        id
        listRequests(nextToken: $nextToken) {
          nextToken
          items {
            id
            firstOccurred
            category
            name
            originalSubject
            originalRequest
            status
            accountId
            unit {
              name
              property {
                id
                name
                phone
                key
                imageKey
                address {
                  street
                  city
                  province
                  postal
                }
              }
            }
            visits {
              ...ProfileRequestVisitFields
            }
            createdZ
          }
        }
      }
    }
  }
  ${ProfileRequestVisitFieldsFragmentDoc}
`;

/**
 * __useListProfileRequestsQuery__
 *
 * To run a query within a React component, call `useListProfileRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProfileRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProfileRequestsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListProfileRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListProfileRequestsQuery, ListProfileRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListProfileRequestsQuery, ListProfileRequestsQueryVariables>(
    ListProfileRequestsDocument,
    options
  );
}
export function useListProfileRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProfileRequestsQuery,
    ListProfileRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListProfileRequestsQuery, ListProfileRequestsQueryVariables>(
    ListProfileRequestsDocument,
    options
  );
}
export type ListProfileRequestsQueryHookResult = ReturnType<typeof useListProfileRequestsQuery>;
export type ListProfileRequestsLazyQueryHookResult = ReturnType<
  typeof useListProfileRequestsLazyQuery
>;
export type ListProfileRequestsQueryResult = Apollo.QueryResult<
  ListProfileRequestsQuery,
  ListProfileRequestsQueryVariables
>;
export const GetAccountSettingsDocument = gql`
  query GetAccountSettings($id: ID!) {
    accountSettings(id: $id) {
      ...AccountSettingsFields
    }
  }
  ${AccountSettingsFieldsFragmentDoc}
`;

/**
 * __useGetAccountSettingsQuery__
 *
 * To run a query within a React component, call `useGetAccountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountSettingsQuery, GetAccountSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountSettingsQuery, GetAccountSettingsQueryVariables>(
    GetAccountSettingsDocument,
    options
  );
}
export function useGetAccountSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountSettingsQuery,
    GetAccountSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountSettingsQuery, GetAccountSettingsQueryVariables>(
    GetAccountSettingsDocument,
    options
  );
}
export type GetAccountSettingsQueryHookResult = ReturnType<typeof useGetAccountSettingsQuery>;
export type GetAccountSettingsLazyQueryHookResult = ReturnType<
  typeof useGetAccountSettingsLazyQuery
>;
export type GetAccountSettingsQueryResult = Apollo.QueryResult<
  GetAccountSettingsQuery,
  GetAccountSettingsQueryVariables
>;
export const CreateApplicationDocument = gql`
  mutation CreateApplication($input: CreateApplicationInput!) {
    createApplication(input: $input) {
      success
      error
      application {
        ...ApplicationFields
      }
    }
  }
  ${ApplicationFieldsFragmentDoc}
`;
export type CreateApplicationMutationFn = Apollo.MutationFunction<
  CreateApplicationMutation,
  CreateApplicationMutationVariables
>;

/**
 * __useCreateApplicationMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationMutation, { data, loading, error }] = useCreateApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApplicationMutation,
    CreateApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateApplicationMutation, CreateApplicationMutationVariables>(
    CreateApplicationDocument,
    options
  );
}
export type CreateApplicationMutationHookResult = ReturnType<typeof useCreateApplicationMutation>;
export type CreateApplicationMutationResult = Apollo.MutationResult<CreateApplicationMutation>;
export type CreateApplicationMutationOptions = Apollo.BaseMutationOptions<
  CreateApplicationMutation,
  CreateApplicationMutationVariables
>;
export const UpdateAdditionalTenantsDocument = gql`
  mutation UpdateAdditionalTenants($id: ID!, $input: UpdateAdditionalTenantsInput!) {
    updateAdditionalTenants(id: $id, input: $input) {
      success
      error
      application {
        ...ApplicationFields
      }
    }
  }
  ${ApplicationFieldsFragmentDoc}
`;
export type UpdateAdditionalTenantsMutationFn = Apollo.MutationFunction<
  UpdateAdditionalTenantsMutation,
  UpdateAdditionalTenantsMutationVariables
>;

/**
 * __useUpdateAdditionalTenantsMutation__
 *
 * To run a mutation, you first call `useUpdateAdditionalTenantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdditionalTenantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdditionalTenantsMutation, { data, loading, error }] = useUpdateAdditionalTenantsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdditionalTenantsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdditionalTenantsMutation,
    UpdateAdditionalTenantsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAdditionalTenantsMutation,
    UpdateAdditionalTenantsMutationVariables
  >(UpdateAdditionalTenantsDocument, options);
}
export type UpdateAdditionalTenantsMutationHookResult = ReturnType<
  typeof useUpdateAdditionalTenantsMutation
>;
export type UpdateAdditionalTenantsMutationResult =
  Apollo.MutationResult<UpdateAdditionalTenantsMutation>;
export type UpdateAdditionalTenantsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdditionalTenantsMutation,
  UpdateAdditionalTenantsMutationVariables
>;
export const GetProfileDocument = gql`
  query GetProfile {
    user {
      profile {
        ...ProfileFields
      }
    }
  }
  ${ProfileFieldsFragmentDoc}
`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
}
export function useGetProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    options
  );
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const UpdateProfileDocument = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    user {
      updateProfile(input: $input) {
        success
        error
        profile {
          ...ProfileFields
        }
      }
    }
  }
  ${ProfileFieldsFragmentDoc}
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(
    UpdateProfileDocument,
    options
  );
}
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const AddProfileWorkHistoryDocument = gql`
  mutation AddProfileWorkHistory($input: AddWorkHistoryInput!) {
    user {
      addWorkHistory(input: $input) {
        success
        error
        profile {
          id
          workHistory {
            ...WorkHistoryFields
          }
        }
      }
    }
  }
  ${WorkHistoryFieldsFragmentDoc}
`;
export type AddProfileWorkHistoryMutationFn = Apollo.MutationFunction<
  AddProfileWorkHistoryMutation,
  AddProfileWorkHistoryMutationVariables
>;

/**
 * __useAddProfileWorkHistoryMutation__
 *
 * To run a mutation, you first call `useAddProfileWorkHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProfileWorkHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProfileWorkHistoryMutation, { data, loading, error }] = useAddProfileWorkHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProfileWorkHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProfileWorkHistoryMutation,
    AddProfileWorkHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddProfileWorkHistoryMutation, AddProfileWorkHistoryMutationVariables>(
    AddProfileWorkHistoryDocument,
    options
  );
}
export type AddProfileWorkHistoryMutationHookResult = ReturnType<
  typeof useAddProfileWorkHistoryMutation
>;
export type AddProfileWorkHistoryMutationResult =
  Apollo.MutationResult<AddProfileWorkHistoryMutation>;
export type AddProfileWorkHistoryMutationOptions = Apollo.BaseMutationOptions<
  AddProfileWorkHistoryMutation,
  AddProfileWorkHistoryMutationVariables
>;
export const EditProfileWorkHistoryDocument = gql`
  mutation EditProfileWorkHistory($input: UpdateWorkHistoryInput!) {
    user {
      editWorkHistory(input: $input) {
        success
        error
        profile {
          id
          workHistory {
            ...WorkHistoryFields
          }
        }
      }
    }
  }
  ${WorkHistoryFieldsFragmentDoc}
`;
export type EditProfileWorkHistoryMutationFn = Apollo.MutationFunction<
  EditProfileWorkHistoryMutation,
  EditProfileWorkHistoryMutationVariables
>;

/**
 * __useEditProfileWorkHistoryMutation__
 *
 * To run a mutation, you first call `useEditProfileWorkHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProfileWorkHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProfileWorkHistoryMutation, { data, loading, error }] = useEditProfileWorkHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditProfileWorkHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditProfileWorkHistoryMutation,
    EditProfileWorkHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditProfileWorkHistoryMutation,
    EditProfileWorkHistoryMutationVariables
  >(EditProfileWorkHistoryDocument, options);
}
export type EditProfileWorkHistoryMutationHookResult = ReturnType<
  typeof useEditProfileWorkHistoryMutation
>;
export type EditProfileWorkHistoryMutationResult =
  Apollo.MutationResult<EditProfileWorkHistoryMutation>;
export type EditProfileWorkHistoryMutationOptions = Apollo.BaseMutationOptions<
  EditProfileWorkHistoryMutation,
  EditProfileWorkHistoryMutationVariables
>;
export const DeleteProfileWorkHistoryDocument = gql`
  mutation DeleteProfileWorkHistory($id: ID!) {
    user {
      deleteWorkHistory(id: $id) {
        success
        error
        profile {
          id
          workHistory {
            ...WorkHistoryFields
          }
        }
      }
    }
  }
  ${WorkHistoryFieldsFragmentDoc}
`;
export type DeleteProfileWorkHistoryMutationFn = Apollo.MutationFunction<
  DeleteProfileWorkHistoryMutation,
  DeleteProfileWorkHistoryMutationVariables
>;

/**
 * __useDeleteProfileWorkHistoryMutation__
 *
 * To run a mutation, you first call `useDeleteProfileWorkHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfileWorkHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfileWorkHistoryMutation, { data, loading, error }] = useDeleteProfileWorkHistoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProfileWorkHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProfileWorkHistoryMutation,
    DeleteProfileWorkHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProfileWorkHistoryMutation,
    DeleteProfileWorkHistoryMutationVariables
  >(DeleteProfileWorkHistoryDocument, options);
}
export type DeleteProfileWorkHistoryMutationHookResult = ReturnType<
  typeof useDeleteProfileWorkHistoryMutation
>;
export type DeleteProfileWorkHistoryMutationResult =
  Apollo.MutationResult<DeleteProfileWorkHistoryMutation>;
export type DeleteProfileWorkHistoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteProfileWorkHistoryMutation,
  DeleteProfileWorkHistoryMutationVariables
>;
export const SaveNoWorkHistoryDocument = gql`
  mutation SaveNoWorkHistory {
    user {
      saveNoWorkHistory {
        success
        error
        profile {
          id
          workHistory {
            ...WorkHistoryFields
          }
        }
      }
    }
  }
  ${WorkHistoryFieldsFragmentDoc}
`;
export type SaveNoWorkHistoryMutationFn = Apollo.MutationFunction<
  SaveNoWorkHistoryMutation,
  SaveNoWorkHistoryMutationVariables
>;

/**
 * __useSaveNoWorkHistoryMutation__
 *
 * To run a mutation, you first call `useSaveNoWorkHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNoWorkHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNoWorkHistoryMutation, { data, loading, error }] = useSaveNoWorkHistoryMutation({
 *   variables: {
 *   },
 * });
 */
export function useSaveNoWorkHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveNoWorkHistoryMutation,
    SaveNoWorkHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveNoWorkHistoryMutation, SaveNoWorkHistoryMutationVariables>(
    SaveNoWorkHistoryDocument,
    options
  );
}
export type SaveNoWorkHistoryMutationHookResult = ReturnType<typeof useSaveNoWorkHistoryMutation>;
export type SaveNoWorkHistoryMutationResult = Apollo.MutationResult<SaveNoWorkHistoryMutation>;
export type SaveNoWorkHistoryMutationOptions = Apollo.BaseMutationOptions<
  SaveNoWorkHistoryMutation,
  SaveNoWorkHistoryMutationVariables
>;
export const AddProfileAddressHistoryDocument = gql`
  mutation AddProfileAddressHistory($input: AddAddressHistoryInput!) {
    user {
      addAddressHistory(input: $input) {
        success
        error
        profile {
          id
          addressHistory {
            ...AddressHistoryFields
          }
        }
      }
    }
  }
  ${AddressHistoryFieldsFragmentDoc}
`;
export type AddProfileAddressHistoryMutationFn = Apollo.MutationFunction<
  AddProfileAddressHistoryMutation,
  AddProfileAddressHistoryMutationVariables
>;

/**
 * __useAddProfileAddressHistoryMutation__
 *
 * To run a mutation, you first call `useAddProfileAddressHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProfileAddressHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProfileAddressHistoryMutation, { data, loading, error }] = useAddProfileAddressHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProfileAddressHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProfileAddressHistoryMutation,
    AddProfileAddressHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProfileAddressHistoryMutation,
    AddProfileAddressHistoryMutationVariables
  >(AddProfileAddressHistoryDocument, options);
}
export type AddProfileAddressHistoryMutationHookResult = ReturnType<
  typeof useAddProfileAddressHistoryMutation
>;
export type AddProfileAddressHistoryMutationResult =
  Apollo.MutationResult<AddProfileAddressHistoryMutation>;
export type AddProfileAddressHistoryMutationOptions = Apollo.BaseMutationOptions<
  AddProfileAddressHistoryMutation,
  AddProfileAddressHistoryMutationVariables
>;
export const EditProfileAddressHistoryDocument = gql`
  mutation EditProfileAddressHistory($input: UpdateAddressHistoryInput!) {
    user {
      editAddressHistory(input: $input) {
        success
        error
        profile {
          id
          addressHistory {
            ...AddressHistoryFields
          }
        }
      }
    }
  }
  ${AddressHistoryFieldsFragmentDoc}
`;
export type EditProfileAddressHistoryMutationFn = Apollo.MutationFunction<
  EditProfileAddressHistoryMutation,
  EditProfileAddressHistoryMutationVariables
>;

/**
 * __useEditProfileAddressHistoryMutation__
 *
 * To run a mutation, you first call `useEditProfileAddressHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProfileAddressHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProfileAddressHistoryMutation, { data, loading, error }] = useEditProfileAddressHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditProfileAddressHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditProfileAddressHistoryMutation,
    EditProfileAddressHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditProfileAddressHistoryMutation,
    EditProfileAddressHistoryMutationVariables
  >(EditProfileAddressHistoryDocument, options);
}
export type EditProfileAddressHistoryMutationHookResult = ReturnType<
  typeof useEditProfileAddressHistoryMutation
>;
export type EditProfileAddressHistoryMutationResult =
  Apollo.MutationResult<EditProfileAddressHistoryMutation>;
export type EditProfileAddressHistoryMutationOptions = Apollo.BaseMutationOptions<
  EditProfileAddressHistoryMutation,
  EditProfileAddressHistoryMutationVariables
>;
export const DeleteProfileAddressHistoryDocument = gql`
  mutation DeleteProfileAddressHistory($id: ID!) {
    user {
      deleteAddressHistory(id: $id) {
        success
        error
        profile {
          id
          addressHistory {
            ...AddressHistoryFields
          }
        }
      }
    }
  }
  ${AddressHistoryFieldsFragmentDoc}
`;
export type DeleteProfileAddressHistoryMutationFn = Apollo.MutationFunction<
  DeleteProfileAddressHistoryMutation,
  DeleteProfileAddressHistoryMutationVariables
>;

/**
 * __useDeleteProfileAddressHistoryMutation__
 *
 * To run a mutation, you first call `useDeleteProfileAddressHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfileAddressHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfileAddressHistoryMutation, { data, loading, error }] = useDeleteProfileAddressHistoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProfileAddressHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProfileAddressHistoryMutation,
    DeleteProfileAddressHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProfileAddressHistoryMutation,
    DeleteProfileAddressHistoryMutationVariables
  >(DeleteProfileAddressHistoryDocument, options);
}
export type DeleteProfileAddressHistoryMutationHookResult = ReturnType<
  typeof useDeleteProfileAddressHistoryMutation
>;
export type DeleteProfileAddressHistoryMutationResult =
  Apollo.MutationResult<DeleteProfileAddressHistoryMutation>;
export type DeleteProfileAddressHistoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteProfileAddressHistoryMutation,
  DeleteProfileAddressHistoryMutationVariables
>;
export const SaveNoAddressHistoryDocument = gql`
  mutation SaveNoAddressHistory {
    user {
      saveNoAddressHistory {
        success
        error
        profile {
          id
          addressHistory {
            ...AddressHistoryFields
          }
        }
      }
    }
  }
  ${AddressHistoryFieldsFragmentDoc}
`;
export type SaveNoAddressHistoryMutationFn = Apollo.MutationFunction<
  SaveNoAddressHistoryMutation,
  SaveNoAddressHistoryMutationVariables
>;

/**
 * __useSaveNoAddressHistoryMutation__
 *
 * To run a mutation, you first call `useSaveNoAddressHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNoAddressHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNoAddressHistoryMutation, { data, loading, error }] = useSaveNoAddressHistoryMutation({
 *   variables: {
 *   },
 * });
 */
export function useSaveNoAddressHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveNoAddressHistoryMutation,
    SaveNoAddressHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveNoAddressHistoryMutation, SaveNoAddressHistoryMutationVariables>(
    SaveNoAddressHistoryDocument,
    options
  );
}
export type SaveNoAddressHistoryMutationHookResult = ReturnType<
  typeof useSaveNoAddressHistoryMutation
>;
export type SaveNoAddressHistoryMutationResult =
  Apollo.MutationResult<SaveNoAddressHistoryMutation>;
export type SaveNoAddressHistoryMutationOptions = Apollo.BaseMutationOptions<
  SaveNoAddressHistoryMutation,
  SaveNoAddressHistoryMutationVariables
>;
export const SaveNoAdditionalTenantsDocument = gql`
  mutation SaveNoAdditionalTenants {
    user {
      saveNoAdditionalTenants {
        success
        error
        profile {
          id
          household {
            ...HouseholdFields
          }
        }
      }
    }
  }
  ${HouseholdFieldsFragmentDoc}
`;
export type SaveNoAdditionalTenantsMutationFn = Apollo.MutationFunction<
  SaveNoAdditionalTenantsMutation,
  SaveNoAdditionalTenantsMutationVariables
>;

/**
 * __useSaveNoAdditionalTenantsMutation__
 *
 * To run a mutation, you first call `useSaveNoAdditionalTenantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNoAdditionalTenantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNoAdditionalTenantsMutation, { data, loading, error }] = useSaveNoAdditionalTenantsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSaveNoAdditionalTenantsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveNoAdditionalTenantsMutation,
    SaveNoAdditionalTenantsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveNoAdditionalTenantsMutation,
    SaveNoAdditionalTenantsMutationVariables
  >(SaveNoAdditionalTenantsDocument, options);
}
export type SaveNoAdditionalTenantsMutationHookResult = ReturnType<
  typeof useSaveNoAdditionalTenantsMutation
>;
export type SaveNoAdditionalTenantsMutationResult =
  Apollo.MutationResult<SaveNoAdditionalTenantsMutation>;
export type SaveNoAdditionalTenantsMutationOptions = Apollo.BaseMutationOptions<
  SaveNoAdditionalTenantsMutation,
  SaveNoAdditionalTenantsMutationVariables
>;
export const AddAdditionalTenantDocument = gql`
  mutation AddAdditionalTenant($input: AddAdditionalTenantInput!) {
    user {
      addAdditionalTenant(input: $input) {
        success
        error
        profile {
          id
          household {
            ...HouseholdFields
          }
        }
      }
    }
  }
  ${HouseholdFieldsFragmentDoc}
`;
export type AddAdditionalTenantMutationFn = Apollo.MutationFunction<
  AddAdditionalTenantMutation,
  AddAdditionalTenantMutationVariables
>;

/**
 * __useAddAdditionalTenantMutation__
 *
 * To run a mutation, you first call `useAddAdditionalTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdditionalTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdditionalTenantMutation, { data, loading, error }] = useAddAdditionalTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAdditionalTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAdditionalTenantMutation,
    AddAdditionalTenantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAdditionalTenantMutation, AddAdditionalTenantMutationVariables>(
    AddAdditionalTenantDocument,
    options
  );
}
export type AddAdditionalTenantMutationHookResult = ReturnType<
  typeof useAddAdditionalTenantMutation
>;
export type AddAdditionalTenantMutationResult = Apollo.MutationResult<AddAdditionalTenantMutation>;
export type AddAdditionalTenantMutationOptions = Apollo.BaseMutationOptions<
  AddAdditionalTenantMutation,
  AddAdditionalTenantMutationVariables
>;
export const EditAdditionalTenantDocument = gql`
  mutation EditAdditionalTenant($input: UpdateAdditionalTenantInput!) {
    user {
      editAdditionalTenant(input: $input) {
        success
        error
        profile {
          id
          household {
            ...HouseholdFields
          }
        }
      }
    }
  }
  ${HouseholdFieldsFragmentDoc}
`;
export type EditAdditionalTenantMutationFn = Apollo.MutationFunction<
  EditAdditionalTenantMutation,
  EditAdditionalTenantMutationVariables
>;

/**
 * __useEditAdditionalTenantMutation__
 *
 * To run a mutation, you first call `useEditAdditionalTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAdditionalTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAdditionalTenantMutation, { data, loading, error }] = useEditAdditionalTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAdditionalTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditAdditionalTenantMutation,
    EditAdditionalTenantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditAdditionalTenantMutation, EditAdditionalTenantMutationVariables>(
    EditAdditionalTenantDocument,
    options
  );
}
export type EditAdditionalTenantMutationHookResult = ReturnType<
  typeof useEditAdditionalTenantMutation
>;
export type EditAdditionalTenantMutationResult =
  Apollo.MutationResult<EditAdditionalTenantMutation>;
export type EditAdditionalTenantMutationOptions = Apollo.BaseMutationOptions<
  EditAdditionalTenantMutation,
  EditAdditionalTenantMutationVariables
>;
export const DeleteAdditionalTenantDocument = gql`
  mutation DeleteAdditionalTenant($id: ID!) {
    user {
      deleteAdditionalTenant(id: $id) {
        success
        error
        profile {
          id
          household {
            ...HouseholdFields
          }
        }
      }
    }
  }
  ${HouseholdFieldsFragmentDoc}
`;
export type DeleteAdditionalTenantMutationFn = Apollo.MutationFunction<
  DeleteAdditionalTenantMutation,
  DeleteAdditionalTenantMutationVariables
>;

/**
 * __useDeleteAdditionalTenantMutation__
 *
 * To run a mutation, you first call `useDeleteAdditionalTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdditionalTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdditionalTenantMutation, { data, loading, error }] = useDeleteAdditionalTenantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdditionalTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAdditionalTenantMutation,
    DeleteAdditionalTenantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAdditionalTenantMutation,
    DeleteAdditionalTenantMutationVariables
  >(DeleteAdditionalTenantDocument, options);
}
export type DeleteAdditionalTenantMutationHookResult = ReturnType<
  typeof useDeleteAdditionalTenantMutation
>;
export type DeleteAdditionalTenantMutationResult =
  Apollo.MutationResult<DeleteAdditionalTenantMutation>;
export type DeleteAdditionalTenantMutationOptions = Apollo.BaseMutationOptions<
  DeleteAdditionalTenantMutation,
  DeleteAdditionalTenantMutationVariables
>;
export const SaveNoPetsDocument = gql`
  mutation SaveNoPets {
    user {
      saveNoPets {
        success
        error
        profile {
          id
          household {
            ...HouseholdFields
          }
        }
      }
    }
  }
  ${HouseholdFieldsFragmentDoc}
`;
export type SaveNoPetsMutationFn = Apollo.MutationFunction<
  SaveNoPetsMutation,
  SaveNoPetsMutationVariables
>;

/**
 * __useSaveNoPetsMutation__
 *
 * To run a mutation, you first call `useSaveNoPetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNoPetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNoPetsMutation, { data, loading, error }] = useSaveNoPetsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSaveNoPetsMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveNoPetsMutation, SaveNoPetsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveNoPetsMutation, SaveNoPetsMutationVariables>(
    SaveNoPetsDocument,
    options
  );
}
export type SaveNoPetsMutationHookResult = ReturnType<typeof useSaveNoPetsMutation>;
export type SaveNoPetsMutationResult = Apollo.MutationResult<SaveNoPetsMutation>;
export type SaveNoPetsMutationOptions = Apollo.BaseMutationOptions<
  SaveNoPetsMutation,
  SaveNoPetsMutationVariables
>;
export const UpdatePetsDocument = gql`
  mutation UpdatePets($input: [PetInput!]) {
    user {
      updatePets(input: $input) {
        success
        error
        profile {
          id
          household {
            ...HouseholdFields
          }
        }
      }
    }
  }
  ${HouseholdFieldsFragmentDoc}
`;
export type UpdatePetsMutationFn = Apollo.MutationFunction<
  UpdatePetsMutation,
  UpdatePetsMutationVariables
>;

/**
 * __useUpdatePetsMutation__
 *
 * To run a mutation, you first call `useUpdatePetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePetsMutation, { data, loading, error }] = useUpdatePetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePetsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePetsMutation, UpdatePetsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePetsMutation, UpdatePetsMutationVariables>(
    UpdatePetsDocument,
    options
  );
}
export type UpdatePetsMutationHookResult = ReturnType<typeof useUpdatePetsMutation>;
export type UpdatePetsMutationResult = Apollo.MutationResult<UpdatePetsMutation>;
export type UpdatePetsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePetsMutation,
  UpdatePetsMutationVariables
>;
export const SaveNoVehiclesDocument = gql`
  mutation SaveNoVehicles {
    user {
      saveNoVehicles {
        success
        error
        profile {
          id
          household {
            ...HouseholdFields
          }
        }
      }
    }
  }
  ${HouseholdFieldsFragmentDoc}
`;
export type SaveNoVehiclesMutationFn = Apollo.MutationFunction<
  SaveNoVehiclesMutation,
  SaveNoVehiclesMutationVariables
>;

/**
 * __useSaveNoVehiclesMutation__
 *
 * To run a mutation, you first call `useSaveNoVehiclesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNoVehiclesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNoVehiclesMutation, { data, loading, error }] = useSaveNoVehiclesMutation({
 *   variables: {
 *   },
 * });
 */
export function useSaveNoVehiclesMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveNoVehiclesMutation, SaveNoVehiclesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveNoVehiclesMutation, SaveNoVehiclesMutationVariables>(
    SaveNoVehiclesDocument,
    options
  );
}
export type SaveNoVehiclesMutationHookResult = ReturnType<typeof useSaveNoVehiclesMutation>;
export type SaveNoVehiclesMutationResult = Apollo.MutationResult<SaveNoVehiclesMutation>;
export type SaveNoVehiclesMutationOptions = Apollo.BaseMutationOptions<
  SaveNoVehiclesMutation,
  SaveNoVehiclesMutationVariables
>;
export const AddVehicleDocument = gql`
  mutation AddVehicle($input: AddVehicleInput!) {
    user {
      addVehicle(input: $input) {
        success
        error
        profile {
          id
          household {
            ...HouseholdFields
          }
        }
      }
    }
  }
  ${HouseholdFieldsFragmentDoc}
`;
export type AddVehicleMutationFn = Apollo.MutationFunction<
  AddVehicleMutation,
  AddVehicleMutationVariables
>;

/**
 * __useAddVehicleMutation__
 *
 * To run a mutation, you first call `useAddVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVehicleMutation, { data, loading, error }] = useAddVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<AddVehicleMutation, AddVehicleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddVehicleMutation, AddVehicleMutationVariables>(
    AddVehicleDocument,
    options
  );
}
export type AddVehicleMutationHookResult = ReturnType<typeof useAddVehicleMutation>;
export type AddVehicleMutationResult = Apollo.MutationResult<AddVehicleMutation>;
export type AddVehicleMutationOptions = Apollo.BaseMutationOptions<
  AddVehicleMutation,
  AddVehicleMutationVariables
>;
export const EditVehicleDocument = gql`
  mutation EditVehicle($input: UpdateVehicleInput!) {
    user {
      editVehicle(input: $input) {
        success
        error
        profile {
          id
          household {
            ...HouseholdFields
          }
        }
      }
    }
  }
  ${HouseholdFieldsFragmentDoc}
`;
export type EditVehicleMutationFn = Apollo.MutationFunction<
  EditVehicleMutation,
  EditVehicleMutationVariables
>;

/**
 * __useEditVehicleMutation__
 *
 * To run a mutation, you first call `useEditVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVehicleMutation, { data, loading, error }] = useEditVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<EditVehicleMutation, EditVehicleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditVehicleMutation, EditVehicleMutationVariables>(
    EditVehicleDocument,
    options
  );
}
export type EditVehicleMutationHookResult = ReturnType<typeof useEditVehicleMutation>;
export type EditVehicleMutationResult = Apollo.MutationResult<EditVehicleMutation>;
export type EditVehicleMutationOptions = Apollo.BaseMutationOptions<
  EditVehicleMutation,
  EditVehicleMutationVariables
>;
export const DeleteVehicleDocument = gql`
  mutation DeleteVehicle($id: ID!) {
    user {
      deleteVehicle(id: $id) {
        success
        error
        profile {
          id
          household {
            ...HouseholdFields
          }
        }
      }
    }
  }
  ${HouseholdFieldsFragmentDoc}
`;
export type DeleteVehicleMutationFn = Apollo.MutationFunction<
  DeleteVehicleMutation,
  DeleteVehicleMutationVariables
>;

/**
 * __useDeleteVehicleMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleMutation, { data, loading, error }] = useDeleteVehicleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVehicleMutation, DeleteVehicleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVehicleMutation, DeleteVehicleMutationVariables>(
    DeleteVehicleDocument,
    options
  );
}
export type DeleteVehicleMutationHookResult = ReturnType<typeof useDeleteVehicleMutation>;
export type DeleteVehicleMutationResult = Apollo.MutationResult<DeleteVehicleMutation>;
export type DeleteVehicleMutationOptions = Apollo.BaseMutationOptions<
  DeleteVehicleMutation,
  DeleteVehicleMutationVariables
>;
export const UpdateProfileConsentsDocument = gql`
  mutation UpdateProfileConsents($input: UpdateProfileConsentsInput!) {
    user {
      updateProfileConsents(input: $input) {
        success
        error
        profile {
          ...ProfileFields
        }
      }
    }
  }
  ${ProfileFieldsFragmentDoc}
`;
export type UpdateProfileConsentsMutationFn = Apollo.MutationFunction<
  UpdateProfileConsentsMutation,
  UpdateProfileConsentsMutationVariables
>;

/**
 * __useUpdateProfileConsentsMutation__
 *
 * To run a mutation, you first call `useUpdateProfileConsentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileConsentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileConsentsMutation, { data, loading, error }] = useUpdateProfileConsentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileConsentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileConsentsMutation,
    UpdateProfileConsentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProfileConsentsMutation, UpdateProfileConsentsMutationVariables>(
    UpdateProfileConsentsDocument,
    options
  );
}
export type UpdateProfileConsentsMutationHookResult = ReturnType<
  typeof useUpdateProfileConsentsMutation
>;
export type UpdateProfileConsentsMutationResult =
  Apollo.MutationResult<UpdateProfileConsentsMutation>;
export type UpdateProfileConsentsMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileConsentsMutation,
  UpdateProfileConsentsMutationVariables
>;
export const AddBankAccountDocument = gql`
  mutation AddBankAccount($input: AddBankAccountInput!) {
    user {
      addBankAccount(input: $input) {
        success
        error
        profile {
          ...ProfileFields
        }
      }
    }
  }
  ${ProfileFieldsFragmentDoc}
`;
export type AddBankAccountMutationFn = Apollo.MutationFunction<
  AddBankAccountMutation,
  AddBankAccountMutationVariables
>;

/**
 * __useAddBankAccountMutation__
 *
 * To run a mutation, you first call `useAddBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBankAccountMutation, { data, loading, error }] = useAddBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<AddBankAccountMutation, AddBankAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddBankAccountMutation, AddBankAccountMutationVariables>(
    AddBankAccountDocument,
    options
  );
}
export type AddBankAccountMutationHookResult = ReturnType<typeof useAddBankAccountMutation>;
export type AddBankAccountMutationResult = Apollo.MutationResult<AddBankAccountMutation>;
export type AddBankAccountMutationOptions = Apollo.BaseMutationOptions<
  AddBankAccountMutation,
  AddBankAccountMutationVariables
>;
export const DeleteBankAccountDocument = gql`
  mutation DeleteBankAccount($id: ID!) {
    user {
      deleteBankAccount(id: $id) {
        success
        error
        profile {
          ...ProfileFields
        }
      }
    }
  }
  ${ProfileFieldsFragmentDoc}
`;
export type DeleteBankAccountMutationFn = Apollo.MutationFunction<
  DeleteBankAccountMutation,
  DeleteBankAccountMutationVariables
>;

/**
 * __useDeleteBankAccountMutation__
 *
 * To run a mutation, you first call `useDeleteBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBankAccountMutation, { data, loading, error }] = useDeleteBankAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBankAccountMutation,
    DeleteBankAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>(
    DeleteBankAccountDocument,
    options
  );
}
export type DeleteBankAccountMutationHookResult = ReturnType<typeof useDeleteBankAccountMutation>;
export type DeleteBankAccountMutationResult = Apollo.MutationResult<DeleteBankAccountMutation>;
export type DeleteBankAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteBankAccountMutation,
  DeleteBankAccountMutationVariables
>;
export const AddProfileOtherIncomeDocument = gql`
  mutation AddProfileOtherIncome($input: AddOtherIncomeInput!) {
    user {
      addOtherIncome(input: $input) {
        success
        error
        profile {
          id
          otherIncome {
            ...OtherIncomeFields
          }
        }
      }
    }
  }
  ${OtherIncomeFieldsFragmentDoc}
`;
export type AddProfileOtherIncomeMutationFn = Apollo.MutationFunction<
  AddProfileOtherIncomeMutation,
  AddProfileOtherIncomeMutationVariables
>;

/**
 * __useAddProfileOtherIncomeMutation__
 *
 * To run a mutation, you first call `useAddProfileOtherIncomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProfileOtherIncomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProfileOtherIncomeMutation, { data, loading, error }] = useAddProfileOtherIncomeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProfileOtherIncomeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProfileOtherIncomeMutation,
    AddProfileOtherIncomeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddProfileOtherIncomeMutation, AddProfileOtherIncomeMutationVariables>(
    AddProfileOtherIncomeDocument,
    options
  );
}
export type AddProfileOtherIncomeMutationHookResult = ReturnType<
  typeof useAddProfileOtherIncomeMutation
>;
export type AddProfileOtherIncomeMutationResult =
  Apollo.MutationResult<AddProfileOtherIncomeMutation>;
export type AddProfileOtherIncomeMutationOptions = Apollo.BaseMutationOptions<
  AddProfileOtherIncomeMutation,
  AddProfileOtherIncomeMutationVariables
>;
export const EditProfileOtherIncomeDocument = gql`
  mutation EditProfileOtherIncome($input: UpdateOtherIncomeInput!) {
    user {
      editOtherIncome(input: $input) {
        success
        error
        profile {
          id
          otherIncome {
            ...OtherIncomeFields
          }
        }
      }
    }
  }
  ${OtherIncomeFieldsFragmentDoc}
`;
export type EditProfileOtherIncomeMutationFn = Apollo.MutationFunction<
  EditProfileOtherIncomeMutation,
  EditProfileOtherIncomeMutationVariables
>;

/**
 * __useEditProfileOtherIncomeMutation__
 *
 * To run a mutation, you first call `useEditProfileOtherIncomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProfileOtherIncomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProfileOtherIncomeMutation, { data, loading, error }] = useEditProfileOtherIncomeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditProfileOtherIncomeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditProfileOtherIncomeMutation,
    EditProfileOtherIncomeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditProfileOtherIncomeMutation,
    EditProfileOtherIncomeMutationVariables
  >(EditProfileOtherIncomeDocument, options);
}
export type EditProfileOtherIncomeMutationHookResult = ReturnType<
  typeof useEditProfileOtherIncomeMutation
>;
export type EditProfileOtherIncomeMutationResult =
  Apollo.MutationResult<EditProfileOtherIncomeMutation>;
export type EditProfileOtherIncomeMutationOptions = Apollo.BaseMutationOptions<
  EditProfileOtherIncomeMutation,
  EditProfileOtherIncomeMutationVariables
>;
export const DeleteProfileOtherIncomeDocument = gql`
  mutation DeleteProfileOtherIncome($id: ID!) {
    user {
      deleteOtherIncome(id: $id) {
        success
        error
        profile {
          id
          otherIncome {
            ...OtherIncomeFields
          }
        }
      }
    }
  }
  ${OtherIncomeFieldsFragmentDoc}
`;
export type DeleteProfileOtherIncomeMutationFn = Apollo.MutationFunction<
  DeleteProfileOtherIncomeMutation,
  DeleteProfileOtherIncomeMutationVariables
>;

/**
 * __useDeleteProfileOtherIncomeMutation__
 *
 * To run a mutation, you first call `useDeleteProfileOtherIncomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfileOtherIncomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfileOtherIncomeMutation, { data, loading, error }] = useDeleteProfileOtherIncomeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProfileOtherIncomeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProfileOtherIncomeMutation,
    DeleteProfileOtherIncomeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProfileOtherIncomeMutation,
    DeleteProfileOtherIncomeMutationVariables
  >(DeleteProfileOtherIncomeDocument, options);
}
export type DeleteProfileOtherIncomeMutationHookResult = ReturnType<
  typeof useDeleteProfileOtherIncomeMutation
>;
export type DeleteProfileOtherIncomeMutationResult =
  Apollo.MutationResult<DeleteProfileOtherIncomeMutation>;
export type DeleteProfileOtherIncomeMutationOptions = Apollo.BaseMutationOptions<
  DeleteProfileOtherIncomeMutation,
  DeleteProfileOtherIncomeMutationVariables
>;
export const SaveNoOtherIncomeDocument = gql`
  mutation SaveNoOtherIncome {
    user {
      saveNoOtherIncome {
        success
        error
        profile {
          id
          otherIncome {
            ...OtherIncomeFields
          }
        }
      }
    }
  }
  ${OtherIncomeFieldsFragmentDoc}
`;
export type SaveNoOtherIncomeMutationFn = Apollo.MutationFunction<
  SaveNoOtherIncomeMutation,
  SaveNoOtherIncomeMutationVariables
>;

/**
 * __useSaveNoOtherIncomeMutation__
 *
 * To run a mutation, you first call `useSaveNoOtherIncomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNoOtherIncomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNoOtherIncomeMutation, { data, loading, error }] = useSaveNoOtherIncomeMutation({
 *   variables: {
 *   },
 * });
 */
export function useSaveNoOtherIncomeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveNoOtherIncomeMutation,
    SaveNoOtherIncomeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveNoOtherIncomeMutation, SaveNoOtherIncomeMutationVariables>(
    SaveNoOtherIncomeDocument,
    options
  );
}
export type SaveNoOtherIncomeMutationHookResult = ReturnType<typeof useSaveNoOtherIncomeMutation>;
export type SaveNoOtherIncomeMutationResult = Apollo.MutationResult<SaveNoOtherIncomeMutation>;
export type SaveNoOtherIncomeMutationOptions = Apollo.BaseMutationOptions<
  SaveNoOtherIncomeMutation,
  SaveNoOtherIncomeMutationVariables
>;
export const UpdateTenantsPayeeFracDocument = gql`
  mutation UpdateTenantsPayeeFrac($input: UpdateTenantsPayeeFracInput!) {
    user {
      updateTenantsPayeeFrac(input: $input) {
        success
        error
        profile {
          id
          household {
            ...HouseholdFields
          }
        }
      }
    }
  }
  ${HouseholdFieldsFragmentDoc}
`;
export type UpdateTenantsPayeeFracMutationFn = Apollo.MutationFunction<
  UpdateTenantsPayeeFracMutation,
  UpdateTenantsPayeeFracMutationVariables
>;

/**
 * __useUpdateTenantsPayeeFracMutation__
 *
 * To run a mutation, you first call `useUpdateTenantsPayeeFracMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantsPayeeFracMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantsPayeeFracMutation, { data, loading, error }] = useUpdateTenantsPayeeFracMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantsPayeeFracMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTenantsPayeeFracMutation,
    UpdateTenantsPayeeFracMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTenantsPayeeFracMutation,
    UpdateTenantsPayeeFracMutationVariables
  >(UpdateTenantsPayeeFracDocument, options);
}
export type UpdateTenantsPayeeFracMutationHookResult = ReturnType<
  typeof useUpdateTenantsPayeeFracMutation
>;
export type UpdateTenantsPayeeFracMutationResult =
  Apollo.MutationResult<UpdateTenantsPayeeFracMutation>;
export type UpdateTenantsPayeeFracMutationOptions = Apollo.BaseMutationOptions<
  UpdateTenantsPayeeFracMutation,
  UpdateTenantsPayeeFracMutationVariables
>;
export const GetInspectionUrlDocument = gql`
  query GetInspectionUrl($accountId: ID!, $input: InspectionUrlInput!) {
    inspectionUrl(accountId: $accountId, input: $input) {
      success
      inspectionUrlObject {
        fileUrl
        filename
        message
      }
    }
  }
`;

/**
 * __useGetInspectionUrlQuery__
 *
 * To run a query within a React component, call `useGetInspectionUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspectionUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspectionUrlQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInspectionUrlQuery(
  baseOptions: Apollo.QueryHookOptions<GetInspectionUrlQuery, GetInspectionUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInspectionUrlQuery, GetInspectionUrlQueryVariables>(
    GetInspectionUrlDocument,
    options
  );
}
export function useGetInspectionUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInspectionUrlQuery, GetInspectionUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInspectionUrlQuery, GetInspectionUrlQueryVariables>(
    GetInspectionUrlDocument,
    options
  );
}
export type GetInspectionUrlQueryHookResult = ReturnType<typeof useGetInspectionUrlQuery>;
export type GetInspectionUrlLazyQueryHookResult = ReturnType<typeof useGetInspectionUrlLazyQuery>;
export type GetInspectionUrlQueryResult = Apollo.QueryResult<
  GetInspectionUrlQuery,
  GetInspectionUrlQueryVariables
>;
export const GetInsurancePolicesDocument = gql`
  query GetInsurancePolices($filter: InsurancePolicyFilterInput) {
    user {
      profile {
        id
        insurancePolicies(filter: $filter) {
          ...InsurancePolicyFields
        }
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;

/**
 * __useGetInsurancePolicesQuery__
 *
 * To run a query within a React component, call `useGetInsurancePolicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsurancePolicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsurancePolicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetInsurancePolicesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInsurancePolicesQuery, GetInsurancePolicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInsurancePolicesQuery, GetInsurancePolicesQueryVariables>(
    GetInsurancePolicesDocument,
    options
  );
}
export function useGetInsurancePolicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInsurancePolicesQuery,
    GetInsurancePolicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInsurancePolicesQuery, GetInsurancePolicesQueryVariables>(
    GetInsurancePolicesDocument,
    options
  );
}
export type GetInsurancePolicesQueryHookResult = ReturnType<typeof useGetInsurancePolicesQuery>;
export type GetInsurancePolicesLazyQueryHookResult = ReturnType<
  typeof useGetInsurancePolicesLazyQuery
>;
export type GetInsurancePolicesQueryResult = Apollo.QueryResult<
  GetInsurancePolicesQuery,
  GetInsurancePolicesQueryVariables
>;
export const AddInsurancePolicyDocument = gql`
  mutation AddInsurancePolicy($input: InsurancePolicyInput!) {
    addInsurancePolicy(input: $input) {
      success
      error
      insurancePolicy {
        ...InsurancePolicyFields
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type AddInsurancePolicyMutationFn = Apollo.MutationFunction<
  AddInsurancePolicyMutation,
  AddInsurancePolicyMutationVariables
>;

/**
 * __useAddInsurancePolicyMutation__
 *
 * To run a mutation, you first call `useAddInsurancePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInsurancePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInsurancePolicyMutation, { data, loading, error }] = useAddInsurancePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddInsurancePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddInsurancePolicyMutation,
    AddInsurancePolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddInsurancePolicyMutation, AddInsurancePolicyMutationVariables>(
    AddInsurancePolicyDocument,
    options
  );
}
export type AddInsurancePolicyMutationHookResult = ReturnType<typeof useAddInsurancePolicyMutation>;
export type AddInsurancePolicyMutationResult = Apollo.MutationResult<AddInsurancePolicyMutation>;
export type AddInsurancePolicyMutationOptions = Apollo.BaseMutationOptions<
  AddInsurancePolicyMutation,
  AddInsurancePolicyMutationVariables
>;
export const EditInsurancePolicyDocument = gql`
  mutation EditInsurancePolicy($id: ID!, $input: InsurancePolicyInput!) {
    editInsurancePolicy(id: $id, input: $input) {
      success
      error
      insurancePolicy {
        ...InsurancePolicyFields
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type EditInsurancePolicyMutationFn = Apollo.MutationFunction<
  EditInsurancePolicyMutation,
  EditInsurancePolicyMutationVariables
>;

/**
 * __useEditInsurancePolicyMutation__
 *
 * To run a mutation, you first call `useEditInsurancePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInsurancePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInsurancePolicyMutation, { data, loading, error }] = useEditInsurancePolicyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditInsurancePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditInsurancePolicyMutation,
    EditInsurancePolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditInsurancePolicyMutation, EditInsurancePolicyMutationVariables>(
    EditInsurancePolicyDocument,
    options
  );
}
export type EditInsurancePolicyMutationHookResult = ReturnType<
  typeof useEditInsurancePolicyMutation
>;
export type EditInsurancePolicyMutationResult = Apollo.MutationResult<EditInsurancePolicyMutation>;
export type EditInsurancePolicyMutationOptions = Apollo.BaseMutationOptions<
  EditInsurancePolicyMutation,
  EditInsurancePolicyMutationVariables
>;
export const DeleteInsurancePolicyDocument = gql`
  mutation DeleteInsurancePolicy($id: ID!) {
    deleteInsurancePolicy(id: $id) {
      success
      error
    }
  }
`;
export type DeleteInsurancePolicyMutationFn = Apollo.MutationFunction<
  DeleteInsurancePolicyMutation,
  DeleteInsurancePolicyMutationVariables
>;

/**
 * __useDeleteInsurancePolicyMutation__
 *
 * To run a mutation, you first call `useDeleteInsurancePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInsurancePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInsurancePolicyMutation, { data, loading, error }] = useDeleteInsurancePolicyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInsurancePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInsurancePolicyMutation,
    DeleteInsurancePolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInsurancePolicyMutation, DeleteInsurancePolicyMutationVariables>(
    DeleteInsurancePolicyDocument,
    options
  );
}
export type DeleteInsurancePolicyMutationHookResult = ReturnType<
  typeof useDeleteInsurancePolicyMutation
>;
export type DeleteInsurancePolicyMutationResult =
  Apollo.MutationResult<DeleteInsurancePolicyMutation>;
export type DeleteInsurancePolicyMutationOptions = Apollo.BaseMutationOptions<
  DeleteInsurancePolicyMutation,
  DeleteInsurancePolicyMutationVariables
>;
export const SendInsurancePolicyDocumentDocument = gql`
  mutation SendInsurancePolicyDocument($id: ID!) {
    sendInsurancePolicyDocument(id: $id) {
      success
      error
      insurancePolicy {
        ...InsurancePolicyFields
      }
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`;
export type SendInsurancePolicyDocumentMutationFn = Apollo.MutationFunction<
  SendInsurancePolicyDocumentMutation,
  SendInsurancePolicyDocumentMutationVariables
>;

/**
 * __useSendInsurancePolicyDocumentMutation__
 *
 * To run a mutation, you first call `useSendInsurancePolicyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInsurancePolicyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInsurancePolicyDocumentMutation, { data, loading, error }] = useSendInsurancePolicyDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendInsurancePolicyDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendInsurancePolicyDocumentMutation,
    SendInsurancePolicyDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendInsurancePolicyDocumentMutation,
    SendInsurancePolicyDocumentMutationVariables
  >(SendInsurancePolicyDocumentDocument, options);
}
export type SendInsurancePolicyDocumentMutationHookResult = ReturnType<
  typeof useSendInsurancePolicyDocumentMutation
>;
export type SendInsurancePolicyDocumentMutationResult =
  Apollo.MutationResult<SendInsurancePolicyDocumentMutation>;
export type SendInsurancePolicyDocumentMutationOptions = Apollo.BaseMutationOptions<
  SendInsurancePolicyDocumentMutation,
  SendInsurancePolicyDocumentMutationVariables
>;
export const EnableSharingDocument = gql`
  mutation enableSharing($id: ID!) {
    assetsShare(id: $id) {
      success
      error
      assetsShare {
        id
        profileId
        shared
        assets {
          id
          accountId
          assetType
          permissions
          shared
          role
        }
        expiryZ
      }
    }
  }
`;
export type EnableSharingMutationFn = Apollo.MutationFunction<
  EnableSharingMutation,
  EnableSharingMutationVariables
>;

/**
 * __useEnableSharingMutation__
 *
 * To run a mutation, you first call `useEnableSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableSharingMutation, { data, loading, error }] = useEnableSharingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnableSharingMutation(
  baseOptions?: Apollo.MutationHookOptions<EnableSharingMutation, EnableSharingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnableSharingMutation, EnableSharingMutationVariables>(
    EnableSharingDocument,
    options
  );
}
export type EnableSharingMutationHookResult = ReturnType<typeof useEnableSharingMutation>;
export type EnableSharingMutationResult = Apollo.MutationResult<EnableSharingMutation>;
export type EnableSharingMutationOptions = Apollo.BaseMutationOptions<
  EnableSharingMutation,
  EnableSharingMutationVariables
>;
export const DisableSharingDocument = gql`
  mutation disableSharing($id: ID!) {
    assetsShareSoftDelete(id: $id) {
      success
      error
      assetsShare {
        id
        profileId
        shared
        assets {
          id
          accountId
          assetType
          permissions
          shared
          role
        }
        expiryZ
      }
    }
  }
`;
export type DisableSharingMutationFn = Apollo.MutationFunction<
  DisableSharingMutation,
  DisableSharingMutationVariables
>;

/**
 * __useDisableSharingMutation__
 *
 * To run a mutation, you first call `useDisableSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableSharingMutation, { data, loading, error }] = useDisableSharingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableSharingMutation(
  baseOptions?: Apollo.MutationHookOptions<DisableSharingMutation, DisableSharingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DisableSharingMutation, DisableSharingMutationVariables>(
    DisableSharingDocument,
    options
  );
}
export type DisableSharingMutationHookResult = ReturnType<typeof useDisableSharingMutation>;
export type DisableSharingMutationResult = Apollo.MutationResult<DisableSharingMutation>;
export type DisableSharingMutationOptions = Apollo.BaseMutationOptions<
  DisableSharingMutation,
  DisableSharingMutationVariables
>;
export const UpdateSharedAssetsDocument = gql`
  mutation updateSharedAssets($input: UpdateSharedAssetsInput!) {
    updateSharedAssets(input: $input) {
      success
      error
      assetsShare {
        id
        profileId
        shared
        assets {
          id
          accountId
          assetType
          permissions
          shared
          role
        }
        expiryZ
      }
    }
  }
`;
export type UpdateSharedAssetsMutationFn = Apollo.MutationFunction<
  UpdateSharedAssetsMutation,
  UpdateSharedAssetsMutationVariables
>;

/**
 * __useUpdateSharedAssetsMutation__
 *
 * To run a mutation, you first call `useUpdateSharedAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSharedAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSharedAssetsMutation, { data, loading, error }] = useUpdateSharedAssetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSharedAssetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSharedAssetsMutation,
    UpdateSharedAssetsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSharedAssetsMutation, UpdateSharedAssetsMutationVariables>(
    UpdateSharedAssetsDocument,
    options
  );
}
export type UpdateSharedAssetsMutationHookResult = ReturnType<typeof useUpdateSharedAssetsMutation>;
export type UpdateSharedAssetsMutationResult = Apollo.MutationResult<UpdateSharedAssetsMutation>;
export type UpdateSharedAssetsMutationOptions = Apollo.BaseMutationOptions<
  UpdateSharedAssetsMutation,
  UpdateSharedAssetsMutationVariables
>;
export const ShareesDocument = gql`
  query sharees {
    user {
      profile {
        id
        assetsShares {
          id
          profileId
          shared
          shareeName
          shareeEmail
          expiryZ
          assets {
            id
            accountId
            shared
            assetType
            role
            permissions
          }
        }
      }
    }
  }
`;

/**
 * __useShareesQuery__
 *
 * To run a query within a React component, call `useShareesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareesQuery({
 *   variables: {
 *   },
 * });
 */
export function useShareesQuery(
  baseOptions?: Apollo.QueryHookOptions<ShareesQuery, ShareesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShareesQuery, ShareesQueryVariables>(ShareesDocument, options);
}
export function useShareesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShareesQuery, ShareesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShareesQuery, ShareesQueryVariables>(ShareesDocument, options);
}
export type ShareesQueryHookResult = ReturnType<typeof useShareesQuery>;
export type ShareesLazyQueryHookResult = ReturnType<typeof useShareesLazyQuery>;
export type ShareesQueryResult = Apollo.QueryResult<ShareesQuery, ShareesQueryVariables>;
export const SharingInvitationDocument = gql`
  mutation sharingInvitation($input: AssetsShareInput!) {
    assetsShareInvitation(input: $input) {
      success
      error
      assetsShare {
        id
        profileId
        shared
        assets {
          id
          accountId
          assetType
          permissions
          shared
          role
        }
        shareeName
        shareeEmail
        expiryZ
      }
    }
  }
`;
export type SharingInvitationMutationFn = Apollo.MutationFunction<
  SharingInvitationMutation,
  SharingInvitationMutationVariables
>;

/**
 * __useSharingInvitationMutation__
 *
 * To run a mutation, you first call `useSharingInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSharingInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sharingInvitationMutation, { data, loading, error }] = useSharingInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSharingInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SharingInvitationMutation,
    SharingInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SharingInvitationMutation, SharingInvitationMutationVariables>(
    SharingInvitationDocument,
    options
  );
}
export type SharingInvitationMutationHookResult = ReturnType<typeof useSharingInvitationMutation>;
export type SharingInvitationMutationResult = Apollo.MutationResult<SharingInvitationMutation>;
export type SharingInvitationMutationOptions = Apollo.BaseMutationOptions<
  SharingInvitationMutation,
  SharingInvitationMutationVariables
>;
export const ProfileEventsDocument = gql`
  subscription ProfileEvents($profileId: ID!) {
    onProfileEvent(profileId: $profileId) {
      profileId
      source
      detail
      detailType
      id
      time
    }
  }
`;

/**
 * __useProfileEventsSubscription__
 *
 * To run a query within a React component, call `useProfileEventsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProfileEventsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileEventsSubscription({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useProfileEventsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ProfileEventsSubscription,
    ProfileEventsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<ProfileEventsSubscription, ProfileEventsSubscriptionVariables>(
    ProfileEventsDocument,
    options
  );
}
export type ProfileEventsSubscriptionHookResult = ReturnType<typeof useProfileEventsSubscription>;
export type ProfileEventsSubscriptionResult = Apollo.SubscriptionResult<ProfileEventsSubscription>;
export const SubmitAppointmentDocument = gql`
  mutation SubmitAppointment($id: ID!, $input: AppointmentChangeInput!) {
    submitAppointment(id: $id, input: $input) {
      id
    }
  }
`;
export type SubmitAppointmentMutationFn = Apollo.MutationFunction<
  SubmitAppointmentMutation,
  SubmitAppointmentMutationVariables
>;

/**
 * __useSubmitAppointmentMutation__
 *
 * To run a mutation, you first call `useSubmitAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAppointmentMutation, { data, loading, error }] = useSubmitAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitAppointmentMutation,
    SubmitAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitAppointmentMutation, SubmitAppointmentMutationVariables>(
    SubmitAppointmentDocument,
    options
  );
}
export type SubmitAppointmentMutationHookResult = ReturnType<typeof useSubmitAppointmentMutation>;
export type SubmitAppointmentMutationResult = Apollo.MutationResult<SubmitAppointmentMutation>;
export type SubmitAppointmentMutationOptions = Apollo.BaseMutationOptions<
  SubmitAppointmentMutation,
  SubmitAppointmentMutationVariables
>;
export const SendRequestLinkDocument = gql`
  mutation SendRequestLink($id: ID!) {
    sendRequestLink(id: $id) {
      success
    }
  }
`;
export type SendRequestLinkMutationFn = Apollo.MutationFunction<
  SendRequestLinkMutation,
  SendRequestLinkMutationVariables
>;

/**
 * __useSendRequestLinkMutation__
 *
 * To run a mutation, you first call `useSendRequestLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRequestLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRequestLinkMutation, { data, loading, error }] = useSendRequestLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendRequestLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendRequestLinkMutation,
    SendRequestLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendRequestLinkMutation, SendRequestLinkMutationVariables>(
    SendRequestLinkDocument,
    options
  );
}
export type SendRequestLinkMutationHookResult = ReturnType<typeof useSendRequestLinkMutation>;
export type SendRequestLinkMutationResult = Apollo.MutationResult<SendRequestLinkMutation>;
export type SendRequestLinkMutationOptions = Apollo.BaseMutationOptions<
  SendRequestLinkMutation,
  SendRequestLinkMutationVariables
>;
export const CancelRequestDocument = gql`
  mutation CancelRequest($id: ID!) {
    cancelRequest(id: $id) {
      id
      status
    }
  }
`;
export type CancelRequestMutationFn = Apollo.MutationFunction<
  CancelRequestMutation,
  CancelRequestMutationVariables
>;

/**
 * __useCancelRequestMutation__
 *
 * To run a mutation, you first call `useCancelRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRequestMutation, { data, loading, error }] = useCancelRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelRequestMutation, CancelRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelRequestMutation, CancelRequestMutationVariables>(
    CancelRequestDocument,
    options
  );
}
export type CancelRequestMutationHookResult = ReturnType<typeof useCancelRequestMutation>;
export type CancelRequestMutationResult = Apollo.MutationResult<CancelRequestMutation>;
export type CancelRequestMutationOptions = Apollo.BaseMutationOptions<
  CancelRequestMutation,
  CancelRequestMutationVariables
>;
export const RecategorizeRequestDocument = gql`
  mutation RecategorizeRequest($id: ID!, $newCategory: String) {
    recategorizeRequest(id: $id, newCategory: $newCategory) {
      id
      category
      fields {
        label
        type
        value
        info
      }
    }
  }
`;
export type RecategorizeRequestMutationFn = Apollo.MutationFunction<
  RecategorizeRequestMutation,
  RecategorizeRequestMutationVariables
>;

/**
 * __useRecategorizeRequestMutation__
 *
 * To run a mutation, you first call `useRecategorizeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecategorizeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recategorizeRequestMutation, { data, loading, error }] = useRecategorizeRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newCategory: // value for 'newCategory'
 *   },
 * });
 */
export function useRecategorizeRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RecategorizeRequestMutation,
    RecategorizeRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RecategorizeRequestMutation, RecategorizeRequestMutationVariables>(
    RecategorizeRequestDocument,
    options
  );
}
export type RecategorizeRequestMutationHookResult = ReturnType<
  typeof useRecategorizeRequestMutation
>;
export type RecategorizeRequestMutationResult = Apollo.MutationResult<RecategorizeRequestMutation>;
export type RecategorizeRequestMutationOptions = Apollo.BaseMutationOptions<
  RecategorizeRequestMutation,
  RecategorizeRequestMutationVariables
>;
export const GetRequestDocument = gql`
  query GetRequest($id: ID!) {
    request(id: $id) {
      ...RequestFields
    }
  }
  ${RequestFieldsFragmentDoc}
`;

/**
 * __useGetRequestQuery__
 *
 * To run a query within a React component, call `useGetRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequestQuery(
  baseOptions: Apollo.QueryHookOptions<GetRequestQuery, GetRequestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRequestQuery, GetRequestQueryVariables>(GetRequestDocument, options);
}
export function useGetRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequestQuery, GetRequestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRequestQuery, GetRequestQueryVariables>(
    GetRequestDocument,
    options
  );
}
export type GetRequestQueryHookResult = ReturnType<typeof useGetRequestQuery>;
export type GetRequestLazyQueryHookResult = ReturnType<typeof useGetRequestLazyQuery>;
export type GetRequestQueryResult = Apollo.QueryResult<GetRequestQuery, GetRequestQueryVariables>;
export const UpdateDraftDocument = gql`
  mutation UpdateDraft($id: ID!, $input: UpdateRequestInput) {
    updateDraft(id: $id, input: $input) {
      id
      details
      firstOccurred
      appointmentPreferences {
        start
        end
      }
      pendingNotifications
      fields {
        value
      }
    }
  }
`;
export type UpdateDraftMutationFn = Apollo.MutationFunction<
  UpdateDraftMutation,
  UpdateDraftMutationVariables
>;

/**
 * __useUpdateDraftMutation__
 *
 * To run a mutation, you first call `useUpdateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftMutation, { data, loading, error }] = useUpdateDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDraftMutation, UpdateDraftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDraftMutation, UpdateDraftMutationVariables>(
    UpdateDraftDocument,
    options
  );
}
export type UpdateDraftMutationHookResult = ReturnType<typeof useUpdateDraftMutation>;
export type UpdateDraftMutationResult = Apollo.MutationResult<UpdateDraftMutation>;
export type UpdateDraftMutationOptions = Apollo.BaseMutationOptions<
  UpdateDraftMutation,
  UpdateDraftMutationVariables
>;
export const SubmitRequestDocument = gql`
  mutation SubmitRequest($id: ID!) {
    submitRequest(id: $id) {
      id
      status
    }
  }
`;
export type SubmitRequestMutationFn = Apollo.MutationFunction<
  SubmitRequestMutation,
  SubmitRequestMutationVariables
>;

/**
 * __useSubmitRequestMutation__
 *
 * To run a mutation, you first call `useSubmitRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRequestMutation, { data, loading, error }] = useSubmitRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmitRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitRequestMutation, SubmitRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitRequestMutation, SubmitRequestMutationVariables>(
    SubmitRequestDocument,
    options
  );
}
export type SubmitRequestMutationHookResult = ReturnType<typeof useSubmitRequestMutation>;
export type SubmitRequestMutationResult = Apollo.MutationResult<SubmitRequestMutation>;
export type SubmitRequestMutationOptions = Apollo.BaseMutationOptions<
  SubmitRequestMutation,
  SubmitRequestMutationVariables
>;
export const API = {
  Query: {
    GetTenant: 'GetTenant',
    profileWithResidents: 'profileWithResidents',
    GetBooksActivation: 'GetBooksActivation',
    GetHolidays: 'GetHolidays',
    GetManager: 'GetManager',
    GetProfileLogins: 'GetProfileLogins',
    Residents: 'Residents',
    GetUnit: 'GetUnit',
    AnnouncementAttachmentPresignedUrl: 'AnnouncementAttachmentPresignedUrl',
    ListAnnouncementInbox: 'ListAnnouncementInbox',
    GetInboxMessage: 'GetInboxMessage',
    GetInboxMessageForActions: 'GetInboxMessageForActions',
    GetAccountDetails: 'GetAccountDetails',
    GetPropertyExpenses: 'GetPropertyExpenses',
    GetExpensesDocumentLink: 'GetExpensesDocumentLink',
    GetOwnerNameAndAddress: 'GetOwnerNameAndAddress',
    GetRequestDetails: 'GetRequestDetails',
    GetOwner: 'GetOwner',
    GetProperty: 'GetProperty',
    GetPropertyNameAndAddress: 'GetPropertyNameAndAddress',
    GetClearablePayments: 'GetClearablePayments',
    GetPayeeClearables: 'GetPayeeClearables',
    GetManagerAccountAutopay: 'GetManagerAccountAutopay',
    GetApplications: 'GetApplications',
    GetApplicationsByApplicationId: 'GetApplicationsByApplicationId',
    GetCampaign: 'GetCampaign',
    GetCampaigns: 'GetCampaigns',
    GetUnitFloorplan: 'GetUnitFloorplan',
    QueryResidents: 'QueryResidents',
    GetDocumentLink: 'GetDocumentLink',
    GetRequestById: 'GetRequestById',
    GetCategoryTemplate: 'GetCategoryTemplate',
    GetRequests: 'GetRequests',
    GetRequestsByProperty: 'GetRequestsByProperty',
    GetProfileRequest: 'GetProfileRequest',
    ListProfileRequests: 'ListProfileRequests',
    GetAccountSettings: 'GetAccountSettings',
    GetProfile: 'GetProfile',
    GetInspectionUrl: 'GetInspectionUrl',
    GetInsurancePolices: 'GetInsurancePolices',
    sharees: 'sharees',
    GetRequest: 'GetRequest',
  },
  Mutation: {
    AddLogin: 'AddLogin',
    DeleteLogin: 'DeleteLogin',
    UpdateProfileEmail: 'UpdateProfileEmail',
    UpdateInboxMessage: 'UpdateInboxMessage',
    CancelPad: 'CancelPad',
    DeclinePadInvite: 'DeclinePadInvite',
    SavePad: 'SavePad',
    UpdatePad: 'UpdatePad',
    CreateAutopaySingleCollection: 'CreateAutopaySingleCollection',
    SuspendAutopay: 'SuspendAutopay',
    ReactivateAutopay: 'ReactivateAutopay',
    GetAutopayEmbedUrl: 'GetAutopayEmbedUrl',
    CreateCampaign: 'CreateCampaign',
    SignLeaseOffer: 'SignLeaseOffer',
    CreateRequest: 'CreateRequest',
    FinalizeRequest: 'FinalizeRequest',
    ApproveRequest: 'ApproveRequest',
    DeclineRequest: 'DeclineRequest',
    CreateApplication: 'CreateApplication',
    UpdateAdditionalTenants: 'UpdateAdditionalTenants',
    UpdateProfile: 'UpdateProfile',
    AddProfileWorkHistory: 'AddProfileWorkHistory',
    EditProfileWorkHistory: 'EditProfileWorkHistory',
    DeleteProfileWorkHistory: 'DeleteProfileWorkHistory',
    SaveNoWorkHistory: 'SaveNoWorkHistory',
    AddProfileAddressHistory: 'AddProfileAddressHistory',
    EditProfileAddressHistory: 'EditProfileAddressHistory',
    DeleteProfileAddressHistory: 'DeleteProfileAddressHistory',
    SaveNoAddressHistory: 'SaveNoAddressHistory',
    SaveNoAdditionalTenants: 'SaveNoAdditionalTenants',
    AddAdditionalTenant: 'AddAdditionalTenant',
    EditAdditionalTenant: 'EditAdditionalTenant',
    DeleteAdditionalTenant: 'DeleteAdditionalTenant',
    SaveNoPets: 'SaveNoPets',
    UpdatePets: 'UpdatePets',
    SaveNoVehicles: 'SaveNoVehicles',
    AddVehicle: 'AddVehicle',
    EditVehicle: 'EditVehicle',
    DeleteVehicle: 'DeleteVehicle',
    UpdateProfileConsents: 'UpdateProfileConsents',
    AddBankAccount: 'AddBankAccount',
    DeleteBankAccount: 'DeleteBankAccount',
    AddProfileOtherIncome: 'AddProfileOtherIncome',
    EditProfileOtherIncome: 'EditProfileOtherIncome',
    DeleteProfileOtherIncome: 'DeleteProfileOtherIncome',
    SaveNoOtherIncome: 'SaveNoOtherIncome',
    UpdateTenantsPayeeFrac: 'UpdateTenantsPayeeFrac',
    AddInsurancePolicy: 'AddInsurancePolicy',
    EditInsurancePolicy: 'EditInsurancePolicy',
    DeleteInsurancePolicy: 'DeleteInsurancePolicy',
    SendInsurancePolicyDocument: 'SendInsurancePolicyDocument',
    enableSharing: 'enableSharing',
    disableSharing: 'disableSharing',
    updateSharedAssets: 'updateSharedAssets',
    sharingInvitation: 'sharingInvitation',
    SubmitAppointment: 'SubmitAppointment',
    SendRequestLink: 'SendRequestLink',
    CancelRequest: 'CancelRequest',
    RecategorizeRequest: 'RecategorizeRequest',
    UpdateDraft: 'UpdateDraft',
    SubmitRequest: 'SubmitRequest',
  },
  Subscription: {
    ProfileEvents: 'ProfileEvents',
  },
  Fragment: {
    TenantLeaseFields: 'TenantLeaseFields',
    AccountFields: 'AccountFields',
    AutopayEnrollmentFields: 'AutopayEnrollmentFields',
    LeaseInspectionFields: 'LeaseInspectionFields',
    LeaseFields: 'LeaseFields',
    ManagerFields: 'ManagerFields',
    ResidencyFields: 'ResidencyFields',
    PropertyHeaderFields: 'PropertyHeaderFields',
    UnitHeaderFields: 'UnitHeaderFields',
    ResidentFields: 'ResidentFields',
    UnitFields: 'UnitFields',
    ResidentRecordFields: 'ResidentRecordFields',
    UnitResidencyFields: 'UnitResidencyFields',
    ProfileLoginFields: 'ProfileLoginFields',
    ExpenseEntryFields: 'ExpenseEntryFields',
    OwnerFields: 'OwnerFields',
    PropertyUnitResidencyFields: 'PropertyUnitResidencyFields',
    PropertyUnitFields: 'PropertyUnitFields',
    PropertyFields: 'PropertyFields',
    ClearableListFields: 'ClearableListFields',
    ClearableDetailFields: 'ClearableDetailFields',
    CampaignFields: 'CampaignFields',
    UnitFloorplanFields: 'UnitFloorplanFields',
    LeaseAgreementFields: 'LeaseAgreementFields',
    SignatureFields: 'SignatureFields',
    ProfileRequestFields: 'ProfileRequestFields',
    RequestDetailsFields: 'RequestDetailsFields',
    RequestVisitFields: 'RequestVisitFields',
    ProfileRequestVisitFields: 'ProfileRequestVisitFields',
    RequestListFields: 'RequestListFields',
    RequestUnitFields: 'RequestUnitFields',
    AddressHistoryFields: 'AddressHistoryFields',
    CampaignApplicationRulesField: 'CampaignApplicationRulesField',
    AccountSettingsFields: 'AccountSettingsFields',
    ApplicationFields: 'ApplicationFields',
    BankAccountFields: 'BankAccountFields',
    AutopayFields: 'AutopayFields',
    DocumentFields: 'DocumentFields',
    TenantFields: 'TenantFields',
    PetFields: 'PetFields',
    VehicleFields: 'VehicleFields',
    HouseholdFields: 'HouseholdFields',
    ApplicationTenantFields: 'ApplicationTenantFields',
    ApplicationHouseholdFields: 'ApplicationHouseholdFields',
    OtherIncomeFields: 'OtherIncomeFields',
    ProfileFields: 'ProfileFields',
    WorkHistoryFields: 'WorkHistoryFields',
    InsurancePolicyFields: 'InsurancePolicyFields',
    newAssetShare: 'newAssetShare',
    RequestFields: 'RequestFields',
    TemplateFields: 'TemplateFields',
  },
};
