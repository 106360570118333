/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantAnnouncementAttachmentPresignedUrlAction } from './announcementPresignedUrl';
import TenantAnnouncementAttachmentPresignedUrlActionSchema from './announcementPresignedUrl.json';

export const isTenantAnnouncementAttachmentPresignedUrlAction = guard<TenantAnnouncementAttachmentPresignedUrlAction>(TenantAnnouncementAttachmentPresignedUrlActionSchema as any);